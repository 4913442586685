import React, { useState, KeyboardEvent, useEffect, useRef } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import crossIcon from '../assets/images/cross-gray.png';  
import linkIcon from '../assets/images/link-icon.png';
import { Follower, NewFollowerData } from '../helpers/interfaces';
import { isMobile, isTablet} from 'react-device-detect';

interface PopupProps {
    closePopup: () => void;
    createInvite: (follower: NewFollowerData) => void;
    follower?: Follower | null;
  }

  interface ErrorState {
    isError: boolean;
    message: string;
  }
  
const InvitePopup: React.FC<PopupProps> = ({ closePopup, createInvite, follower = null}) => {
    const [isEmail, setIsEmail] = useState(true);
    const [email, setEmail] = useState((follower && follower.channel === 'email') ? follower.endpoint : "");
    const [countryCode, setCountryCode] = useState('ca');
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isValid, setValid] = useState(false);
    const [error, setError] = useState<ErrorState>({ isError: false, message: "" });
    const inputNameRef = useRef<HTMLInputElement>(null);
    const isPhone= () => {
      return isMobile && !isTablet
    }

    const getNameParts = (inviterName: string) => {
        const nameParts = inviterName.split(' ');
        return {
          firstName: nameParts[0] || '',
          lastName: nameParts[1] || '',
        };
      };

      const [name, setName] = useState(() => {
        if (follower && follower.inviterName) {
          return getNameParts(follower.inviterName);
        }
        return { firstName: '', lastName: '' };
    });

    useEffect(() => {
      if (inputNameRef.current) {
        inputNameRef.current.focus();
      }
    }, []);

    useEffect(() => {
        
        if (follower && follower.inviterName) {
          setName(getNameParts(follower.inviterName));
        }

        if (follower && follower.channel === 'phone') {
            setIsEmail(false);
            const phone = follower.endpoint.replace(/[^\d]/g, ''); // Remove "+" and keep only numeric characters
            setPhoneNumber(phone);
            setValid(validatePhoneNumber(phone) && isFirstNameValid(name.firstName));
         } else { 
            setIsEmail(true);
            setEmail(follower?.endpoint || "");
            setValid(validateEmail(email) && isFirstNameValid(name.firstName));
         }
       
      }, [follower]);

    const isFirstNameValid = (firstName: string) => {
        return firstName.length > 1;
    } 

    const onFormSubmit = () => {
        console.log("onFormSubmit")
        const newFollowerData = {channel: isEmail ? 'email' : 'phone', endpoint: isEmail ? email : phoneNumber, name: `${name.firstName} ${name.lastName}`}
        createInvite(newFollowerData)
      };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();  
        onFormSubmit();  
      };
    
      const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          e.preventDefault();  
          handleSubmit(e); 
        }
      };
    
      const handlePhoneChange = (value: string, data: CountryData) => {
        setPhoneNumber(value);
        setValid(validatePhoneNumber(value) && isFirstNameValid(name.firstName));
      };
    
      const handleInputTypeChange = () =>{
        setIsEmail(!isEmail)
        setPhoneNumber("")
        setEmail("")
        setError(prevError => ({ ...prevError, isError: false, message: ''}));
      }
     
      const validateEmail = (email: string) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        return emailPattern.test(email);
      };
    
      const validatePhoneNumber = (phoneNumber: string) => {
        const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
     
        return phoneNumberPattern.test(phoneNumber);
      };
    
      const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const email = e.target.value
          setEmail(email);
          setValid(validateEmail(email) && isFirstNameValid(name.firstName));
 
          setError(prevError => ({ ...prevError, isError: false, message: ''}));
      };

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const firstName = e.target.value
        setName(prevName => ({ ...prevName, firstName: firstName }));
        setValid(validateEmail(email) && isFirstNameValid(firstName));
        setError(prevError => ({ ...prevError, isError: false, message: ''}));
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const lastName = e.target.value
        setName(prevName => ({ ...prevName, lastName: lastName }));
        setError(prevError => ({ ...prevError, isError: false, message: ''}));
    };

    const handleSendInvite = () => {
        console.log("Send Invite")
        const newFollowerData = {channel: isEmail ? 'email' : 'phone', endpoint: isEmail ? email : phoneNumber, name: `${name.firstName} ${name.lastName}`}
        createInvite(newFollowerData)
    }

    const handleCopyLink = () => {
        console.log('handleCopyLink link');
      
      }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className={`${isPhone() ? 'w-[350px]' : 'w-[450px]'}  bg-white p-6 rounded-lg shadow-lg`}>
        <div className="flex justify-start items-center mb-4">
          <button  onClick={closePopup} className="text-gray-500 hover:text-gray-700">
            <img src={crossIcon} alt="close" className="h-[14px] w-[14px] items-center mr-[15px]" />
          </button>
          <h2 className="text-lg font-bold">Invite Member</h2>
        </div>
        <form>
          <div className={`${isPhone() ? 'flex-col' : 'flex-row'} flex mb-4`}>
            <div className={`${isPhone() ? 'mr-0' : 'mr-2'} flex-1`}>
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input ref={inputNameRef} type="text" className="mt-1 p-2 w-full border rounded-full" onChange={handleFirstNameChange} value={name.firstName}/>
            </div>
            <div className={`${isPhone() ? 'ml-0 mt-4' : 'ml-2'} flex-1`}>
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input type="text" placeholder="Optional" className="mt-1 p-2 w-full border rounded-full"  onChange={handleLastNameChange} value={name.lastName}/>
            </div>
          </div>
          <div className="mb-4 flex flex-col">
            <label className="block text-field-label-text text-sm font" htmlFor="email">
              {isEmail ? 'Email' : 'Phone'}
            </label>
            {/* Show text field depends on selected option */}
            {isEmail ? (
              <input
                className={`border-black-border w-full mx-auto my-[8px] px-[24px] h-[40px] appearance-none  ${error.isError ? 'border-error-border' : 'border-black-border'} rounded-full text-black-text text-[16px] leading-tight `}
                //style={{outline: `1px solid ${Colors.primaryButton}`}}
                id="email"
                type="email"
                placeholder="Your email"
                value={email}
                onChange={ handleEmailChange}
                autoFocus
                />
              ) : (
                <PhoneInput
                  country={countryCode}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  inputProps={{
                    required: true,
                    autoFocus: true,
                    type: "tel"
                  }}
                  onKeyDown={handleKeyDown}
                  //enableSearch={true}
                  inputStyle={{fontSize: "16px", width: "100%", borderRadius: '20px', paddingLeft: "80px", height: '40px'}}
                  containerStyle={{width: "100%", borderRadius: '20px', height: '40px', marginTop: '8px', marginBottom: '8px', borderColor: error.isError ? "error-border" : "black-border"}}
                  buttonStyle={{ border: "none", borderRight: "1px solid black" , backgroundColor: "transparent", background: "none", paddingRight: '10px', height: "90%", margin: "5px", marginLeft: "20px", alignSelf: 'center'}}
                />
              )
            }
              <div className="flex items-center justify-center">
          
              <div
                className="inline-block align-baseline font-bold text-[14px] text-blue-text hover:blue-text"
                onClick={() => handleInputTypeChange()}
              >
                {isEmail ? 'Or use mobile number instead' : 'Or use email address instead'}
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center mt-[40px]">
            <button
              onClick={() => handleSendInvite()}
              type="button"
              className={`${isValid ? 'bg-primary-button text-black' : 'bg-inactive-button text-gray-text'}   py-2 px-4 h-[40px] w-[130px] rounded-full font-bold`}
              disabled={!isValid}
            >
              { (follower) ? 'Send Again' : 'Send Now'}
            </button>
            {/* <button onClick={() => handleCopyLink()} type="button" className="text-blue-500 flex items-center text-blue-text">
              <img src={linkIcon} alt="close" className="h-[14px] w-[18px] items-center mr-[15px]" />
              <span className="mr-1 text-blue-text font-bold">
                Copy Link
              </span>  
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default InvitePopup;
