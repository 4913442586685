import React, { useState } from 'react';
import changeNavLayoutIcon from '../assets/images/change-navigation-layout-sidebar.png';
import startIcon from '../assets/images/start-icon.png';
import profileIcon from '../assets/images/profile-icon.png';
import stepcoLogo from '../assets/images/stepcoLogoLong.png';
import signOutIcon from '../assets/images/sign-out-icon.png';
import { AwsMethods } from "../helpers/AwsMethods";
import BottomSheetDevelopmentInfo from '../components/BottomSheetDevelopmentInfo';
interface SideNavBarProps {
  selectedMenu: 'start' | 'profile';
  setSelectedMenu: (menu: 'start' | 'profile') => void;
  toggleView: () => void;
}

const SideNavBar: React.FC<SideNavBarProps> = ({ selectedMenu, setSelectedMenu, toggleView }) => {
  const [showDevInfo, setShowDevInfo] = useState(false)
  const signOutAction = () => {
    AwsMethods.signOut((res: string, responseType: any) => {
      // handle sign out response
    });
  }


  return (
 
        <div className="fixed bg-secondary-background top-0 left-0 w-[315px] h-full shadow-lg flex flex-col justify-between items-start p-4">
          <div className="w-full"> 
          <div  className={`w-full flex flex-row self-center items-center justify-between  mb-[60px] mt-[10px]`}>
            <img src={stepcoLogo} alt="ChangeNavLayout" className="h-[25px] w-[135px] mr-[18px]" />  
            <button onClick={toggleView} className="text-white self-center items-center">
                <img src={changeNavLayoutIcon} alt="ChangeNavLayout" className="h-[25px] w-[25px] " />  
            </button>
          </div>
          <button
            onClick={() => setSelectedMenu('start')}
            className={`flex h-[52px] w-[281px] items-center space-x-2 w-full rounded-full px-4 py-2 mb-[12px] ${selectedMenu === 'start' ? 'bg-white' : ''}`}
          >
              <img src={startIcon} alt="Start" className="h-[25px] w-[25px] mr-[12px]" />  
            <span className="text-black font-bold text-[18px]">Start</span>
          </button>
          <button
            onClick={() => setSelectedMenu('profile')}
            className={`flex h-[52px] w-[281px] items-center space-x-2 w-full rounded-full px-4 py-2  mb-[12px] ${selectedMenu === 'profile' ? 'bg-white' : ''}`}
          >
              <img src={profileIcon} alt="Profile" className="h-[25px] w-[25px] mr-[12px]" />  
            <span className="text-black font-bold text-[18px]">Profile</span>
          </button>
          </div>
          {/* Sign-out container */}
          <div className="w-full flex flex-col self-center items-center justify-center" >
           {/* App version */}
          <span className="text-black font-bold text-[14px]">
            {`v. ${import.meta.env.VITE_RELEASE} `}
            {import.meta.env.VITE_ENV === 'dev' ? (
              <span onClick={() => setShowDevInfo(true)} className="cursor-pointer underline">
                (dev)
              </span>
            ) : (
              ''
            )}
          </span>
            {/* Devider */}
            <div className="bg-followers-devider h-[1px] my-[12px] w-full" />
            <div className=""> 
                <button
                onClick={() => signOutAction()}
                className={`flex h-[52px] w-[281px] items-center space-x-2 w-full rounded-full px-4 py-2`}
                >
                <img src={signOutIcon} alt="Start" className="h-[25px] w-[25px] mr-[12px]" />  
                <span className="text-warning-text font-bold text-[18px]">Sign-out</span>
                </button>
            </div>
          </div>
          
          {/* Development info */}
          <BottomSheetDevelopmentInfo
            currentTheme={'dark'}
            showBottomSheet={showDevInfo}
            closeBottomSheet={() => setShowDevInfo(false)}
          />
        </div>
      
  );
};

export default SideNavBar;
