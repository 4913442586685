 
const SERVER_URL = import.meta.env.VITE_CHIME_SERVER_URL;
const SERVER_REGION = import.meta.env.VITE_CHIME_SERVER_REGION;

export type MeetingFeatures = {
  Audio: { [key: string]: string };
};

export type CreateMeetingResponse = {
  MeetingFeatures: MeetingFeatures;
  MediaRegion: string;
};

interface AttendeeInfo {
  AttendeeId: string;
  Capabilities: {
      Audio: string;
      Video: string;
      Content: string;
  };
  ExternalUserId: string;
  JoinToken: string;
}

export type JoinMeetingInfo = {
  Meeting: CreateMeetingResponse;
  Attendee: string;
};

interface MeetingResponse {
  JoinInfo: JoinMeetingInfo;
}

interface GetAttendeeResponse {
  name: string;
}

export async function createMeetingAndAttendee(
  title: string,
  attendeeName: string,
  region: string,
  echoReductionCapability = false
): Promise<MeetingResponse> {
  
  let url = encodeURI(
    SERVER_URL +
    "join?" +
    `title=${title + ""}&name=${attendeeName}&region=${SERVER_REGION}&attendeeAudioCapability=SendReceive&attendeeVideoCapability=SendReceive&attendeeContentCapability=SendReceive&ns_es=false`
  );

  console.log("meetinId", title)
  console.log("userId", attendeeName)
  console.log("url", url)

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  console.log("res", res)
  const data = await res.json();
  console.log("data", data)
  if (data.error) {
    throw new Error(`Server error: ${data.error}`);
  }

  return data;
}

export async function getAttendee(title: string, attendeeId: string): Promise<GetAttendeeResponse> {
  const params = {
    title: encodeURIComponent(title),
    id: encodeURIComponent(attendeeId),
  }; 

  console.log("getAttendee title: ", title)
  console.log("getAttendee attendeeId: ", attendeeId)

  const res = await fetch(SERVER_URL + 'get_attendee?' + new URLSearchParams(params), {
    method: 'GET',
  });

  if (!res.ok) {
    throw new Error('Invalid server response');
  }

  const data = await res.json();
  console.log("data", data)
  return {
    name: data,
  };
}

export async function endMeeting(title: string): Promise<void> {
  const body = {
  };
  console.log("endMeeting body: ", body)
  const res = await fetch(SERVER_URL + 'end' + `?title=${title}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    throw new Error('Server error ending meeting');
  }
}

export const createGetAttendeeCallback = (meetingId: string) => (
  chimeAttendeeId: string
): Promise<GetAttendeeResponse> => getAttendee(meetingId, chimeAttendeeId);
