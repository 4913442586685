import { Amplify} from "aws-amplify";
import { signIn, confirmSignIn, signOut, getCurrentUser } from 'aws-amplify/auth';
import { AppConstant } from "../constants";
import config from '../amplifyconfiguration.json';
 

Amplify.configure(config);

export const AwsMethods = {
  async signOut(callback: any) {
    await signOut()

    callback("", "success");
  },

  async signIn(userData: any, callback: any) {
    console.log("userData SignIn", userData);
    try {
      const username = userData.inputTypeNumber
        ? `+${userData.phone}`
        : userData.email.toLocaleLowerCase();
        
        try {
          const {isSignedIn,nextStep} = await signIn(
            {
            username: username,
            options: {
              authFlowType: 'CUSTOM_WITHOUT_SRP'
            }
          })
     
          switch (nextStep.signInStep) {
          case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
              console.log("CONFIRM_SIGN_IN_WITH_TOTP_CODE")
          case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
              console.log("CONFIRM_SIGN_IN_WITH_SMS_CODE")
            break;
          case 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE':
              console.log("CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE")
              callback(nextStep, "success");
              console.log("nextStep", nextStep)
            break;
          case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
              console.log("CONTINUE_SIGN_IN_WITH_TOTP_SETUP")
            break;
          case 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION':
              console.log("CONTINUE_SIGN_IN_WITH_MFA_SELECTION")
            break;
          case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
              console.log("CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED")
            break;
          case 'DONE':
            break;
          }
        } catch(e) {
          const error = e as { code?: string, message?: string };
          console.log("error sign in:", error);
    
          // More robust error handling
          if (typeof error === 'object' && error.code) {
            callback(error.code, "error");
          } else if (error instanceof Error) {
            // Handle standard Error objects
            callback(error.message, "error");
          } else {
            // Fallback for other cases
            callback("Unknown error", "error");
          }
        }
    } catch (e) {
      handleError(e, callback);
    }
  },
  
  async verifyOTP(userData: any, callback: any) {
    try {
      const { isSignedIn, nextStep } = await confirmSignIn({ challengeResponse: userData.otp });
      if (isSignedIn) {
        callback("", "success");
      } else {
        callback("Incorrect code", "error");
      }
    } catch(e) {
      const error = e as { code?: string, message?: string };
      if (error.message == "Invalid session for the user.") {
        callback(AppConstant.expireOtpError, "error");
      } else if (
        error.message == "Incorrect username or password." ||
        e === "No current user" ||
        error.message?.includes("Cannot read properties of undefined")
      ) {
        callback(AppConstant.incorrectOTPError, "error");
      } else {
        callback(error.message, "error");
      }
    }
  },
};

function handleError(e: unknown, callback: (errorCode: string, errorType: string) => void) {
  const error = e as { code?: string, message?: string };

  if (typeof error === 'object' && error.code) {
    console.log("Error code:", error.code);
    callback(error.code, "error");
  } else if (error instanceof Error) {
    console.log("Error message:", error.message);
    callback(error.message, "error");
  } else {
    console.log("Unknown error");
    callback("Unknown error", "error");
  }
}
