import styled from 'styled-components';

interface Props {
  showNav: boolean;
  showRoster: boolean;
  showChat: boolean;
  isPortrait: boolean;
}

// Styles for video tiles
export const localVideoFluid = `
  height: 100%;
  width: 100%;
`;

export const localVideoInstructor = (isPortrait: boolean) =>  { 
if (isPortrait) {
  return `
    height: auto;
    width: auto;  
    aspect-ratio: 9 / 16;
    `;
  } else {
    return  `
    height: auto;
    width: auto;  
    aspect-ratio: 16 / 9;
  `
  }
}

export const localVideoStatic = (isMobile: boolean) => {
  if (isMobile) {
    // Styles for mobile devices with dynamic aspect ratio
    return `
      display: flex;
      position: absolute;
      bottom: 2rem;
      right: 1rem;
      max-height: 20vh;
      width: auto;  // Adjust based on content
      aspect-ratio: 16 / 9;  // Default to 16:9

      @media (orientation: portrait) {
        aspect-ratio: 9 / 16;  // Change to 9:16 in portrait mode
      }

      video {
        width: 100%;  // Make video fill the container
        height: 100%;
        object-fit: cover;  // Ensure video covers the area without distortion
      }
    `;
  } else {
    // Styles for non-mobile devices, fixed to landscape
    return `
      display: flex;
      position: absolute;
      bottom: 2rem;
      right: 1rem;
      max-height: 20vh;
      width: auto;  // Adjust based on content
      aspect-ratio: 16 / 9;  // Always 16:9

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `;
  }
};


export const remoteVideoSingle = `
  @media screen and (max-width: 460px) {
    height: 100%;
  }
  height: 100%;
`;

export const remoteVideoMultiple = `
  height: 100%;
`;


export const StyledLayout = styled.main<Props>`
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: grid;
 
  .video-content {
    grid-area: content;
  }
 
  ${({ showNav, showRoster, showChat }) => {
    if (showNav && (showRoster || showChat)) {
      return `
        grid-template-columns: auto auto 1fr;
        grid-template-areas: 'nav side-panel content';
      `;
    }

    if (showNav && (showRoster || showChat)) {
      return `
        grid-template-columns: auto auto 1fr;
        grid-template-areas: 'nav side-panel content';
      `;
    }

    if (showNav) {
      return `
        grid-template-columns: auto 1fr;
        grid-template-areas: 'nav content';
      `;
    }

    if (showRoster || showChat) {
      return `
        grid-template-columns: auto 1fr;
        grid-template-areas: 'side-panel content';
      `;
    }

    return `
      grid-template-columns: 1fr;
      grid-template-areas: 'content';
    `;
  }}
 
  .nav {
    grid-area: nav;
    bottom: 0;
    position: relative;
  }
   
  .roster,
  .chat {
    grid-area: side-panel;
    z-index: 2;
    bottom: 0;
    position: fixed;
  }
 
  @media screen and (min-width: 848px) {
    .mobile-toggle {
      display: none;
    }
  }
 
  

  @media screen and (max-width: 848px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'content';
      grid-area: unset;
      position: fixed;
      
    }
 
    .roster,
    .chat {
      grid-area: unset;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 320px;
    }
  }
 
  @media screen and (max-width: 460px) {
    .roster,
    .chat {
      max-width: 100%;
      max-height: 85%;
      bottom: 0;
    }
    .nav {
      width: 100%;
      position: fixed;
    }
  }
`;

export const StyledContent = styled.div`
  position: relative;
  grid-area: content;
  height: 100%;
  display: flex;
  flex-direction: column;
 
  .videos {
    flex: 1;
    padding-left: 68px;

  }

  .controls {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
 
  @media screen and (max-width: 868px) {
    .controls {
      bottom: 0rem;
      width: 100%;
    }
  }
`;