import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import { StyledLayout } from './Styled';
import DeviceSelection from '../../components/chime/components/DeviceSelection';
import { useMeetingState } from '../../components/chime/providers/MeetingStateProvider';
import { isMobile, isTablet} from 'react-device-detect';
import { MeetingManager, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import routes from '../../constants/routes';
import {ControlButton} from '../../helpers/types'
import BottomSheetDeviseSelection from '../../components/BottomSheetDeviseSelection';
import { formatTime } from '../../utils/formatters';
import { useOrientationChange } from '../../hooks/useOrientationChange';
import { useJoinMeeting } from '../../hooks/useJoinMeeting';
import { RotatingLines as Spinner } from 'react-loader-spinner'
import {UserContext} from '../../contexts/userContext'
import { MeetingMode } from '../../components/chime/types';
import PopUp from '../../components/Popup'
import joinClassAnimation  from '../../assets/animation/joinClass.gif'

const DeviceSetup: React.FC = () => {
  const { 
    meetingData, 
    leaveMeeting, 
    observerDeviceList,
    changeVideoInput, 
    changeAudioInput,
    changeAudioOutput,
    setTheme, 
    setMeetingData,
  } = useMeetingState();
  const meetingManager = useMeetingManager();
  const navigate = useNavigate();
  const [showDeviceSelection, setShowDeviceSelection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { meetingId } = useMeetingState();
  const [deviceSelectionButton, setDeviceSelectionButton] = useState<ControlButton>('video')
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [meetingTime, setMeetingTime] = useState("");
  const location = useLocation();
  const classItem = location.state.classItem;
  const { joinMeeting, errorMessage, updateErrorMessage } = useJoinMeeting();
  const { user } = useContext(UserContext);
 
  const isPhone= () => {
    return isMobile && !isTablet
  }
 
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    meetingManager?.meetingSession?.audioVideo.addDeviceChangeObserver(observerDeviceList);
 
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
      meetingManager?.meetingSession?.audioVideo.removeDeviceChangeObserver(observerDeviceList);
    }
  }, []);

  useEffect(() => {
    createMeeting()
    setTheme('dark');
    localStorage.setItem('theme', 'dark');
    // Disable scrolling
    document.body.style.overflow = 'hidden';
    return () => {
      // Re-enable scrolling
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    if (meetingData) {
      const meetingTimeDisplay = getMeetingTime()
      setMeetingTime(meetingTimeDisplay)
    }
  }, [meetingData]);

  const createMeeting = async () => {
    setIsLoading(true);
    // Use different id depends on a role 
    let meetingId = classItem.Event?.id
    let userId = user?.contactID ?? ""
    let username = user?.FirstName ?? "Anonimus"
    let meetingMode = user?.IsInstructor ? MeetingMode.Spectator : MeetingMode.Attendee
    console.log('handleJoinNowClick classItem', classItem)
    setMeetingData(classItem)
    await joinMeeting(user?.id ?? "", meetingId, userId, username, meetingMode, classItem);
    setIsLoading(false);
 };

  const handleResize = () => {
    const portrait =isPhone() ? (window.innerHeight > window.innerWidth) : (window.innerHeight > window.innerWidth);
    setIsPortrait(portrait);
  };

  const endMeetingHandler= () => {
    leaveMeeting(meetingManager)
    navigate(routes.START);
  }

  const closeDeviceSelection = (): void => {
    setShowDeviceSelection(false)
  }

  const  handleOpenDeviceSelection= (buttonType: ControlButton) => {
    setDeviceSelectionButton(buttonType)
    setShowDeviceSelection(true)
   }

  const  handleDeviceSelection= (deviceId: string, meetingManager: MeetingManager | undefined, buttonType: ControlButton) => {
    if (buttonType === "video") {
      changeVideoInput(deviceId, meetingManager)
    } else if (buttonType === 'microphone') {
      changeAudioInput(deviceId, meetingManager)
    } else  if (buttonType === "speaker") {
      changeAudioOutput(deviceId, meetingManager)
    }
   }

    const stopMeeting = async (): Promise<void> => {
    try {
      // Stop the local audio and video
      meetingManager.audioVideo?.stopLocalVideoTile();
      meetingManager.audioVideo?.stopAudioInput();
       // Leave the meeting
      await meetingManager.leave();
   
    } catch (error) {
      console.error('Failed to leave the meeting', error);
    }
  };

    const closeError = async (): Promise<void> => {
      updateErrorMessage('')
      await stopMeeting()
      navigate(routes.START)
    };

   const getMeetingTime= () => {
    console.log('meetingData?.Event.EventStartTime', meetingData?.Event.EventStartTime)
    console.log('meetingData?.Event.EventEndTime', meetingData?.Event.EventEndTime)

    const startTime = formatTime(meetingData?.Event.EventStartTime ?? "");
    const endTime = formatTime(meetingData?.Event.EventEndTime ?? "");
    const meetingTimeDisplay = `${startTime} - ${endTime}`;
    return meetingTimeDisplay
   }

   const handleJoinMeeting = async () => {
    setIsLoading(true);
    try {
      await meetingManager.start();
      setIsLoading(false);
      //Stop video input to be ableto use the camera in the meeting
      await meetingManager?.meetingSession?.audioVideo.stopVideoInput()
      navigate(`${routes.MEETING}/${meetingId}`);
    } catch (error) {
      setIsLoading(false);
      setError((error as Error).message);
    }
  };

  return (<>
        <div className={`flex flex-col ${isPhone() ? 'justify-start' : 'justify-center'} w-full h-full self-center bg-primary-meeting-background text-white`}> 
        {(isLoading) ? (
              // <div className="fixed inset-0 z-50 flex items-center justify-center">
              //   <Spinner 
              //     visible={true}
              //     width='40px'
              //     strokeColor= {Colors.primaryButton}
              //   />
              // </div>
                <div className="fixed inset-0 z-1 flex flex-col items-center justify-center">
                 <div className="flex justify-center items-center text-center text-[30px] font-semibold text-primary-button mt-[48px] mb-[18px] w-[250px]">
                 <img src={joinClassAnimation} alt="Animated GIF" />
                 </div>
               </div>
            ) : (
        <StyledLayout isPortrait={isPortrait}>
            <div className='class-info-top'> 
              <div className={`${isPhone() ? 'text-[24px]' : 'text-[24px]'} text-center font-bold `}>{meetingData?.Event.Name}</div>
              <div className={`${isPhone() ? 'text-[20px] py-[15px]' : 'text-[20px] py-[23px]'}  text-center`} >{meetingTime}</div>
            </div> 
            <DeviceSelection 
              isPhone={isPhone()}
              isPortrait={isPortrait} 
              isIncognito={false} 
              handleOpenDeviceSelection= {handleOpenDeviceSelection}
            />
            
            <div className="flex flex-col min-w-[300px] mt-[20px]"> 
              <div className='class-info-side'> 
                <div className={`${isPhone() ? 'text-[24px]' : 'text-[24px]'} text-center font-bold `}>{meetingData?.Event.Name}</div>
                <div className={`${isPhone() ? 'text-[20px] py-[15px]' : 'text-[20px] py-[23px]'}  text-center`} >{meetingData?.Event.EventStartTime}</div>
              </div>
              <button
                onClick={handleJoinMeeting}
                className="self-center mb-[20px] w-[180px] h-[46px] bg-primary-button hover:bg-hover-button text-white  text-[20px] font-bold py-2 px-6 rounded-full focus:outline-none focus:shadow-outline"
              >
                {isLoading ? 'Loading...' : 'Join class'}
                </button>
              <button 
                onClick={() => {
                  endMeetingHandler()
                }}
                className={`text-pwhite font-bold bg-transparent text-[20px]`}>
                Cancel
              </button>
            </div>
          <BottomSheetDeviseSelection
            currentTheme={'dark'}
            showBottomSheet={showDeviceSelection}
            closeBottomSheet={closeDeviceSelection}
            handleDeviceSelection={handleDeviceSelection}
            buttonType={deviceSelectionButton}
            isPortrait={isPortrait}
          />
          {errorMessage && (
            <PopUp 
              type={'error'} 
              isOpen={errorMessage.length > 0} 
              onClose={closeError}
              messageString={"Unable to join meeting: " + errorMessage}
            />
          )} 
        </StyledLayout>
      )}
      </div>
  </>
  );
}

export default DeviceSetup;