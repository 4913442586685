
import React, { FC, PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  NotificationProvider,
  GlobalStyles,
  MeetingProvider,
  LoggerProvider,
} from 'amazon-chime-sdk-component-library-react';
import { demoLightTheme, demoDarkTheme } from '../../theme/demoTheme';
import { MeetingStateProvider, useMeetingState } from '../../providers/MeetingStateProvider';
import ErrorProvider from '../../providers/ErrorProvider';
import Notifications from '../Notifications';
import { NavigationProvider } from '../../providers/NavigationProvider';
import meetingConfig from '../../../../utils/meeting/meetingConfig';
import MeetingFeaturesWrapper from '../MeetingFeaturesWrapper';
 
type MeetingProviderWrapperProps = {
  children: React.ReactNode;
};

const MeetingProviderWrapper: FC<MeetingProviderWrapperProps> = ({ children }) => (
  <MeetingProvider> 
    <LoggerProvider logger={meetingConfig.logger}>
        <MeetingStateProvider>
        <Theme>
            <NotificationProvider>
            <Notifications />
            <ErrorProvider>
                <NavigationProvider>
                    <MeetingFeaturesWrapper>
                        {children}
                    </MeetingFeaturesWrapper>
                </NavigationProvider>
            </ErrorProvider>
            </NotificationProvider>
        </Theme>
        </MeetingStateProvider>
    </LoggerProvider>
 </MeetingProvider> 
);

const Theme: React.FC<PropsWithChildren> = ({ children }) => {
  const { theme } = useMeetingState();

  return (
    <ThemeProvider theme={theme === 'light' ? demoLightTheme : demoDarkTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};


export default MeetingProviderWrapper;
