import React from 'react';

interface PlaceholderProps {
    showPlaceholder: boolean;
    meetingName: string;
    thumbnail: string; 
  }

const Placeholder: React.FC<PlaceholderProps> = ({ showPlaceholder, meetingName, thumbnail }) => {
  if (!showPlaceholder) {
    return null;
  }

  return (
    <div style={{zIndex: 0}} className="fixed z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center h-[300px] w-[300px]">
      <img src={thumbnail} alt="thumbnail" className="rounded-md" />
      <span className="text-primary-text text-[16px] text-white font-normal m-5">
        {meetingName}
      </span>
      <span className="text-primary-text text-[16px] text-white font-normal">
      Will begin shortly...
      </span>
    </div>
  );
};

export default Placeholder;
