import { useState, useEffect, useContext} from 'react';
import timerIcon  from '../assets/images/timer.png'
import { useMeetingState } from './chime/providers/MeetingStateProvider';
import { StyledControls } from '../components/chime/containers/MeetingControls/Styled';
import { useUserActivityState, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import {convertDateTimeFormatInLocalTimeZone} from "../utils/formatters"
import {UserContext} from '../contexts/userContext'
import { isMobile, isTablet} from 'react-device-detect';

interface MeetingHeaderProps {
  isIncognito?: boolean;
  endMeetingHandler: () => void;
  isPortrait: boolean;
}

const MeetingHeader: React.FC<MeetingHeaderProps> = ({ isIncognito = false, endMeetingHandler, isPortrait}) => {
  const { 
    meetingData, 
    theme, 
  } = useMeetingState();
  const { isUserActive } = useUserActivityState();
  const [timer, setTimer] = useState<string>('');
  const { user } = useContext(UserContext);

  const isPhone= () => {
    return isMobile && !isTablet  
  }

  useEffect(() => {
    if (typeof meetingData?.EventEndTime === 'string') {
      const timeFormatted = convertDateTimeFormatInLocalTimeZone(meetingData.EventEndTime).toString();
      const endMeetingTime = new Date(timeFormatted).getTime();
  
      const updateTimer = () => {
        const now = new Date().getTime();
        const distance = endMeetingTime - now;
  
        if (distance < 0) {
          // Handle case when the countdown is over
          setTimer('Meeting has ended');
          return;
        }
  
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
        // Construct timer string conditionally
        let timerString = '';
        if (hours > 0) {
          timerString += `${hours}h `;
        }
        if (minutes > 0 || hours > 0) { // Include minutes if there are hours
          timerString += `${minutes}m `;
        }
        timerString += `${seconds}s`;
  
        setTimer(timerString);
      };
  
      // Update timer every second
      updateTimer(); // Initial update
      const intervalId = setInterval(updateTimer, 1000);
  
      return () => clearInterval(intervalId); // Cleanup on component unmount
    }
  }, [meetingData?.EventEndTime]); // Adjusted dependency to match the data structure
  
  const getMarginTop=() => {
    let margin = isIncognito ? 'mt-14' : 'mt-5'
    
    if (!isPortrait) {
      margin ='mt-5'
    }

    return margin
    
  }

  return (
    <StyledControls active={!!isUserActive} isIncognito={isIncognito}>
    <div className={`${(isPhone() && isPortrait) ? 'w-[95%]' : ' min-w-[400px]' } ${(isIncognito && !isPortrait) ? 'ml-[25px]' : '' } h-[64px] self-center fixed shadow-lg ${getMarginTop()} top-0 left-1/2 transform -translate-x-1/2 ${theme === "light" ? "bg-white" : " bg-gray-controls-background bg-opacity-70 backdrop-blur"} rounded-full py-2  px-2 z-50`}>
        <div className={`flex flex-row justify-between  items-center`}> 
           <div  className={`flex min-w-[150px] flex-col justify-start mr-[16px] ml-[16px]`}>
            <div className={`${theme === "light" ? "text-black" : "text-white"}  font-bold  text-[14px]`}>{meetingData?.Event.Name} </div>  
            <div className="flex m-w-[150px]  items-center space-x-2 mt-[6px] text-[12px] ">  
              <img src={timerIcon} alt="Logo" className="h-[13px] w-[13px]" />
              <span className={`${theme === "light" ? "text-black" : "text-white"}`}>{timer}</span> {/* Timer text */}
            </div>
           </div>
           <button 
            onClick={() => {
              endMeetingHandler()
            }}
            className={`w-[150px] h-[50px] text-white p-2 rounded-full bg-error-button font-bold text-[14px]`}>
            Leave
          </button>
        </div>
      </div>
    </StyledControls>
  );
  
};

export default MeetingHeader;
