import React from 'react';
import { useNavigate } from 'react-router-dom';
import backIcon from '../assets/images/back-arrow.png'; // Adjust the path as per your project structure

interface NavBarProps {
  title?: string;
  navigationRoute?: string;
}

const NavBar: React.FC<NavBarProps> = ({ title, navigationRoute }) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (navigationRoute) {
      navigate(navigationRoute);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="h-[50px] flex items-center ">
      <button onClick={goBack} className="focus:outline-none flex items-center">
        <img src={backIcon} alt="Back" className="h-[12px] w-[7px] mr-[18px]" /> {/* Adjust size as needed */}
        <span className="text-primary-button text-[18px] font-bold">Back</span>
      </button>
      <span className="ml-4 text-lg font-semibold">{title}</span>
    </div>
  );
};

export default NavBar;
