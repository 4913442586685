import React, { ReactNode, useEffect } from 'react';
// Ensure MeetingEventObserver is imported if it's a separate component
// import MeetingEventObserver from './MeetingEventObserver';

interface FullScreenWrapperProps {
  children: ReactNode;
}


const FullScreenWrapper: React.FC<FullScreenWrapperProps> = ({ children }) => {
  useEffect(() => {
    const requestFullScreen = () => {
      const element: Element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if ((element as any).mozRequestFullScreen) {
        (element as any).mozRequestFullScreen();
      } else if ((element as any).webkitRequestFullscreen) {
        (element as any).webkitRequestFullscreen();
      } else if ((element as any).msRequestFullscreen) {
        (element as any).msRequestFullscreen();
      }
    };

    const exitFullScreen = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      }
    };

    requestFullScreen();

    return () => {
      exitFullScreen();
    };
  }, []);

  return <>{children}</>;
};

export default FullScreenWrapper;