// src/components/Prompt.tsx
import React, { useEffect } from 'react';
import { isMobile, isTablet} from 'react-device-detect';

interface PromptProps {
  message: string;
  isVisible: boolean;
  isPortrait: boolean;
  isIncognito: boolean;
  onClose: () => void;
  useTimer?: boolean;
}

const Prompt: React.FC<PromptProps> = ({ message, isVisible, isPortrait, isIncognito, onClose, useTimer = false  }) => {
  const isPhone= () => {
    return isMobile && !isTablet  
  }

  useEffect(() => {
    if (isVisible && useTimer) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <div
      className={`fixed left-1/2 transform -translate-x-1/2 bg-primary-button text-[18px] font-bold text-white h-[50px] rounded-full flex items-center justify-center z-50 cursor-pointer ${(isPhone() && isPortrait) ? 'w-[95%]' : 'w-[400px]'} ${isIncognito ? ' top-[140px]' : ' top-[100px]'}`}
      onClick={onClose}
    >
      {message}
    </div>
  );
};

export default Prompt;
