import { createContext, useState, useEffect } from 'react';
import * as Constants from '../constants';
import { Hub } from 'aws-amplify/utils';
import {  getCurrentUser, signOut, fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import routes from '../constants/routes';
import { Contact } from '../helpers/interfaces';
import Api from '../helpers/customAPIs';

interface UserContextType {
  user: Contact | null | undefined;  
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

interface DataProviderProps {
  children: React.ReactNode;
}

const initialContextValue: UserContextType = {
  user: null, // Or your initial user value
  isAuthenticated: false,
  setIsAuthenticated : () => {}
};

// Creating a data context to manage and access data across different component and pages.
export const UserContext = createContext<UserContextType>(initialContextValue);

export const UserProvider: React.FC<DataProviderProps> = ({ children }) => {
  const [user, setUser] = useState<Contact | null | undefined>(null); // Replace 'any' with your User type
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setAuthListener();
    getCurrentAuthState()
  }, []); 

  const setAuthListener = async () => {
    console.log('setAuthListener');
    Hub.listen('auth', async ({ payload }) => {
      console.log('setAuthListener payload.event',payload);
      switch (payload.event) {
        case 'signedIn':
          console.log('setAuthListener user have been signedIn successfully.');
          await getCurrentAuthState()
          setIsAuthenticated(true)
          navigate(`${routes.START}`);
          break;
        case 'signedOut':
          console.log('user have been signedOut successfully.');
          setUser(null)
          setIsAuthenticated(false)
          navigate(`${routes.LOGIN}`);
          break;
        case 'tokenRefresh':
          console.log('auth tokens have been refreshed.');
          break;
        case 'tokenRefresh_failure':
          console.log('failure while refreshing auth tokens.');
          break;
        case 'signInWithRedirect':
          console.log('signInWithRedirect API has successfully been resolved.');
          break;
        case 'signInWithRedirect_failure':
          console.log('failure while trying to resolve signInWithRedirect API.');
          break;
        case 'customOAuthState':
          console.log('custom state returned from CognitoHosted UI');
          break;
      }
    });
  };

  const getCurrentAuthState = async () => {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      if (username && userId) {
        try {
          let user = await Api.getUserData();
          setUser(user)
          console.log('getCurrentAuthState user', user)
        } catch (error) {
          setUser(null)
          console.log('fetchUserData error', error)
        }
        setIsAuthenticated(true)
        navigate(`${routes.START}`);
      }
    } catch {
      setUser(null)
      console.log("User is not uathentificated!")
    }
  }
  
  const fetchAuthToken = async () => {
    const result = await fetchAuthSession()
    console.log("fetchAuthSession",result)
  }

  return (
    <UserContext.Provider
      value={{
        user,
        isAuthenticated,
        setIsAuthenticated
      }}
    >
      {children}
    </UserContext.Provider>
  );
};



