import React, { ReactElement, useState, useEffect } from 'react'
import NavBarHeader from '../../components/NavBarHeader'
import ClassCard from '../../components/ClassCard';
import { useJoinMeeting } from '../../hooks/useJoinMeeting';
import { MeetingMode, VideoFiltersCpuUtilization } from '../../components/chime/types';
import {
  Modal,
  ModalBody,
  ModalHeader,
 } from 'amazon-chime-sdk-component-library-react';
import Card from '../../components/chime/components/Card';
import { RotatingLines as Spinner } from 'react-loader-spinner'
import DevicePermissionPrompt from '../../components/chime/containers/DevicePermissionPrompt';
import { useMeetingState } from '../../components/chime/providers/MeetingStateProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import Api from '../../helpers/customAPIs';
import { Contact, AttendanceRecord } from '../../helpers/interfaces';
import Colors from "../../constants/colors"
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import joinClassAnimation  from '../../assets/animation/joinClass.gif'
import PopUp from '../../components/Popup'
import routes from '../../constants/routes';


interface MeetingDetails {
  meetingEndDate: string;  
  meetingName: string;
  meetingId: string;  
  userId: string;  
  username: string; 
  image: string; 
  isInstructor: boolean;
}

function JoinMeeting(): ReactElement {

  const navigate = useNavigate()
  const location = useLocation();
  const [classData, setClassData] = useState<AttendanceRecord[]>()
  const [userData, setUserData] = useState<Contact>();
  const [isInstructor, setIsInstructor] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [usersCountryCode, setUsersCountryCode] = useState("ca")
  const [showPopup, setShowPopup] = useState(false)
  const { joinMeeting, isMeetingLoading, errorMessage, meetingID, updateErrorMessage } = useJoinMeeting();
  const { setMeetingData, closeWebView } = useMeetingState();
  const meetingManager = useMeetingManager();
  const [meetingDetails, setMeetingDatails] =  useState<MeetingDetails>();

  useEffect(() => {
    setIsLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const meetingDate = searchParams.get('meetingDate');
    const meetingName = searchParams.get('meetingName');
    const meetingId = searchParams.get('meetingId');
    const userId = searchParams.get('userId');
    const username = searchParams.get('username');
    const image = searchParams.get('image');
    const isInstructorString = searchParams.get('isInstructor');

    let isInstructor = false 
    if (isInstructorString && isInstructorString === "true") {
      isInstructor = true
    }

    console.log("meetingDetails", meetingName)
    console.log("meetingDate", meetingDate)
    console.log("meetingId", meetingId)
    console.log("userId", userId)
    console.log("username", username)
    console.log("image", image)
    console.log("isInstructor", isInstructor)
  

    if (meetingName && meetingDate && meetingId && userId && username && image) {
      const meetingDetails: MeetingDetails = {
        meetingEndDate: meetingDate,
        meetingName: meetingName,
        meetingId: meetingId,
        userId: userId,
        username: username,
        image: image,
        isInstructor: isInstructor
      }
      setMeetingDatails(meetingDetails)
      validateTokenAndLogin(meetingDetails);
    } else {
      setIsLoading(false);
      navigate(`*`);
    }
  }, [location]);

  useEffect(() => {
     if (errorMessage != '') {
      setShowPopup(true)
     }
  }, [errorMessage]);

  const validateTokenAndLogin = async (meetingDetails: MeetingDetails) => {
    handleJoinNowClick(meetingDetails)
    setIsLoading(isMeetingLoading);
  };

  const handleJoinNowClick = async (meetingDetails: MeetingDetails) => {
    const classItem: AttendanceRecord = {
      Contact: {
        Achievements: {},
        Birthdate: null,
        CreatedDate: null,
        DeviceInfo: {},
        DeviceTokens: null,
        Email: '',
        FirstName: '',
        Gender: '',
        InstructorBio: null,
        IsInstructor: meetingDetails.isInstructor,
        LastName: '',
        Membership: '',
        Notifications: null,
        Phone: null,
        ProfileImageUrl: null,
        Status: '',
        Survey: {
          workoutPreference: [],
          programs: [],
          workoutWeekDays: ''
        },
        contactID: meetingDetails.userId,
        countryCode: '',
        createdAt: '',
        id: '',
        updatedAt: ''
      },
      Event: {
        AssistantInstructors: '',
        AssistantInstructorsId: null,
        AssistantInstructorsInfo: [],
        Booked: 0,
        Capacity: 0,
        Description: '',
        Duration: 0,
        EventEndTime: meetingDetails.meetingEndDate,
        EventStartTime: '',
        Instructor: '',
        InstructorDetails: {
          Birthdate: '',
          CreatedDate: null,
          Email: '',
          FirstName: '',
          Gender: '',
          InstructorBio: '',
          IsInstructor: meetingDetails.isInstructor,
          LastName: '',
          Phone: null,
          ProfileImageUrl: null,
          Status: '',
          contactID: '',
          createdAt: '',
          id: '',
          instructor: {
            PhotoCard: null,
            PhotoThumb: null
          },
          updatedAt: ''
        },
        Name: meetingDetails?.meetingName ?? "Class",
        ParentId: '',
        PlannerEventExtra: '',
        Price: 0,
        RawEventDate: '',
        Recurrence: '',
        Remaining: 0,
        SubCategory: {
          BookingType: 0,
          Category: '',
          Description: '',
          Name: '',
          PreviewImage:  meetingDetails?.image ?? "",
          PreviewVideo: '',
          createdAt: null,
          id: ''
        },
        id: '',
        programEventsId: null,
        subCategoryEventsId: ''
      },
      EventEndTime: meetingDetails.meetingEndDate,
      EventStartDate: '',
      EventStartTime: meetingDetails.meetingEndDate,
      ModifiedDate: '',
      Status: '',
      contactAttendancesContactID: '',
      createdAt: '',
      eventAttendancesId: '',
      id: '',
      updatedAt: ''
    }
    
    const meetingMode = meetingDetails.isInstructor ? MeetingMode.Spectator : MeetingMode.Attendee
    console.log("isInstructor", meetingDetails.isInstructor)
    console.log("meetingMode", meetingMode)

    setMeetingData(classItem)
    // Need to pass cognitio user id as a first argument!!! 
   await joinMeeting("", meetingDetails.meetingId, meetingDetails.userId, meetingDetails.username, meetingMode, classItem);
       
   navigate(routes.DEVICEINC);
  };

  const leaveMeeting = async (): Promise<void> => {
    try {
      // Stop the local audio and video
      meetingManager.audioVideo?.stopLocalVideoTile();
      meetingManager.audioVideo?.stopAudioInput();
      
       // Leave the meeting
      await meetingManager.leave();
      // Leave the meeting
    } catch (error) {
      console.error('Failed to leave the meeting', error);
    }
  };

  const closeError = (): void => {
    updateErrorMessage('')
  };

  useEffect(() => {
    setIsLoading(true)
    leaveMeeting()
  }, []);

  useEffect(() => {
    console.log("isMeetingLoading", isMeetingLoading)
    setIsLoading(isMeetingLoading)
  }, [isMeetingLoading]);

  const endMeetingHandler= () => {
    closeWebView()
    setShowPopup(false)
  }

  const retryJoinMeetingHandler= () => {
    if (meetingDetails) {
      handleJoinNowClick(meetingDetails)
      updateErrorMessage('')
      setShowPopup(false)
    }
  }

  return (
    <div className="w-full h-screen pt-[100px] bg-primary-meeting-background">
       <div className="fixed inset-0 z-1 flex flex-col items-center justify-center">
          <div className="flex justify-center items-center text-center text-[30px] font-semibold text-primary-button mt-[48px] mb-[18px] w-[250px]">
          <img src={joinClassAnimation} alt="Animated GIF" />
          </div>
        </div>
        <PopUp 
            type={'cannotJoinMeeting'} 
            isOpen={showPopup} 
            onClose={endMeetingHandler}
            onActionOne={retryJoinMeetingHandler}
        />
    </div>
  )
}

export default JoinMeeting


