// Navigation.tsx
import React, { useEffect, useState } from 'react';
import FloatingTabBar from './FloatingTabBar';
import SideNavBar from './SideNavBar';
import { useNavigate } from 'react-router-dom';
import routes from '../constants/routes';
import { isMobile, isTablet} from 'react-device-detect';

const Navigation: React.FC = () => {
  const isPhone= () => {
    return isMobile && !isTablet
  }
  const [view, setView] = useState<'floating' | 'side'>(isPhone() ? 'floating': 'side');
  const [selectedMenu, setSelectedMenu] = useState<'start' | 'profile'>('start');
  const [animating, setAnimating] = useState(false);
  const navigate = useNavigate();

  const toggleView = () => {
    setAnimating(true);
    setTimeout(() => {
      setView((prevView) => (prevView === 'floating' ? 'side' : 'floating'));
      setAnimating(false);
    }, 500);
  };

  useEffect(() => {
    switch(selectedMenu) {
      case 'start':
       navigate(routes.START);
        break;
      case 'profile':
        navigate(routes.PROFILE);
        break;
      default:
        break;
    }
  }, [selectedMenu]);

  return (
    <div className={`relative ${view === 'side' ? 'w-[400px]' : ''}`}>
      {view === 'floating' ? (
        <div className={animating ? 'animate-fadeOut' : 'animate-fadeIn'}>
          <FloatingTabBar
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            toggleView={toggleView}
          />
        </div>
      ) : (
        <div className={animating ? 'animate-fadeOut' : 'animate-fadeIn'}>
          <SideNavBar
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            toggleView={toggleView}
          />
        </div>
      )}
    </div>
  );
};

export default Navigation;
