import { ErrorBoundary } from 'react-error-boundary';
import React from 'react';
interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

interface LocalErrorBoundaryProps {
  children: React.ReactNode;
}
interface GlobalErrorBoundaryProps {
  children: React.ReactNode;
}

interface GlobalErrorBoundaryState {
  hasError: boolean;
  error: { message: string; stack: string };
  info: { componentStack: string };
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
  console.error(error);

  return (
    <div>
      <h2>Something went wrong!</h2>
      {/* Optionally, you can render the error message and a button to reset the error boundary */}
      <p>{error.message}</p>
      <button onClick={resetErrorBoundary}>Try Again</button>
    </div>
  );
};

const LocalErrorBoundary: React.FC<LocalErrorBoundaryProps> = ({ children }) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
);

class GlobalErrorBoundary extends React.Component<GlobalErrorBoundaryProps, GlobalErrorBoundaryState> {
  state: GlobalErrorBoundaryState = {
    hasError: false,
    error: { message: '', stack: '' },
    info: { componentStack: '' },
  };

  static getDerivedStateFromError(error: Error): GlobalErrorBoundaryState {
    return { hasError: true, error: { message: error.message, stack: error.stack || '' }, info: { componentStack: '' } };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({
      error: { message: error.message, stack: error.stack || '' },
      // Use a fallback value to ensure componentStack is always a string
      info: { componentStack: info.componentStack || '' },
    });
  }
  

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // Pass resetErrorBoundary as a prop if you want to use it in the FallbackComponent
      return <ErrorFallback error={new Error(this.state.error.message)} resetErrorBoundary={this.resetErrorBoundary} />;
    }

    return children;
  }

  resetErrorBoundary = (): void => {
    this.setState({ hasError: false });
  };
}


export { GlobalErrorBoundary, LocalErrorBoundary };
