import React, { ReactElement, useState, useEffect, useContext } from 'react'
import { RotatingLines as Spinner } from 'react-loader-spinner'
import Api from '../../helpers/customAPIs';
import { Follower} from '../../helpers/interfaces';
import Colors from "../../constants/colors"
import {UserContext} from '../../contexts/userContext'
import PopUp from '../../components/Popup'
import { useNavigate } from 'react-router-dom';
import routes from '../../constants/routes';
import NavBar from '../../components/NavBar'

function Members(): ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useContext(UserContext);
  const [showPopup, setShowPopup] = useState(false)
  const [errorMessagePopup, setErrorMessagePopup] = useState("Somthing went wrong")
  const navigate = useNavigate();
  const [members, setMembers] = useState<Follower[]>([]);

  useEffect(() => {
    setIsLoading(true)
  }, []);


  useEffect(() => {
    fetchMembersList();
  }, []);

  const fetchMembersList = async () => {
    if (user) {
    }
  };

  const onSendMessageClick = () => {
     
  }


  return (
    <div className="w-full h-screen pt-[24px] px-[48px]">
      <NavBar/>
      <div className="">
        <div className="flex flex-row self-center items-center justify-between pb-[20px]">
          <div className="self-center text-[48px] ">Members</div>
          <button
              onClick={onSendMessageClick}
              className="min-w-[100px] h-[46px] bg-primary-button hover:bg-hover-button text-primary-text  text-[20px] font-bold py-2 px-6 rounded-full focus:outline-none focus:shadow-outline"
            >
              {"Send Message"}
            </button>
        </div>
        </div>
       
    {(isLoading) && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <Spinner 
            visible={true}
            width='40px'
            strokeColor= {Colors.primaryButton}
          />
        </div>
      )}
      {showPopup && (
        <PopUp 
          type={'error'} 
          isOpen={showPopup} 
          onClose={() => {setShowPopup(false)}}
          titleString='Cannot Join Class'
          messageString={errorMessagePopup}
        />
      )}
    </div>
  )
}

export default Members


