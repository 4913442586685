import React, { useState} from 'react'
import styled from 'styled-components';
import Sheet from 'react-modal-sheet';
import { isMobile, isTablet} from 'react-device-detect';
import { LeaveMeetingButton } from '../helpers/types';

interface BottomSheetProps {
  currentTheme: 'light' | 'dark';
  showBottomSheet: boolean;
  closeBottomSheet: () => void;
  isPortrait?: boolean;
}

const CustomSheet = styled(Sheet)<{ theme: 'light' | 'dark' }>`
  .react-modal-sheet-header {
    background-color: ${({ theme }) => theme === 'light' ? 'white' : '#333'};
  }
  .react-modal-sheet-backdrop {
    background-color: rgba(66, 66, 66, 0.7);  
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
  }
`;

const BottomSheet: React.FC<BottomSheetProps> = ({
  currentTheme,
  showBottomSheet,
  closeBottomSheet,
  isPortrait = true,
}) => {

 
  const isPhone= () => {
    return (isMobile && !isTablet) 
  }

  const getDisabledButtonBackground= (buttonType: LeaveMeetingButton) => {
    let background = buttonType === 'leave' ? 'bg-error-button' : 'bg-gray-button'
    return background
  }
 
  const getSnapPoint= () => {
    let shapPoint = 0.3 
    if (!isPortrait)  {
      shapPoint += 0.4
    }  
    return [shapPoint]
  }

  return (
    <CustomSheet
      theme={currentTheme}
      isOpen={showBottomSheet}
      onClose={closeBottomSheet}
      snapPoints={getSnapPoint()}
    >
      <Sheet.Container >
        <Sheet.Header />
        <Sheet.Content className="bg-white">
          <div className="flex flex-col px-[20px] justify-center items-center "> 
          <span className="mt-[5px]">{`ENV: ${import.meta.env.VITE_ENV}`}</span>
          <span className="mt-[5px]">{`CHIME_SERVER_URL: ${import.meta.env.VITE_CHIME_SERVER_URL}`}</span>
          <span className="mt-[5px]">{`BRANCH_IO_KEY: ${import.meta.env.VITE_BRANCH_IO_KEY}`}</span>
          <span className="mt-[5px]">{`AWS_APPSYNC_GRAPHQL_ENDPOINT: ${import.meta.env.VITE_AWS_APPSYNC_GRAPHQL_ENDPOINT}`}</span>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={closeBottomSheet} />
    </CustomSheet>
  );
};

export default BottomSheet;
