import React, { useState, useEffect, useRef } from 'react';
import { usePopup } from '../contexts/popupContext'; // Ensure the path is correct
import { MeetingManager, useLocalAudioOutput, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import styled from 'styled-components';
import Sheet from 'react-modal-sheet';
import {VideoShared, VideoItemProps} from '../helpers/interfaces'
import {ControlButton} from '../helpers/types'
import { useMeetingState } from './chime/providers/MeetingStateProvider';
import { isMobile, isTablet } from 'react-device-detect';

interface BottomSheetProps {
  currentTheme: 'light' | 'dark';
  showBottomSheet: boolean;
  closeBottomSheet: () => void;
  handleDeviceSelection: (deviceId: string, meetingManager: MeetingManager | undefined, buttonType: ControlButton) => void;
  isIncognito?: boolean; 
  buttonType: ControlButton;
  isPortrait?: boolean;
}

const CustomSheet = styled(Sheet)<{ theme: 'light' | 'dark' }>`
  .react-modal-sheet-header {
    background-color: ${({ theme }) => theme === 'light' ? 'white' : '#333'};
  }
  .react-modal-sheet-backdrop {
    background-color: rgba(66, 66, 66, 0.7);  
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
  }
`;

const VideoItem: React.FC<VideoItemProps> = ({ item, onClick }) => (
    <div className="w-full flex flex-col items-center" onClick={onClick}>
      <img src={item.thumbnailUrl} className=""></img>
      <p className="mt-2">{item.videoName}</p>
    </div>
  );


const BottomSheet: React.FC<BottomSheetProps> = ({
  currentTheme,
  showBottomSheet,
  closeBottomSheet,
  handleDeviceSelection,
  isIncognito = false,
  buttonType,
  isPortrait = false,
}) => {
  const { isAudioOn, toggleAudio } = useLocalAudioOutput()
  const {
    videoDevices,
    audioInputDevices,
    audioOutputDevices,
    selectedVideoDeviceId,
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    fetchDevicesForType,
    changeVideoInput,
    changeAudioInput,
    changeAudioOutput,
  } = useMeetingState();

  const isPhone = () => {
    return isMobile && !isTablet
  }
  const meetingManager = useMeetingManager();

  // Fetch devices when component mounts
  useEffect(() => {
    const fetchDevices = async () => {
      fetchDevicesForType(buttonType, meetingManager)
    };

    fetchDevices();
  }, []);

  const getDeviceList=  () => {
    if (buttonType === "video") {
      //console.log("getDeviceList videoDevices", videoDevices)
      //console.log("getDeviceList videoDevices", selectedVideoDeviceId)
      return videoDevices
    } else if (buttonType === 'microphone') {
      //console.log("getDeviceList audioInputDevices", audioInputDevices)
      //console.log("getDeviceList audioInputDevices", selectedAudioInputDeviceId)
      return audioInputDevices
    } else  if (buttonType === "speaker") {
      //console.log("getDeviceList audioOutputDevices", audioOutputDevices)
      //console.log("getDeviceList audioInputDevices", selectedAudioOutputDeviceId)
      return audioOutputDevices
    }
    return []
  }

  const  handleDeviceSelected= (deviceId: string, meetingManager: MeetingManager | undefined, buttonType: ControlButton) => {
    //console.log("handleDeviceSelection deviceId ", deviceId)
    //console.log("handleDeviceSelection buttonType ", buttonType)
    if (buttonType === "video") {
      changeVideoInput(deviceId, meetingManager)
    } else if (buttonType === 'microphone') {
        changeAudioInput(deviceId, meetingManager)
        meetingManager?.meetingSession?.audioVideo?.realtimeUnmuteLocalAudio()
    } else  if (buttonType === "speaker") {
        changeAudioOutput(deviceId, meetingManager)
        toggleAudio()
    }
   }

  const getSelectedDeviceId= () => {
    if (buttonType === "video") {
      return selectedVideoDeviceId
    } else if (buttonType === 'microphone') {
      return selectedAudioInputDeviceId
    } else  if (buttonType === "speaker") {
      return selectedAudioOutputDeviceId
    }
    return []
  }

  const getSnapPoint= () => {
    let shapPoint = 0.05 * getDeviceList().length
     
    if (isPortrait)  { 
      shapPoint += 0.15
    } else {
      shapPoint += 0.4
    }

    if (isIncognito) {
      shapPoint -= 0.0
    }
   
    return [shapPoint]
  }
 
  return (
    <CustomSheet
      theme={currentTheme}
      isOpen={showBottomSheet}
      onClose={closeBottomSheet}
      snapPoints={getSnapPoint()}
    >
      <Sheet.Container >
        <Sheet.Header />
        <Sheet.Content className="bg-dark-mode-background">
          <Sheet.Scroller draggableAt="both"> 
          <div className="flex flex-col px-[20px] pt-[10px] justify-center items-center "> 
          {getDeviceList().map(device => (
            <button
              className={` w-full max-w-[400px] h-[50px] text-start px-[20px] rounded-[10px] ${getSelectedDeviceId() === device.deviceId ? 'bg-meeting-button' : 'bg-transparent'}`}
              key={device.deviceId}
              onClick={() => {
                handleDeviceSelected(device.deviceId, meetingManager, buttonType);
                closeBottomSheet(); 
              }}>
              {device.label || "Unnamed Device"}
            </button>
          ))}
          </div>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={closeBottomSheet} />
    </CustomSheet>
  );
};

export default BottomSheet;
