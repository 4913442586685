import React, { useState, useEffect, useRef, useContext } from 'react'
import BottomSheetShareVideo from '../../components/BottomSheetShareVideo';
import BottomSheetDeviseSelection from '../../components/BottomSheetDeviseSelection';
import BottomSheetLeaveMeeting from '../../components/BottomSheetLeaveMeeting';
import { VideoTileGrid, UserActivityProvider, MeetingStatus, MeetingManager, useAudioVideo, } from 'amazon-chime-sdk-component-library-react';
import {
  LocalVideo,
  RemoteVideos,
  useLocalVideo,
  useLocalAudioOutput,
  useRemoteVideoTileState,
  VideoGrid,
  ContentShare,
  useMeetingManager,
  useMeetingStatus
} from 'amazon-chime-sdk-component-library-react';
import { StyledLayout, StyledContent, localVideoFluid, localVideoStatic, remoteVideoSingle, localVideoInstructor, remoteVideoMultiple } from './Styled';
import NavigationControl from '../../components/chime/containers/Navigation/NavigationControl';
import { useNavigation } from '../../components/chime/providers/NavigationProvider';
import MeetingControls from '../../components/chime/containers/MeetingControls';
import useMeetingEndRedirect from '../../components/chime/hooks/useMeetingEndRedirect';
import { MeetingMode } from '../../components/chime/types';
import { VideoTileGridProvider } from '../../components/chime/providers/VideoTileGridProvider';
import { useMeetingState } from '../../components/chime/providers/MeetingStateProvider';
import { DataMessagesProvider } from '../../components/chime/providers/DataMessagesProvider';
import MeetingHeader from '../../components/MeetingHeader'
import MeetingPlaceholder from '../../components/MeetingPlaceholder'
import FullScreenWrapper from '../../components/FullScreenWrapper';
import { EventName, EventAttributes } from 'amazon-chime-sdk-js';
import routes from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { MeetingSessionStatusCode, MeetingSessionStatus, VideoTileState } from 'amazon-chime-sdk-js';
import { RotatingLines as Spinner } from 'react-loader-spinner'
import Colors from "../../constants/colors"
import { VideoObject, HTMLVideoElementWithCaptureStream, VideoShared } from '../../helpers/interfaces'
import { UserContext } from '../../contexts/userContext'
import { PopupType } from '../../helpers/types'
import { isMobile, isTablet, isIPhone13, isIPad13 } from 'react-device-detect';
import { LeaveMeetingButton } from '../../helpers/types';
import {ControlButton} from '../../helpers/types'
import SharingVideoGroup from '../../components/SharingVideoGroup';
import { useOrientationChange } from '../../hooks/useOrientationChange';
import Api from '../../helpers/customAPIs';
import FullScreenBluredOverlay from '../../components/FullScreenBluredOverlay';
import Prompt from '../../components/Prompt';


const MeetingView = (props: { mode: MeetingMode }) => {
  const [isPortraitTile, setisPortraitTile] = useState(true);
  const { user } = useContext(UserContext);
  const { tiles, tileIdToAttendeeId, attendeeIdToTileId, size } = useRemoteVideoTileState();
  const { isAudioOn, toggleAudio } = useLocalAudioOutput()
  const { toggleVideo, isVideoEnabled, setIsVideoEnabled } = useLocalVideo()
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupType, setPopupType] = useState<PopupType>('endMeeting');
  const meetingStatus = useMeetingStatus();
  const manager = useMeetingManager();
  const { showNavbar, showRoster, showChat } = useNavigation();
  const { mode } = props;
  const {
    meetingData,
    videoEnabled,
    microphoneEnabled,
    speakerEnabled,
    selectedVideoDeviceId,
    observerDeviceList,
    changeVideoInput,
    changeAudioInput,
    changeAudioOutput,
    leaveMeeting,
    endMeetingForAll,
    closeWebView,
  } = useMeetingState();
  const [showLeaveMeeting, setShowLeaveMeeting] = useState(false);
  const [showDeviceSelection, setShowDeviceSelection] = useState(false);
  const [showShareContent, setShowShareContent] = useState(false)
  const [showContentShareControls,  setShowContentShareControls] = useState(false)
  const [meetingManager, setMeetingManager] = useState(manager)
  const bucketName = 'step-content-share-bucket';
  const [videoList, setVideoList] = useState<VideoShared[]>([]);
  const [snapPoint, setSnapPoint] = useState(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [videoVolume, setVideoVolume] = useState(1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [showPlaceholder, setShowPlaceholder] = useState(false)
  let canvasStreamCapture: { stream: MediaStream; stop: () => void; } | null = null;
  const [deviceSelectionButton, setDeviceSelectionButton] = useState<ControlButton>('video')
  const isPortrait = useOrientationChange();
  const hiddenButtonRef = useRef<HTMLButtonElement>(null);
  const [isPromptVisible, setIsPromptVisible] = useState(false);
  const [promptMessage, setPromptMessage] = useState('');
  const audioVideo = useAudioVideo();
  
  const isPhone = () => {
    return isMobile && !isTablet
  }

  const isIOS = (): boolean => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  };

  function adjustHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }

  useEffect(() => {
    handleResize();
    adjustHeight();
    window.addEventListener('resize', handleResize);
    window.addEventListener('resize', adjustHeight);
    window.addEventListener('orientationchange', adjustHeight);
    meetingManager?.meetingSession?.audioVideo.addDeviceChangeObserver(observerDeviceList);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
      meetingManager?.meetingSession?.audioVideo.removeDeviceChangeObserver(observerDeviceList);
    }
  }, []);

  // Disables scroling for the page
  useEffect(() => {
    // When the component mounts
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';

    // When the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    };
  }, []);

  // Set up settings for audio and video
  useEffect(() => {
    async function setUp() {
      if (meetingStatus === MeetingStatus.Succeeded) {
        setMeetingManager(meetingManager)

        if (videoEnabled && !isVideoEnabled && !audioVideo?.hasStartedLocalVideoTile()) {
          // Enable video
          await changeVideoInput(selectedVideoDeviceId, meetingManager)
          await toggleVideo();
        }  

        if (microphoneEnabled) {
           meetingManager.meetingSession?.audioVideo?.realtimeUnmuteLocalAudio()
        } else {
           meetingManager?.audioVideo?.realtimeMuteLocalAudio()
        }
        
        if (speakerEnabled && !isAudioOn) {
          toggleAudio()
        }
        setIsLoading(false)
      }
    }
 
    setIsLoading(true)
    setUp();
    checkLocalTileOrientation()
  }, [meetingStatus]);

  // Set up video when enabled 
  useEffect(() => {
    async function setUp() {
        if (isVideoEnabled) {
          changeVideoInput(selectedVideoDeviceId, meetingManager)
         }
      }
    setUp();
   
  }, [isVideoEnabled]);


  useEffect(() => {
    if (meetingManager.meetingSession?.audioVideo) {
      meetingManager.meetingSession?.audioVideo.addContentShareObserver(observerShare)
      meetingManager.meetingSession?.eventController.addObserver(oserverEvent)
      console.log("observerShare set")
      console.log("oserverEvent set")
    }

    if (meetingManager) {
      meetingManager.audioVideo?.addObserver(observerMeeting);
      console.log("observerMeeting set")
    }

    return () => {
      // Remove on component unmount
      if (canvasRef.current) canvasRef.current.remove();
      canvasRef.current = null;
      // Remove observers
      if (meetingManager.meetingSession?.audioVideo) {
        meetingManager.meetingSession?.audioVideo.removeContentShareObserver(observerShare)
        meetingManager.meetingSession?.eventController.removeObserver(oserverEvent)
      }
      if (meetingManager) {
        meetingManager.audioVideo?.removeObserver(observerMeeting);
      }
    };

  }, [meetingStatus]);

   const checkLocalTileOrientation = () => {
        let localVideoTile =  meetingManager?.audioVideo?.getLocalVideoTile()
        if (localVideoTile) {
          // console.log("state():", localVideoTile.state());
          const state = localVideoTile.state()
          const height = state.videoStreamContentHeight
          const width = state.videoStreamContentWidth
          if (width && height) {
            const aspectRatio = width / height;
            setisPortraitTile(aspectRatio < 1 );
          }  
        }
    };

   const toggleVideoButton= async () => {
      await toggleVideo()
   }

   const fetchVideoList = async () => {
      try {
        const videoList = await Api.getS3VideoList(user?.id ?? "");
        setVideoList(videoList);
      } catch (error) {
        console.log('fetchVideoList error: ', error)
      }
  };

  useEffect(() => {
    if (mode === MeetingMode.Spectator) {
      fetchVideoList()
    }
  }, [showShareContent]);

  const observerShare = {
    contentShareDidStop: () => {
      console.log('Content share stopped');
      if (videoRef.current) {
        videoRef.current.pause();
      }
    },

    contentShareDidStart: () => {
      console.log('Screen share started');
      setShowContentShareControls(true)
    },

    contentShareDidPause: () => {
      console.log('Content Share Did Pause');
    },

    contentShareDidUnpause: () => {
      console.log('Content Share Did Unpause');
    }
  };

  const observerMeeting = {
    audioVideoDidStop: (sessionStatus: MeetingSessionStatus) => {
      console.log('Adio Video Did Stop');
      const sessionStatusCode = sessionStatus.statusCode();
      if (sessionStatusCode === MeetingSessionStatusCode.Left || sessionStatusCode === MeetingSessionStatusCode.MeetingEnded) {
        console.log('The meeting ended or the user left the meeting');
        closeWebView()
        navigate(routes.MEETINGINCENDED)
      } else {
        console.error('Meeting stopped with session status code:', sessionStatusCode)
      }
    },

    audioVideoDidStart: () => {
      console.log('Audio Video Did Start');
      let localVideoTile =  meetingManager?.audioVideo?.getLocalVideoTile()
      checkLocalTileOrientation()
    },

    audioVideoWasDemotedFromPrimaryMeeting: (status: MeetingSessionStatus) => {
      console.log('Audio Video Was Demoted From Primary Meeting', status);
    },

    videoTileWasRemoved: (tileId: number) => {
      console.log('Video Tile Was Removed', tileId);
      const tilesCount = meetingManager.audioVideo?.getAllRemoteVideoTiles().length
      if (tilesCount) {
        setShowPlaceholder(tilesCount === 0 && mode != MeetingMode.Spectator)
      } else {
        setShowPlaceholder(mode != MeetingMode.Spectator)
      }
      checkLocalTileOrientation()
    },

    videoTileDidUpdate: (ttileState: VideoTileState) => {
      console.log('Video Tile Did Update', ttileState);
      const tilesCount = meetingManager.audioVideo?.getAllRemoteVideoTiles().length
      if (tilesCount) {
        setShowPlaceholder(tilesCount === 0 && mode != MeetingMode.Spectator)
      } else {
        setShowPlaceholder(mode != MeetingMode.Spectator)
      }
      checkLocalTileOrientation()
    }
  };

  const oserverEvent = {
    eventDidReceive: (name: EventName, attributes: EventAttributes) => {
      console.log("Oserver Event name", name)
      console.log("Oserver Event attributes", attributes)
    }
  }

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused || videoRef.current.ended) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const changeVolume = (event: { target: { value: string; }; }) => {
    const volume = parseFloat(event.target.value);
    if (videoRef.current) {
      videoRef.current.volume = volume;
    }
    setVideoVolume(volume);
  };

  const handleResize = () => {
    const maxHeight = window.innerHeight;
    let newSnapPoint;

    if (window.innerWidth >= 1024) { // 'lg' breakpoint and above
      newSnapPoint = maxHeight - maxHeight / 4; // e.g., 3/4 of the height
    } else if (window.innerWidth >= 768) { // 'md' breakpoint
      newSnapPoint = maxHeight - maxHeight / 4; // e.g., 4/5 of the height
    } else {
      newSnapPoint = maxHeight - maxHeight / 5; // Default for smaller devices
    }

    setSnapPoint(newSnapPoint);
  };

  const handleShareVideo = (): void => {
    setShowShareContent(true)
  };

  const captureStreamFromCanvas = async () => {
    if (!videoRef.current) return null;
  
    if (canvasRef.current) canvasRef.current.remove();
    canvasRef.current = null;
  
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvasRef.current = canvas;
    let isStreaming = true;
  
    const drawFrame = () => {
      if (!isStreaming || !videoRef.current || !canvasRef.current) return;
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        requestAnimationFrame(drawFrame);
      }
    };
  
    if (videoRef.current.readyState >= 2) {
      drawFrame();
    } else {
      videoRef.current.addEventListener('canplay', () => {
        drawFrame();
      });
    }
  
    const stream = canvas.captureStream(25);
  
    const stop = () => {
      isStreaming = false;
    };
  
    return { stream, stop };
  };
  

  const handleShareVideoClick = async (item: VideoObject) => {
    console.log("handleShareVideoClick", item);
    // Close bottom sheet
    setShowShareContent(false)
    // Show prompt
    setPromptMessage('Sharing video: ' + item.videoName)
    showPrompt()

    // Fetch the video
    const url = item.videoUrl.toString()
    fetch(url, { method: 'GET' })
      .then(response => console.log(response))
      .catch(error => console.error('Error fetching video:', error));
    
      // Set the video source and play it
    videoRef!.current!.src = url
    await videoRef!.current?.play();
   
    if (isIOS()) {
      console.log("iOS devices: Use the canvas-based approach")
      // iOS devices: Use the canvas-based approach
      const campture = await captureStreamFromCanvas();
      if (campture?.stream) {
        await meetingManager?.meetingSession?.audioVideo.startContentShare(campture.stream);
      }
    } else {
      console.log("Non-iOS devices: Use the standard captureStream approach")
      // Non-iOS devices: Use the standard captureStream approach
      if (videoRef.current) {
        const videoElement: HTMLVideoElementWithCaptureStream = videoRef.current as HTMLVideoElementWithCaptureStream;
        const mediaStream = videoElement.captureStream ? videoElement.captureStream() : videoElement.mozCaptureStream ? videoElement.mozCaptureStream() : null;
        if (mediaStream) {
          await meetingManager?.meetingSession?.audioVideo.startContentShare(mediaStream);
        }
      }
    }

    // Unmute the video
    if (videoRef.current) {
      videoRef.current.muted = false;
    }
  };

  const closeContentShared = (): void => {
    setShowShareContent(false)
  }


  const closeLeaveMeeting = (): void => {
    setShowLeaveMeeting(false)
  }

  const closeDeviceSelection = (): void => {
    setShowDeviceSelection(false)
  }

  const stopContentShare = (): void => {
    meetingManager.meetingSession?.audioVideo.stopContentShare();

    if (canvasStreamCapture) {
      canvasStreamCapture.stop(); // Stop canvas streaming
      canvasStreamCapture = null; // Reset the variable
    }

    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.pause();
      // Reset the video source if necessary
    }
    setShowContentShareControls(false)
    setShowShareContent(false);
  };

  const endMeetingHandler = () => {
    setShowLeaveMeeting(true)
  }

  const handleLeaveMeeting= (buttonType: LeaveMeetingButton) => {
    closeLeaveMeeting()
    setIsLoading(true)
    if (buttonType === 'end') {
      // Show prompt
      setPromptMessage('The class is going to be ended for all...')
      showPrompt()
      endMeetingForAll(meetingManager)
    } else {
      leaveMeeting(meetingManager)
    }
   }

   const  handleOpenDeviceSelection= (buttonType: ControlButton) => {
    setDeviceSelectionButton(buttonType)
    setShowDeviceSelection(true)
   }

   const  handleDeviceSelection= (deviceId: string, meetingManager: MeetingManager | undefined, buttonType: ControlButton) => {
    if (buttonType === "video") {
      changeVideoInput(deviceId, meetingManager)
    } else if (buttonType === 'microphone') {
      if (microphoneEnabled) {
        changeAudioInput(deviceId, meetingManager)
        meetingManager?.meetingSession?.audioVideo?.realtimeUnmuteLocalAudio()
      } else {
        meetingManager?.audioVideo?.realtimeMuteLocalAudio()
      }
    } else  if (buttonType === "speaker") {
      if (speakerEnabled && !isAudioOn) {
        changeAudioOutput(deviceId, meetingManager)
        toggleAudio()
      }
    }
   }

   const showPrompt = () => {
    setIsPromptVisible(true);
  };

  const closePrompt = () => {
    setIsPromptVisible(false);
  };

  return (
    <>
      <FullScreenWrapper>
        <div className="w-full h-screen bg-dark-mode-background"  >
          <UserActivityProvider>
            <DataMessagesProvider>
              <VideoTileGridProvider>
                <StyledLayout showNav={showNavbar} showRoster={showRoster} showChat={showChat} isPortrait={isPortrait}>
                  <StyledContent>
                  <VideoTileGrid />
                    {/* { (size >= 1 ||  mode == MeetingMode.Attendee) ?  ( 
                        <VideoTileGrid />
                      ) : ( 
                        <VideoGrid size={size} layout='standard'>
                          <ContentShare />
                          <RemoteVideos
                            css={localVideoFluid}
                          />
                          <LocalVideo
                            nameplate="Me"
                            css={localVideoInstructor(isPortraitTile)}
                          />
                        </VideoGrid>
                      ) 
                    } */}
                    {/* <VideoTileGrid />
                    <VideoGrid size={gridSize} layout='standard'>
                      <ContentShare />
                      <RemoteVideos
                        css={gridSize > 1 ? remoteVideoMultiple : remoteVideoSingle}
                      />
                      <div className={` ${gridSize > 1 ? 'relative' : ''}`}>
                        <LocalVideo
                          nameplate="Me"
                          css={gridSize > 1 ? localVideoFluid : localVideoStatic(isMobile)}
                        />
                        {isPhone() && isVideoEnabled &&
                          <button
                            onClick={() => switchCamera(meetingManager)}
                            className={`absolute  ${flipIconPossition()} w-[42px] h-[42px]`}>
                            <img src={flippIcon} alt="Switch Camera" className=" " />
                          </button>
                        }
                      </div>
                    </VideoGrid> */}
                    
                    <MeetingHeader endMeetingHandler={endMeetingHandler} isPortrait={isPortrait} isIncognito={true}/>
                    {/* Show blurred overlay if there is only one student */}
                    { (size > 0) ? 
                      <MeetingPlaceholder showPlaceholder={showPlaceholder} meetingName={meetingData?.Event.Name ?? ""} thumbnail={meetingData?.Event.SubCategory.PreviewImage ?? ""} />
                    :
                      <FullScreenBluredOverlay isVisible={(mode !== MeetingMode.Spectator)} onClose={()=> {}} >  
                          <MeetingPlaceholder showPlaceholder={showPlaceholder} meetingName={meetingData?.Event.Name ?? ""} thumbnail={meetingData?.Event.SubCategory.PreviewImage ?? ""} />
                      </FullScreenBluredOverlay>
                    }
                    <MeetingControls isInstructor={mode === MeetingMode.Spectator} handleShareVideo={handleShareVideo} handleOpenDeviceSelection={handleOpenDeviceSelection} isIncognito={true} meetingManager={meetingManager} />
                  </StyledContent>
                  <NavigationControl handleShareVideo={handleShareVideo} />
                </StyledLayout>
              </VideoTileGridProvider>
            </DataMessagesProvider>
          </UserActivityProvider>
        </div>
        {/* Sharing_video_group" */}
        <SharingVideoGroup
          videoRef={videoRef}
          togglePlayPause={togglePlayPause}
          changeVolume={changeVolume}
          videoVolume={videoVolume}
          stopContentShare={stopContentShare}
          isDisplayed={showContentShareControls}
          isIncognito={true}
        />
        {(isLoading) && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <Spinner
              visible={true}
              width='40px'
              strokeColor={Colors.primaryButton}
            />
          </div>
        )}
        <Prompt message={promptMessage} isVisible={isPromptVisible} onClose={closePrompt} isPortrait={isPortrait} isIncognito={true}/>
        <BottomSheetShareVideo
          currentTheme={'dark'}
          showBottomSheet={showShareContent}
          closeBottomSheet={closeContentShared}
          handleShareVideoClick={handleShareVideoClick}
          snapPoints={[snapPoint]}
          videoList={videoList}
          isIncognito={true}
        />
         <BottomSheetDeviseSelection
          currentTheme={'dark'}
          showBottomSheet={showDeviceSelection}
          closeBottomSheet={closeDeviceSelection}
          handleDeviceSelection={handleDeviceSelection}
          buttonType={deviceSelectionButton}
          isPortrait={isPortrait}
          isIncognito={true}
        />
         <BottomSheetLeaveMeeting
          currentTheme={'dark'}
          showBottomSheet={showLeaveMeeting}
          closeBottomSheet={closeLeaveMeeting}
          handleLeaveMeeting={handleLeaveMeeting}
          isInstructor={mode === MeetingMode.Spectator}
          isLoading={isLoading}
          isPortrait={isPortrait}
          isIncognito={true}
        />
        {/* Fake button for local video preview  */}
         <button
            ref={hiddenButtonRef}
            style={{ display: 'none' }} // Hide the button
            onClick={toggleVideoButton}/>
      </FullScreenWrapper>
    </>
  );
};

export default MeetingView;