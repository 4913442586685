// src/components/FullScreenBlurredOverlay.tsx
import React, { ReactNode } from 'react';

interface FullScreenBlurredOverlayProps {
  isVisible: boolean;
  onClose: () => void;
  children?: ReactNode;
}

const FullScreenBlurredOverlay: React.FC<FullScreenBlurredOverlayProps> = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-0 flex items-center justify-center"
      onClick={onClose}
      style={{ zIndex: 0 }}
    >
      <div onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default FullScreenBlurredOverlay;
