import React, { useEffect } from 'react';
import placeholder  from '../../assets/images/thanks_person.png'
import { useMeetingState } from '../../components/chime/providers/MeetingStateProvider';

const MeetingEnded = () => {
  const { closeWebView} = useMeetingState();

  const close= () => {
    closeWebView()
  }


  return (
    <div className="w-full h-screen pt-[100px] bg-primary-meeting-background text-white">
    <div className="fixed inset-0 z-50 flex flex-col items-center text-center justify-center">
        <div className="flex justify-center items-center text-[30px] text-primary-button font-semibold  mt-[48px] mb-[18px]">
          Class ended. Thank you! 
        </div>  
        <img src={placeholder} alt="placeholder" className="h-[250px] w-[200px] mt-7"/>
        <button 
            onClick={() => {
              close()
            }}
            className={`text-white font-bold bg-primary-button p-2 w-[100px] rounded-full text-[20px] mt-[30px]`}>
            Close
          </button>
      </div>
  </div>
  );
};

export default MeetingEnded;
