 
import { i } from "vite/dist/node/types.d-aGj9QkWt";
import { ApiResponse } from "../helpers/interfaces";

const INVITE_URL = import.meta.env.VITE_BRANCH_IO_INVITE_URL;
const DELETE_URL = import.meta.env.VITE_BRANCH_IO_DELETE_URL;
const UPDATE_URL =  import.meta.env.VITE_BRANCH_IO_UPDATE_URL;

export async function inviteFollower(
  inviterID: string,
  inviterImage: string,
  inviterName: string,
  inviterType: string,
  followerName: string,
  endpoint: string,
  channel: string,
  className: string,
  isEdited: boolean,
): Promise<ApiResponse> {

  let url = encodeURI(
    INVITE_URL +
    "?" +
    `inviterID=${inviterID}&inviterImage=${inviterImage}&inviterName=${inviterName}&inviterType=${inviterType}&followerName=${followerName}&endpoint=${endpoint}&channel=${channel}&className=${className}&isEdited=${isEdited}`
  );

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data = await res.json();
  if (res.ok) {
    return {statusCode: res.status, success: true, message: data.message};
  }
  return {statusCode: res.status, success: false, message: data.message};
}

export async function updateFollower(
  inviterID: string,
  followerID: string,
  followerImage: string,
  followerName: string,
  endpoint: string,
): Promise<ApiResponse> {

  let url = encodeURI(
    UPDATE_URL +
    "?" +
    `inviterID=${inviterID}&followerID=${followerID}&followerImage=${followerImage}&followerName=${followerName}&endpoint=${endpoint}`
  );

  console.log("url", url)

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
 console.log("res", res)
  const data = await res.json();
  console.log("data", data)
  if (res.ok) {
    return {statusCode: res.status, success: true, message: data.message};
  }
  return {statusCode: res.status, success: false, message: data.message};
}

export async function deleteInvite(
  inviterID: string,
  id: string,
  link: string,
): Promise<ApiResponse> {

  let url = encodeURI(
    DELETE_URL +
    "?" +
    `inviterID=${inviterID}&id=${id}&link=${link}`
  );

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data = await res.json();
  if (res.ok) {
    return {statusCode: res.status, success: true, message: data.message};
  }
  return {statusCode: res.status, success: false, message: data.message};
}