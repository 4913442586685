
import styled from 'styled-components';

interface StyledProps {
  active: boolean;
  isIncognito: boolean;
}

export const StyledControls = styled.div<StyledProps>`
  display: flex;
  justify-content: center;  
  align-items: center;
  flex-wrap: wrap;
  opacity: ${props => (props.active ? '1' : '0')};
  transition: opacity 250ms ease;

  .header {
    height: 300x;
  }

  .controls-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;  
    width: auto;
    height: 65px;
    position: static;
    border-radius: 30px;
    margin-bottom: 20px;
    background-color: rgba(66, 66, 66, 0.7);  
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);

  }
`;
