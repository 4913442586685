export const listEventOccurrences = /* GraphQL */ `
  query listEventOccurrences(
    $filter: ModelEventFilterInput
    $filterEvents: ModelEventOccurrenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Name
        id
        ParentId
        EventStartDate
        EventEndDate
        StartDate
        EndDate
        Remaining
        Instructor
        AssistantInstructors
        Duration
        Recurrence
        Description
        PlannerEventExtra
        Price
        Capacity
        Booked
        EventOccurrences(filter: $filterEvents) {
          items {
            id
            ParentEventId
            StartTime
            EndTime
            OccurrenceId
            Status
            Name
            RawEventDate
            EventStartDate
            EventEndDate
            eventSubCategoryId
            createdAt
            updatedAt
            eventEventOccurrencesId
          }
          nextToken
        }
        InstructorDetails {
          id
          FirstName
          LastName
          Email
          Gender
          Phone
          CreatedDate
          Birthdate
          ProfileImageUrl
          Status
          IsInstructor
          InstructorBio
          instructor {
            PhotoCard
            PhotoThumb
          }
          contactID
          createdAt
          updatedAt
        }
        AssistantInstructorsInfo {
          id
          FirstName
          LastName
          Email
          Gender
          Phone
          CreatedDate
          Birthdate
          ProfileImageUrl
          Status
          IsInstructor
          instructor {
            PhotoCard
            PhotoThumb
          }
          InstructorBio
          contactID
          createdAt
          updatedAt
        }
        SubCategory {
          id
          Name
          Category
          Description
          PreviewImage
          PreviewVideo
          BookingType
          createdAt
          updatedAt
        }
        
      }
    }
  }
`;

export const EventOccurrencesList = /* GraphQL */ `
  query EventOccurrencesList(
    $filter: ModelEventOccurrenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventOccurrences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        EndTime
        StartTime
        id
        EventStartDate
        EventEndDate
        ParentEventId
        Event {
          AgeTo
          Name
          id
          StartDate
          EndDate
          Remaining
          Instructor
          AssistantInstructors
          Duration
          Recurrence
          Description
          PlannerEventExtra
          Price
          Capacity
          Booked
          SubCategory {
            id
            Name
            Category
            Description
            PreviewImage
            PreviewVideo
            BookingType
            createdAt
            updatedAt
          }
          AssistantInstructorsInfo {
            id
            FirstName
            LastName
            Email
            Gender
            Phone
            CreatedDate
            Birthdate
            ProfileImageUrl
            Status
            IsInstructor
            instructor {
              PhotoCard
              PhotoThumb
            }
            InstructorBio
            contactID
            createdAt
            updatedAt
          }
          InstructorDetails {
            id
            FirstName
            LastName
            Email
            Gender
            Phone
            CreatedDate
            Birthdate
            ProfileImageUrl
            Status
            IsInstructor
            InstructorBio
            instructor {
              PhotoCard
              PhotoThumb
            }
            contactID
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;

export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        EventStartDate
        EventEndDate
        StartDate
        EndDate
        IsCourse
        Recurrence
        Description
        ParentId
        Capacity
        Booked
        LevelFrom
        LevelTo
        AgeFrom
        AgeTo
        Gender
        Instructor
        AssistantInstructors
        InstructorId
        AssistantInstructorsId
        InstructorInfo {
          id
          FirstName
          LastName
          Email
          Gender
          Phone
          CreatedDate
          Birthdate
          ProfileImageUrl
          Membership
          Status
          IsInstructor
          InstructorBio
          Survey
          Notifications
          DeviceInfo
          DeviceTokens
          contactID
          createdAt
          updatedAt
        }
        InstructorDetails {
          id
          FirstName
          LastName
          Email
          Gender
          Phone
          CreatedDate
          Birthdate
          ProfileImageUrl
          Membership
          Status
          IsInstructor
          InstructorBio
          Survey
          Notifications
          DeviceInfo
          DeviceTokens
          contactID
          createdAt
          updatedAt
          instructor {
            PhotoCard
            PhotoThumb
          }
        }
        AssistantInstructorsInfo {
          id
          FirstName
          LastName
          Email
          Gender
          Phone
          CreatedDate
          Birthdate
          ProfileImageUrl
          Membership
          Status
          IsInstructor
          InstructorBio
          Survey
          Notifications
          DeviceInfo
          DeviceTokens
          contactID
          createdAt
          updatedAt
          instructor {
            PhotoCard
            PhotoThumb
          }
        }
        Fees
        Extras
        Duration
        Remaining
        PriceScheme
        Price
        SubCategory {
          id
          Name
          Category
          Description
          PreviewImage
          PreviewVideo
          BookingType
          createdAt
          updatedAt
        }
        Attendances {
          nextToken
        }
        EventOccurrences {
          nextToken
        }
        PlannerEventExtra
        RawEventDate
        createdAt
        updatedAt
        contactEventsContactID
        subCategoryEventsId
        programEventsId
      }
      nextToken
    }
  }
`;

export const getContact = /* GraphQL */ `
  query GetContact($contactID: ID!) {
    getContact(contactID: $contactID) {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      countryCode
      Membership
      Status
      Achievements {
        id
        ContentIMG
        Description
        Title
        createdAt
        BadgeIMG
      }
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceTokens
      DeviceInfo
      contactID
      createdAt
      updatedAt
    }
  }
`;

export const listSplashScreens = /* GraphQL */ `
  query ListSplashScreens(
    $filter: ModelSplashScreensFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSplashScreens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Description
        Label
        Image
        Type
        Video
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAttendances = /* GraphQL */ `
  query ListAttendances(
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Status
        EventStartDate
        EventEndTime
        EventStartTime
        Contact {
          id
          FirstName
          LastName
          Email
          Gender
          Phone
          CreatedDate
          Birthdate
          ProfileImageUrl
          Membership
          Status
          IsInstructor
          InstructorBio
          Survey
          Notifications
          DeviceInfo
          DeviceTokens
          contactID
          createdAt
          updatedAt
        }
        contactAttendancesContactID
        Event {
          id
          Name
          StartDate
          EndDate
          IsCourse
          Recurrence
          Description
          ParentId
          Capacity
          Booked
          LevelFrom
          LevelTo
          AgeFrom
          AgeTo
          Gender
          Instructor
          AssistantInstructors
          InstructorId
          AssistantInstructorsId
          Fees
          Extras
          Duration
          Remaining
          PriceScheme
          Price
          PlannerEventExtra
          RawEventDate
          createdAt
          updatedAt
          contactEventsContactID
          subCategoryEventsId
          programEventsId
          EventOccurrences {
            items {
              StartTime
              EndTime
              id
              Name
            }
          }
          InstructorDetails {
            id
            FirstName
            LastName
            Email
            Gender
            Phone
            CreatedDate
            Birthdate
            ProfileImageUrl
            Status
            IsInstructor
            InstructorBio
            instructor {
              PhotoCard
              PhotoThumb
            }
            contactID
            createdAt
            updatedAt
          }
          AssistantInstructorsInfo {
            id
            FirstName
            LastName
            Email
            Gender
            Phone
            CreatedDate
            Birthdate
            ProfileImageUrl
            Status
            IsInstructor
            instructor {
              PhotoCard
              PhotoThumb
            }
            InstructorBio
            contactID
            createdAt
            updatedAt
          }
          SubCategory {
            PreviewImage
            PreviewVideo
            Name
            Category
            BookingType
            Description
            id
            createdAt
          }
        }
        eventAttendancesId
        ModifiedDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listConversationsStudent = /* GraphQL */ `
  query ListAttendances(
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Event {
          InstructorDetails {
            id
            FirstName
            LastName
            ProfileImageUrl
            contactID
          }
          AssistantInstructorsInfo {
            id
            FirstName
            LastName
            ProfileImageUrl
            contactID
          }
        }
      }
      nextToken
    }
  }
`;

export const listConversationsInstructor = /* GraphQL */ `
  query listEventOccurrences(
    $filter: ModelEventFilterInput
    $filterEvents: ModelEventOccurrenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Name
        id
        ParentId
        EventStartDate
        EventEndDate
        StartDate
        EndDate
        Remaining
        Instructor
        AssistantInstructors
        Duration
        Recurrence
        Description
        PlannerEventExtra
        Price
        Capacity
        Booked
        EventOccurrences(filter: $filterEvents) {
          items {
            id
            ParentEventId
            StartTime
            EndTime
            OccurrenceId
            Status
            Name
            RawEventDate
            EventStartDate
            EventEndDate
            eventSubCategoryId
            createdAt
            updatedAt
            eventEventOccurrencesId
          }
          nextToken
        }
        InstructorDetails {
          id
          FirstName
          LastName
          Email
          Gender
          Phone
          CreatedDate
          Birthdate
          ProfileImageUrl
          Status
          IsInstructor
          InstructorBio
          instructor {
            PhotoCard
            PhotoThumb
          }
          contactID
          createdAt
          updatedAt
        }
        AssistantInstructorsInfo {
          id
          FirstName
          LastName
          Email
          Gender
          Phone
          CreatedDate
          Birthdate
          ProfileImageUrl
          Status
          IsInstructor
          instructor {
            PhotoCard
            PhotoThumb
          }
          InstructorBio
          contactID
          createdAt
          updatedAt
        }
        SubCategory {
          id
          Name
          Category
          Description
          PreviewImage
          PreviewVideo
          BookingType
          createdAt
          updatedAt
        }
        
      }
    }
  }
`;

export const listSubCategories = /* GraphQL */ `
  query ListSubCategories(
    $filter: ModelSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Category
        Description
        PreviewImage
        PreviewVideo
        BookingType
        Events {
          nextToken
        }
        Videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const topPicksFilter = /* GraphQL */ `
  query TopPicks($filter: EventFilterInput) {
    topPicks(filter: $filter) {
      id
      Name
      StartDate
      EndDate
      EventStartDate
      EventEndDate
      IsCourse
      Recurrence
      Description
      ParentId
      Capacity
      Booked
      LevelFrom
      LevelTo
      AgeFrom
      AgeTo
      Gender
      Program {
        id
        Name
        Description
        Ranks
        Progress
        Gender
        MinimumAge
        MaximumAge
        Events {
          nextToken
        }
        createdAt
        updatedAt
      }
      Instructor
      AssistantInstructors
      InstructorId
      AssistantInstructorsId
      InstructorInfo {
        id
        FirstName
        LastName
        Email
        Gender
        Phone
        CreatedDate
        Birthdate
        ProfileImageUrl
        Membership
        Status
        Achievements {
          id
          Title
          Description
          BadgeIMG
          ContentIMG
          createdAt
          updatedAt
        }
        IsInstructor
        InstructorBio
        Survey
        Notifications
        Workouts {
          nextToken
        }
        Attendances {
          nextToken
        }
        Videos {
          nextToken
        }
        Events {
          nextToken
        }
        DeviceInfo
        DeviceTokens
        contactID
        verificationOTP
        instructor {
          ID
          RecordName
          LocationId
          CreatedDate
          CreatedByUserID
          ModifiedDate
          ModifiedByUserID
          RowVersion
          AboutThis
          FirstName
          LastName
          Photo
          FullName
          Position
          JobTitle
          Gender
          Birthdate
          Status
          EmployementDate
          TerminationDate
          MobilePhone
          MobilePhone_NoSpace
          HomePhone
          HomePhone_NoSpace
          WorkPhone
          WorkPhone_NoSpace
          OtherPhone
          OtherPhone_NoSpace
          Address
          Email
          StaffID
          UseServiceRestrictions
          BookingStartsInDays
          BookingStartsInHours
          BookingEndsInDays
          DisplayEndsInDays
          Age
          PhotoThumb
          PhotoCard
        }
        createdAt
        updatedAt
      }
      InstructorDetails {
        id
        FirstName
        LastName
        Email
        Gender
        Phone
        CreatedDate
        Birthdate
        ProfileImageUrl
        Membership
        Status
        Achievements {
          id
          Title
          Description
          BadgeIMG
          ContentIMG
          createdAt
          updatedAt
        }
        IsInstructor
        InstructorBio
        Survey
        Notifications
        Workouts {
          nextToken
        }
        Attendances {
          nextToken
        }
        Videos {
          nextToken
        }
        Events {
          nextToken
        }
        DeviceInfo
        DeviceTokens
        contactID
        verificationOTP
        instructor {
          ID
          RecordName
          LocationId
          CreatedDate
          CreatedByUserID
          ModifiedDate
          ModifiedByUserID
          RowVersion
          AboutThis
          FirstName
          LastName
          Photo
          FullName
          Position
          JobTitle
          Gender
          Birthdate
          Status
          EmployementDate
          TerminationDate
          MobilePhone
          MobilePhone_NoSpace
          HomePhone
          HomePhone_NoSpace
          WorkPhone
          WorkPhone_NoSpace
          OtherPhone
          OtherPhone_NoSpace
          Address
          Email
          StaffID
          UseServiceRestrictions
          BookingStartsInDays
          BookingStartsInHours
          BookingEndsInDays
          DisplayEndsInDays
          Age
          PhotoThumb
          PhotoCard
        }
        createdAt
        updatedAt
      }
      AssistantInstructorsInfo {
        id
        FirstName
        LastName
        Email
        Gender
        Phone
        CreatedDate
        Birthdate
        ProfileImageUrl
        Membership
        Status
        Achievements {
          id
          Title
          Description
          BadgeIMG
          ContentIMG
          createdAt
          updatedAt
        }
        IsInstructor
        InstructorBio
        Survey
        Notifications
        Workouts {
          nextToken
        }
        Attendances {
          nextToken
        }
        Videos {
          nextToken
        }
        Events {
          nextToken
        }
        DeviceInfo
        DeviceTokens
        contactID
        verificationOTP
        instructor {
          ID
          RecordName
          LocationId
          CreatedDate
          CreatedByUserID
          ModifiedDate
          ModifiedByUserID
          RowVersion
          AboutThis
          FirstName
          LastName
          Photo
          FullName
          Position
          JobTitle
          Gender
          Birthdate
          Status
          EmployementDate
          TerminationDate
          MobilePhone
          MobilePhone_NoSpace
          HomePhone
          HomePhone_NoSpace
          WorkPhone
          WorkPhone_NoSpace
          OtherPhone
          OtherPhone_NoSpace
          Address
          Email
          StaffID
          UseServiceRestrictions
          BookingStartsInDays
          BookingStartsInHours
          BookingEndsInDays
          DisplayEndsInDays
          Age
          PhotoThumb
          PhotoCard
        }
        createdAt
        updatedAt
      }
      Fees
      Extras
      Duration
      Remaining
      PriceScheme
      Price
      SubCategory {
        id
        Name
        Category
        Description
        PreviewImage
        PreviewVideo
        BookingType
        Events {
          nextToken
        }
        Videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoryEventsId
      programEventsId
      Attendances {
        items {
          id
          Status
          contactAttendancesContactID
          eventAttendancesId
          EventStartDate
          ModifiedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      EventOccurrences {
        items {
          id
          ParentEventId
          StartTime
          EndTime
          OccurrenceId
          Status
          RawEventDate
          EventStartDate
          EventEndDate
          eventSubCategoryId
          createdAt
          updatedAt
          eventEventOccurrencesId
        }
        nextToken
      }
      PlannerEventExtra
      RawEventDate
      createdAt
      updatedAt
      contactEventsContactID
    }
  }
`;
export const validateCognitoUser = /* GraphQL */ `
  query ValidateCognitoUser($filter: UserValidateInputInput) {
    validateCognitoUser(filter: $filter) {
      status
      message
    }
  }
`;

export const getVideosByContact = /* GraphQL */ 
`query GetVideosByContact($filter: GetVideosInput) {
  getVideosByContact(filter: $filter) {
    id
    Name
    VideoUrl
    ThumbnailUrl
    ContactIds
    SubCategoryID {
      id
      Name
      Category
      Description
      PreviewImage
      PreviewVideo
      BookingType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    contactVideosContactID
    subCategoryVideosId
    __typename
  }
}`
 

export const listFollowers = /* GraphQL */ `
  query listFollowers(
    $filter: ModelFollowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        inviterID
        inviterImage
        inviterName
        inviterType
        followerID
        followerImage
        followerName
        channel
        endpoint
        link
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;