import branch, { BranchError, SessionData, InitOptions, DeepLinkData } from 'branch-sdk'
const branchKey = import.meta.env.VITE_BRANCH_IO_KEY;

export const BranchService = {
   
    init: (
      options?: InitOptions,
      callback?: (err: BranchError | null, data: SessionData | null) => void,
    ): void => {
      branch.init(branchKey, options, (err: BranchError | null, data: SessionData | null) => {
        if (callback) {
          callback(err, data);
        } else {
          if (err) {
            console.error('Branch initialization error:', err);
          } else {
            console.log('Branch initialized:', data);
          }
        }
      });
    },
  
    createLink: async (params: DeepLinkData): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        branch.link(params, (err: BranchError | null, url: string | null) => {
          if (err) {
            reject(err);
          } else {
            resolve(url || '');
          }
        });
      });
    },

    getSessionData: (): Promise<SessionData | null> => {
      return new Promise((resolve, reject) => {
        branch.init(branchKey, {}, (err: BranchError | null, data: SessionData | null) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
    },
  
    // Add other Branch SDK functionalities as needed
  };

  
  
  export default BranchService;
