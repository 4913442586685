import React, { MutableRefObject, useState, useEffect, useRef } from 'react';
import startIcon from '../assets/images/start_share.png'
import stopIcon from '../assets/images/stop_share.png'
import pauseIcon from '../assets/images/pause_share.png'
import { isMobile, isTablet } from 'react-device-detect';

interface SharingVideoGroupProps {
  videoRef: MutableRefObject<HTMLVideoElement | null>;
  togglePlayPause: () => void;
  changeVolume: (event: React.ChangeEvent<HTMLInputElement>) => void;
  videoVolume: number;
  stopContentShare: () => void;
  isDisplayed: boolean;
  isIncognito?: boolean;
}


const SharingVideoGroup: React.FC<SharingVideoGroupProps> = ({
  videoRef,
  togglePlayPause,
  changeVolume,
  videoVolume,
  stopContentShare,
  isDisplayed,
  isIncognito = false,
}) => {
  const [isPlaying, setIsPlaying] = useState(true);

  const isPhone = () => {
    return isMobile && !isTablet
  }

  const handleTogglePlayPause = () => {
    togglePlayPause()
    if (videoRef.current) {
      setIsPlaying(!videoRef.current?.paused);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      setIsPlaying(!videoRef.current?.paused);
    }
  }, [videoRef.current?.paused]);

  return (
    <div
      style={{
        display: isDisplayed ? 'block' : 'none',
      }}
    >
      <video
        crossOrigin="anonymous"
        autoPlay
        loop
        muted
        playsInline
        ref={videoRef}
        style={{
          display: 'none',
        }}
      />
      <div className={`fixed z-100 ${isIncognito ? 'top-[140px]' : 'top-[100px]'} left-1/2 transform -translate-x-1/2`}>
        <div className="flex flex-row items-center justify-between">
          <button
            className="m-[12px]"
            onClick={() => { handleTogglePlayPause() }}
          >
            <img
              className={` ${isPhone() ? `w-[60px]` : `w-[80px]`}`}
              src={(!isPlaying || videoRef.current?.paused || videoRef.current?.ended) ? startIcon : pauseIcon}
              alt="Toggle Share"
            />
          </button>
          <button
            className="m-[12px]"
            onClick={stopContentShare}
          >
            <img
              className={` ${isPhone() ? `w-[60px]` : `w-[80px]`}`}
              src={stopIcon}
              alt="Toggle Share"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SharingVideoGroup;
