import React from 'react';
import { Contact, AttendanceRecord } from '../helpers/interfaces';
import { formatEventStartTime, isEventAccessible } from '../utils/formatters';

interface ClassCardProps {
  data: AttendanceRecord;
  onButtonClick: () => void;
  isInstructor: boolean;
}

const ClassCard: React.FC<ClassCardProps> = ({
  data,
  onButtonClick,
  isInstructor
}) => {

  const imageURL = data.Event.SubCategory.PreviewImage
  const title = data.Event.Name
  const date = formatEventStartTime(data.EventStartTime)
  const isAccesable = isEventAccessible(data.EventStartTime, 15)
  const buttonText = isInstructor ? 'Teach' : 'Join Now'
  
  return (
    // The card takes the full width of the parent container which controls its size
    <div className="flex p-4 bg-white m-[12px] min-h-[150px]" style={{border: '0.1rem solid #DCDEE0'} }>
      {/* Image takes 1/3 of the card */}
      <div className="w-1/3">
        <img className="object-cover w-[100px] h-[100px]" src={imageURL} alt={title} />
      </div>
      {/* Content takes 2/3 of the card */}
      <div className="w-2/3 flex flex-col  pl-4">
        <div>
          <h3 className="text-primary-text mb-2 text-[18px] font-bold">{title}</h3>
          <p className="text-secondary-text mb-2 text-[16px] font-normal">{date}</p>
        </div>
        {isAccesable && (
          <button
            onClick={onButtonClick}
            className="min-w-[138px] h-[46px] bg-primary-button hover:bg-hover-button text-primary-text  text-[20px] font-bold py-2 px-6 rounded-full focus:outline-none focus:shadow-outline"
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default ClassCard;
