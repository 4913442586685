import React, { ReactElement, useState, useEffect, useContext } from 'react'
import NavBarHeader from '../../components/NavBarHeader'
import maleAvatar from '../../assets/images/maleAvatar.png';
import femaleAvatar from '../../assets/images/femaleAvatar.png';
import heartIcon from '../../assets/images/heart-black-icon.png';
import membersIcon from '../../assets/images/members-black-icon.png';
import { RotatingLines as Spinner } from 'react-loader-spinner'
import Api from '../../helpers/customAPIs';
import { Follower} from '../../helpers/interfaces';
import { InvitationStatus} from '../../helpers/types';
import Colors from "../../constants/colors"
import {UserContext} from '../../contexts/userContext'
import PopUp from '../../components/Popup'
import { useNavigate } from 'react-router-dom';
import routes from '../../constants/routes';
import Bio from '../../components/Bio'
import { isMobile, isTablet} from 'react-device-detect';
import signOutIcon from '../../assets/images/sign-out-icon.png';
import { AwsMethods } from "../../helpers/AwsMethods";

function Profile(): ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useContext(UserContext);
  const [showPopup, setShowPopup] = useState(false)
  const [errorMessagePopup, setErrorMessagePopup] = useState("Somthing went wrong")
  const navigate = useNavigate();
  const [followers, setFollowers] = useState<Follower[]>([]);
  const [members, setFMembers] = useState<Follower[]>([]);
  const [programs, setPrograms] = useState<Follower[]>([]);
  const [classes, setClasses] = useState<Follower[]>([]);
  const isPhone= () => {
    return isMobile && !isTablet
  }

  useEffect(() => {
    setIsLoading(true)
  }, []);


  useEffect(() => {
    fetchFollowersList();
  }, []);

  const fetchFollowersList = async () => {
    if (user) {
      setIsLoading(true)
      try {
        console.log('fetchFollowersList user: ', user)
        const followersList = await Api.getFollowersForInviter(user.contactID);
        groupFollowers(followersList)
        setIsLoading(false)
      } catch (error) {
        console.log('fetchFollowersList error: ', error)
        setIsLoading(false)
      }
    }
  };


  const groupFollowers = (followersList: Follower[]) => {
    const followers = followersList.filter(follower => follower.status.toLowerCase() === 'following');
    setFollowers(followers);
  };

 
  const onEditProfileClick = () => {

  }

  const onFollowersClick = () => {
    navigate(routes.FOLLOWERS)
  }

  const onMembersClick = () => {
    navigate(routes.MEMBERS)
  }

  const signOutAction = () => {
    AwsMethods.signOut((res: string, responseType: any) => {
      // handle sign out response
    });
  }

  const getProfileImage = () => {
    let profileImage = user?.ProfileImageUrl
    
    if (!profileImage) {
        profileImage = user?.Gender === "male" ? maleAvatar : femaleAvatar
    }
 
    return profileImage
  }
 
  return (
    <div className="w-full flex flex-col self-center justify-between">
      <div className={`${isPhone() ? 'pt-[80px]' : 'pt-[30px]'} w-full h-screen px-[48px]`}>
        <div className="">
          {/* Name container  */}
          <div className={`${isPhone() ? 'flex-col' : 'flex-row'} flex self-center items-center justify-between pb-[20px] h-[72px]`}>
          <div className={`${isPhone() ? 'text-[25px]' : 'text-[48px]'} self-center`}>
              {`${user?.FirstName} ${user?.LastName} Info`}
            </div>
            {/* <button
                onClick={onEditProfileClick}
                className="min-w-[100px] h-[46px] bg-primary-button hover:bg-hover-button text-primary-text  text-[20px] font-bold py-2 px-6 rounded-full focus:outline-none focus:shadow-outline"
              >
                {"Edit profile"}
              </button> */}
          </div>
          {/* About Container */}
          <div className={`${isPhone() ? 'flex-col' : 'flex-row'} flex justify-start max-h-[300px]`}>
            {/* Photo Container */}
            <div className="flex-shrink-0 w-3/10 self-center">
              <img src={getProfileImage()} alt="avatar" className={`${isPhone() ? 'max-w-[350px]' : 'w-full mr-[16px]'}  max-h-[280px] rounded-[10px]`}/>
            </div>
            {/* Bio and Followers Container */}
            <div className={`${isPhone() ? 'flex-col mt-[30px]' : 'flex-row'} w-7/10 ml-[20px] flex flex-col justify-between h-full`}>
              {/* Bio Container */}
              <Bio bio={user?.InstructorBio ?? ""}/>
              {/* Followers and Members Container */}
              { user?.IsInstructor && <div className="flex flex-row justify-start mt-[20px]" >
                {/* Followers Container */}
                <div onClick={onFollowersClick} className="flex flex-col self-center items-center justify-center w-[110px] h-[95px] mr-[12px] bg-follower-card-background rounded-[10px]">
                  <div className="flex flex-row self-center items-center justify-between">
                      <img src={heartIcon} alt="heart" className="w-[21px] mr-[5px]" />
                      <span className="text-primary-text text-[32px] self-center items-center">
                        {followers.length}
                      </span>
                  </div>
                  <span className="text-primary-text text-[16px] self-center mt-[10px]">
                    {`Followers`}
                  </span>
                </div>
                {/* Members Container */}
                {/* <div onClick={onMembersClick} className="flex flex-col self-center items-center justify-center w-[110px] h-[95px] mr-[12px] bg-members-card-background rounded-[10px]">
                  <div className="flex flex-row self-center items-center justify-between">
                      <img src={membersIcon} alt="heart" className="w-[21px] mr-[5px]" />
                      <span className="text-primary-text text-[32px] self-center items-center">
                        {members.length}
                      </span>
                  </div>
                  <span className="text-primary-text text-[16px] self-center mt-[10px]">
                    {`Members`}
                  </span>
                </div> */}
              </div>
              }
            </div>
          </div>

      </div>
      {(isPhone()) && (
           <div className="fixed bottom-0 left-0 flex flex-col w-full items-center justify-center">
              {/* Devider */}
              <div className="bg-followers-devider h-[1px] my-[0px] w-full" />
              <div className=""> 
                <button
                  onClick={() => signOutAction()}
                  className={`flex h-[52px] w-[281px] items-center space-x-2 w-full rounded-full px-4 py-2`}
                >
                  <img src={signOutIcon} alt="Start" className="h-[25px] w-[25px] mr-[12px]" />  
                  <span className="text-warning-text font-bold text-[18px]">Sign-out</span>
                </button>
              </div>
              </div>
            )}
    </div>
    {(isLoading) && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <Spinner 
            visible={true}
            width='40px'
            strokeColor= {Colors.primaryButton}
          />
        </div>
    )}
      {showPopup && (
        <PopUp 
          type={'error'} 
          isOpen={showPopup} 
          onClose={() => {setShowPopup(false)}}
          titleString='Cannot Join Class'
          messageString={errorMessagePopup}
        />
      )}
    </div>
  )
}

export default Profile


