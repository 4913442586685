import React, { useEffect, useState, KeyboardEvent } from 'react';
import logo from '../assets/images/loginLogo.png'
import errorIcon from "../assets/images/error_icon.png"
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { RotatingLines as Spinner } from 'react-loader-spinner'
import Colors from "../constants/colors"

interface LoginFormProps {
  setCredentials: React.Dispatch<React.SetStateAction<{
    phone: string;
    email: string;
    inputTypeNumber: boolean;
    countryCode: string;
    otp: string;
  }>>;
  onFormSubmit: () => void;
  error: {
    isError: boolean;
    message: string;
  };
  setError: React.Dispatch<React.SetStateAction<{
    isError: boolean;
    message: string;
  }>>;
  isLoading: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ error, setError, isLoading,  setCredentials, onFormSubmit }) => {
  const [isEmail, setIsEmail] = useState(true);
  const [email, setEmail] = useState('');
  const [countryCode, setCountryCode] = useState('ca');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOTP] = useState('');
  const [isValid, setValid] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();  
    onFormSubmit();  
    console.log("handleSubmit")
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();  
      handleSubmit(e); 
    }
  };

  const handlePhoneChange = (value: string, data: CountryData) => {
    setPhoneNumber(value);
    setValid(validatePhoneNumber(value));
    setError(prevError => ({ ...prevError, isError: false, message: ''}));
    setCredentials(prevState => ({...prevState, phone: value, countryCode: data.countryCode, inputTypeNumber: true, email: ''}));
  };

  const handleInputTypeChange = () =>{
    setIsEmail(!isEmail)
    setPhoneNumber("")
    setEmail("")
    setError(prevError => ({ ...prevError, isError: false, message: ''}));
  }
 
  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailPattern.test(email);
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
 
    return phoneNumberPattern.test(phoneNumber);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const email = e.target.value
      setEmail(email);
      setValid(validateEmail(email));
      setCredentials(prevState => ({...prevState, email: email, inputTypeNumber: false,  phone: ''}));
      setError(prevError => ({ ...prevError, isError: false, message: ''}));
  };
  
  return (
      <div className="w-full flex flex-col items-center mx-[60px] my-[32px]">
        <img src={logo} alt="logo" className="w-[185px] mb-[52px]" />
        <form onSubmit={handleSubmit} className="w-full">
          <div className="mb-4 flex flex-col">
            <label className="block text-field-label-text text-sm font" htmlFor="email">
              {isEmail ? 'Email' : 'Phone'}
            </label>
            {/* Show text field depends on selected option */}
            {isEmail ? (
              <input
                className={`border-black-border w-full mx-auto my-[8px] px-[24px] h-[40px] appearance-none  ${error.isError ? 'border-error-border' : 'border-black-border'} rounded-full text-black-text text-[16px] leading-tight `}
                //style={{outline: `1px solid ${Colors.primaryButton}`}}
                id="email"
                type="email"
                placeholder="Your email"
                value={email}
                onChange={ handleEmailChange}
                autoFocus
                />
              ) : (
                <PhoneInput
                  country={countryCode}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  inputProps={{
                    required: true,
                    autoFocus: true,
                    type: "tel"
                  }}
                  onKeyDown={handleKeyDown}
                  //enableSearch={true}
                  inputStyle={{fontSize: "16px", width: "100%", borderRadius: '20px', paddingLeft: "80px", height: '40px'}}
                  containerStyle={{width: "100%", borderRadius: '20px', height: '40px', marginTop: '8px', marginBottom: '8px', borderColor: error.isError ? "error-border" : "black-border"}}
                  buttonStyle={{ border: "none", borderRight: "1px solid black" , backgroundColor: "transparent", background: "none", paddingRight: '10px', height: "90%", margin: "5px", marginLeft: "20px", alignSelf: 'center'}}
                />
              )
            }
            { isLoading ? (
              <div className="flex items-center justify-center">
                <Spinner 
                  visible={isLoading}
                  width='30px'
                  strokeColor= {Colors.primaryButton}
                />
              </div>
            ) : (
              <div> 
              {error.isError && 
                <div className="flex flex-row items-center justify-center"> 
                    <img src={errorIcon} alt="error" className="w-[16px] mr-[4px] my-[8px]" />
                    <p className="text-red-text text-[14px] ">{error.message}</p>
                </div>
                }
              </div>
            )}
              <div className="flex items-center justify-center">
          
              <div
                className="inline-block align-baseline font-bold text-[14px] text-blue-text hover:blue-text"
                onClick={() => handleInputTypeChange()}
              >
                {isEmail ? 'Or use mobile number instead' : 'Or use email address instead'}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between">
            <button
              className={`w-full rounded-full ${isValid ? 'bg-primary-button' : 'bg-inactive-button'}  text-primary-text font-bold text-[20px] py-[8px] px-[24px] mt-[52px] h-[46px] rounded focus:outline-none focus:shadow-outline`}
              type="submit"
              disabled={!isValid}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    
  );
};

export default LoginForm;



