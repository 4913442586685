
import { createRoot } from 'react-dom/client';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import { Amplify} from "aws-amplify";
import config from './amplifyconfiguration.json';
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from '@sentry/react';

Amplify.configure(config);

const queryClient = new QueryClient()

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

window.addEventListener('load', () => {
  const container = document.getElementById('root') as HTMLElement;
  const root = createRoot(container);
  root.render(
    <ErrorBoundary> 
      <React.StrictMode>
          <QueryClientProvider client={queryClient}> 
              <App />
          </QueryClientProvider>
      </React.StrictMode>
    </ErrorBoundary>
  );
});
