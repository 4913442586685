import React, { useState} from 'react'
import styled from 'styled-components';
import Sheet from 'react-modal-sheet';
import { isMobile, isTablet} from 'react-device-detect';
import { LeaveMeetingButton } from '../helpers/types';

interface BottomSheetProps {
  currentTheme: 'light' | 'dark';
  showBottomSheet: boolean;
  closeBottomSheet: () => void;
  handleLeaveMeeting: (buttonType: LeaveMeetingButton) => void;
  isIncognito?: boolean; 
  isInstructor: boolean; 
  setLoading?: (isLoading: boolean) => void;
  isLoading?: boolean;
  isPortrait?: boolean;
}

const CustomSheet = styled(Sheet)<{ theme: 'light' | 'dark' }>`
  .react-modal-sheet-header {
    background-color: ${({ theme }) => theme === 'light' ? 'white' : '#333'};
  }
  .react-modal-sheet-backdrop {
    background-color: rgba(66, 66, 66, 0.7);  
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
  }
`;

const BottomSheet: React.FC<BottomSheetProps> = ({
  currentTheme,
  showBottomSheet,
  closeBottomSheet,
  handleLeaveMeeting,
  isInstructor,
  isIncognito = false, 
  isLoading = false,
  setLoading,
  isPortrait = true,
}) => {

 
  const isPhone= () => {
    return (isMobile && !isTablet) || isIncognito
  }

  const getDisabledButtonBackground= (buttonType: LeaveMeetingButton) => {
    let background = buttonType === 'leave' ? 'bg-error-button' : 'bg-gray-button'
    
    if (isLoading) {
      background += " bg-opacity-50"
    }  
    return background
  }
 
  const getSnapPoint= () => {
    let shapPoint = isInstructor ? 0.3 : 0.3
    if (!isPortrait)  {
      shapPoint += 0.4
    }  
    return [shapPoint]
  }

  return (
    <CustomSheet
      theme={currentTheme}
      isOpen={showBottomSheet}
      onClose={closeBottomSheet}
      snapPoints={getSnapPoint()}
    >
      <Sheet.Container >
        <Sheet.Header />
        <Sheet.Content className="bg-dark-mode-background">
          <div className="flex flex-col px-[20px] justify-center items-center "> 
          <button
              onClick={() => {
                 
                handleLeaveMeeting("leave");
                if (setLoading) {
                  setLoading(true)
                }
              }}
              disabled={isLoading }
              className={`my-[15px] w-full max-w-[400px] px-4 py-2 h-[50px] font-bold rounded-full text-white transition duration-150 ${getDisabledButtonBackground('leave')}`}
            >
              {"Leave the class"}
            </button>
              {isInstructor && 
              <button
                onClick={() => {
                    handleLeaveMeeting("end");
                    if (setLoading) {
                      setLoading(true)
                    }
                }}
                disabled={isLoading}
                className={`px-4 py-2 w-full max-w-[400px] h-[50px] font-bold rounded-full text-white transition duration-150 ${getDisabledButtonBackground('end')}`}
              >
                {"End meeting for all"}
              </button>}
             </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={closeBottomSheet} />
    </CustomSheet>
  );
};

export default BottomSheet;
