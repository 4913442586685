import React, { useState, useRef, useEffect } from 'react';
import { overflow } from 'styled-system';

interface BioProps {
  bio: string;
}

const Bio: React.FC<BioProps> = ({ bio }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const bioRef = useRef<HTMLSpanElement>(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (bioRef.current && bioRef.current.offsetHeight > 100) {
      setIsOverflowing(true);
    }
  }, [bio]);

  return (
    <div className="relative">
      <div className={`overflow-hidden ${isExpanded ? '' : 'max-h-[100px]'}`}>
        <span
          ref={bioRef}
          className="min-w-[150px] text-primary-text text-[16px] font-bold pr-[10px]"
        >
          {bio}
        </span>
      </div>
      {isOverflowing && (
        <div className="text-blue-500 cursor-pointer mt-2" onClick={toggleExpand}>
          {isExpanded ? 'show less' : 'show more...'}
        </div>
      )}
    </div>
  );
};

export default Bio;
