
import moment from "moment-timezone";

export function convertToIST(isoString: string, usersCountryCode: string) {
    const timezone = moment.tz.guess();
    const format = 'YYYY-MM-DDTHH:mm:ss';
    const formattedDate = moment(isoString).tz(timezone).format(format);
    return formattedDate;
}

// Returns a string formatted as 'YYYY-MM-DD' in the local timezone
export function convertDateInLocalTimeZone(isoString: string) {
    const timezone = moment.tz.guess();
    const date = isoString + 'Z';
    const formattedDate = moment(date).tz(timezone).format('YYYY-MM-DD')
    return formattedDate
}

// Returns a string formatted as 'YYYY-MM-DD HH:mm:ss' in the local timezone
export const convertDateTimeFormatInLocalTimeZone = (isoString: string) => {
  const timezone = moment.tz.guess(); // Guessing the user's timezone
  const date = isoString + 'Z'; // Assuming isoString is in UTC, append 'Z' to make it explicit
  const formattedDate = moment(date).tz(timezone) 
  return formattedDate; 
}

// Returns a string formatted as 'YYYY-MM-DD HH:mm:ss' in the local timezone
export const convertDateTimeFormatInLocalTimeZoneString = (isoString: string): string => {
  const timezone = moment.tz.guess(); // Guessing the user's timezone
  const date = isoString + 'Z'; // Assuming isoString is in UTC, append 'Z' to make it explicit
  const formattedDate = moment(date).tz(timezone).format('YYYY-MM-DD HH:mm:ss'); // Format the date in the local timezone
  return formattedDate;
}


//Returns time in format 'Month Day, Year at HH:MM AM/PM'
export const formatEventStartTime = (isoString: string): string => {
  const localDateString = convertDateTimeFormatInLocalTimeZoneString(isoString);
  const date = new Date(localDateString.replace(' ', 'T')); // Replace space with 'T' to make it ISO 8601 compliant

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date");
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};
  
  export const isEventAccessible = (eventStartTimeISO: string, minutesBeforeEvent: number = 15): boolean => {
    const localDateString = convertDateTimeFormatInLocalTimeZoneString(eventStartTimeISO)
    // Convert event start time from ISO 8601 string to a Date object
    const eventStartTime = new Date(localDateString.replace(' ', 'T'));
    // Get the current date and time
    const now = new Date();

    if (isNaN(eventStartTime.getTime())) {
      throw new Error("Invalid date");
    }
    // Calculate the time [minutesBeforeEvent] minutes before the event starts
    const timeBeforeEvent = new Date(eventStartTime.getTime() - minutesBeforeEvent * 60 * 1000);
    // The event is considered accessible if the current time is:
    // 1. Greater than or equal to [minutesBeforeEvent] minutes before the event starts
    // 2. Any time after the event has started (since we return true if now is past eventStartTime)
    return now >= timeBeforeEvent;
  }

  // Returns time in format 00:00 PM
  export const formatTime = (isoDateString: string) => {
    const localDate = convertDateTimeFormatInLocalTimeZone(isoDateString).format('YYYY-MM-DD HH:mm:ss')
    const date = new Date(localDate.replace(' ', 'T'));

    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
  
    return `${hours}:${minutesStr} ${ampm}`;
  }
  