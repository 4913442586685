 
export const rootPath: string = '/';

const routes = {
  LOGIN: `${rootPath}`,
  START: `${rootPath}start`,
  FOLLOWERS: `${rootPath}followers`,
  MEMBERS: `${rootPath}members`,
  PROFILE: `${rootPath}profile`,
  DEVICE: `${rootPath}devices`,
  MEETING: `${rootPath}meeting`,
  JOINMEETING: `${rootPath}joinmeeting`,
  DEVICEINC: `${rootPath}devicesinc`,
  MEETINGINC: `${rootPath}meetinginc`,
  MEETINGINCENDED: `${rootPath}meetingincended`,
};

export default routes;
