import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext';
import routes from '../../constants/routes';

interface User {
}

const PrivateRoute: React.FC = () => {
  const { user } = useContext(UserContext) as { user: User | null };  
  return user ? (
    <Outlet />
  ) : (
    <Navigate to={routes.LOGIN} replace />
  );
};

export default PrivateRoute;
