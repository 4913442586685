import React from 'react';
import Messages from './Messages';
import ChatInput from './ChatInput';
import { StyledChat, StyledTitle } from './Styled';
import { IconButton, Remove } from 'amazon-chime-sdk-component-library-react';
import { useNavigation } from '../../providers/NavigationProvider';
import closeIcon from '../../../../assets/images/end_meeting.png'
export default function Chat() {
  const { toggleChat } = useNavigation();

  return (
    <StyledChat className="chat">
      <StyledTitle>
        <div className="ch-title">Chat</div>
        <div className="close-button">
          <button onClick={toggleChat} >
            <div className='w-[30px] h-[30px] rounded-full bg-primary-button flex justify-center items-center'> 
              <img src={closeIcon} className="w-[20px] h-auto"/>
            </div>
          </button>
        </div>
      </StyledTitle>
      <Messages />
      <ChatInput />
    </StyledChat>
  );
}
