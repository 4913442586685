import React, { useState,useEffect, useContext } from 'react';
import LoginForm from '../../components/LoginForm'
import CodeForm from '../../components/CodeForm'
import {AppConstant} from '../../constants';
import { AwsMethods } from "../../helpers/AwsMethods";
import placeholderImage from "../../assets/images/login_placeholder.png"
import BranchService from '../../utils/branchioService';
import Api from '../../helpers/customAPIs';
import routes from '../../constants/routes';
import { useNavigate} from 'react-router-dom';
import { UserContext } from '../../contexts/userContext';

interface ErrorState {
  isError: boolean;
  message: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { user, setIsAuthenticated } = useContext(UserContext);
  const [videoLoaded, setVideoLoaded] = useState(false); 
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [error, setError] = useState<ErrorState>({ isError: false, message: "" });
  const [isLoading, setIsLoading] = useState(false)
  const [credentials, setCredentials] = useState({
    phone: "",
    email: "",
    inputTypeNumber: false,
    countryCode: '',
    otp: '',
  });

  const handleFormSubmit = () => {
    console.log('handleFormSubmit');
    console.log('credentials',credentials);
    signIn()
  };

  const handleCodeSubmit = () => {
    console.log('handleCodeSubmit');
    console.log('credentials',credentials);
    verifyCode()
    setError({isError: false, message:""}) 
  };

  const handleCodeCancel = () => {
    console.log('handleCodeCancel');
    console.log('credentials',credentials);
    setIsCodeSent(false)
    setError({isError: false, message:""}) 
  };

  const handleResendCode = () => {
    console.log('handleResendCode');
    console.log('credentials',credentials);
    signIn()
    setError({isError: false, message:""}) 
  };

const signIn = async () => {
  setIsLoading(true)
  AwsMethods.signIn(credentials, (res: string, responseType: any) => {
    console.log('res',res);
    console.log('responseType',responseType);
    if (responseType == AppConstant.success.toLocaleLowerCase()) {
      console.log('setIsCodeSent',setIsCodeSent);
      setIsCodeSent(true)
    } else if (res == "There is already a signed in user.") {
      console.log('Logged in:',res);
      setError({isError: false, message:""}) 
      setIsAuthenticated(true)
      navigate(`${routes.PROFILE}`);
      console.log('Navigatw PROFILE:');
    } else if (res == "UserLambdaValidationException") {
      console.log('UserLambdaValidationException',res);
      setError(prevError => ({ ...prevError, isError: true, message: res}));
    } else {
      setIsLoading(false)
      if (res.includes(AppConstant.UserDoesNotExistException)) {
        console.log('UserNotFoundException',res);
      } else if (res === AppConstant.UsernameExistsException) {
        console.log('UsernameExistsException',res);
      } else if (res === AppConstant.NotAuthorizedException) {
        console.log('NotAuthorizedException',res);
      } else {
        console.log('Unknown error',res);
        let message = "Somthing went wrong. Please try again later.";
        setError(prevError => ({ ...prevError, isError: true, message: message}));
        return
      }
      
      setError(prevError => ({ ...prevError, isError: true, message: res}));
    }
    setIsLoading(false)
  });
};

const verifyCode = async () => {
  setIsLoading(true)
  AwsMethods.verifyOTP(credentials, (res: any, responseType: any) => {
    console.log('verifyCode res',res);
    if (responseType == AppConstant.success.toLocaleLowerCase()) {
      setTimeout(async () => {
        setIsLoading(false)
      }, 1000);
      handleBranchIOData();
    } else {
      setIsLoading(false)
      setError(prevError => ({ ...prevError, isError: true, message: res}));
    }
  });
}

const handleBranchIOData = async () => {
  try {
    const sessionData: { data_parsed?: { inviterID?: string, endpoint?: string, followerName?: string} } | null = await BranchService.getSessionData();
    console.log('Branch session data:', sessionData);
    let user = await Api.getUserData();

    if (sessionData && sessionData.data_parsed) {
      const { inviterID, endpoint, followerName } = sessionData.data_parsed;
      if (user && inviterID && endpoint) {
        // Updates followers table
        const result =  await Api.updateFollowerData(inviterID, user.contactID, user.FirstName + " " + user.LastName, user.ProfileImageUrl ?? "", 'following', endpoint);
        console.log('result:', result);
      }
    }
  } catch (error) {
    console.error('Error retrieving Branch session data:', error);
  }
};
 
  return (
    <>
      <div className="flex flex-col lg:flex-row  w-full h-full lg:h-screen"> 
        <div className="flex justify-center items-center mx-[auto]" style={{ maxWidth: '600px', width: '100%' }}>
          {isCodeSent ? (
            <CodeForm error={error} setError={setError} isLoading={isLoading} credentials={credentials} onCodeSubmit={handleCodeSubmit} setCredentials={setCredentials} onResendCode={handleResendCode} onCodeCancel={handleCodeCancel}/>
          ) : (
            <LoginForm error={error} setError={setError} isLoading={isLoading} onFormSubmit={handleFormSubmit} setCredentials={setCredentials}/>
          )}
        </div>
        <div className="flex-grow">
      <div className="relative flex items-center justify-center h-screen overflow-hidden"> 
        <div className="absolute inset-0 overflow-hidden">
          {/* Placeholder Image */}
          {!videoLoaded && (
            <img 
              src={placeholderImage} 
              alt="Loading..."
              className="h-full w-full object-cover"
            />
          )}

          {/* Video */}
          <video 
            src={'https://d1rr2szh7z14o1.cloudfront.net/promotalscreen/background-video.mp4'} 
            autoPlay 
            loop 
            muted 
            playsInline
            className={`h-full w-full object-cover ${videoLoaded ? 'block' : 'hidden'}`} // Hide video until loaded
            onLoadedData={() => setVideoLoaded(true)} // Show video and hide placeholder when video is ready
          />
        </div>

        <div className="absolute inset-0 flex flex-col lg:justify-center justify-start items-center text-center mt-[30px]">
          <h1 className="text-white text-4xl font-bold mb-[8px] mx-[60px]">Elevate Your Potential with Guided Classes!</h1>
          <h2 className="text-white text-2xl mx-[60px]">Unlock new skills with tailored sessions for an exceptional experience.</h2>
        </div>
      </div>
    </div>
      
      </div>
    </>
  );

};

export default Login;

