const colors = {
    primaryText: "#0D1A26",
    secondaryText: "#304050",
    blueText: "#158DA8",
    blackText: "#000",
    redText: "#F00",
    fieldLabelText:"#304050",
    messageBadge:"#17C7FF",
    warningText: "#BF4040",
    greenText: "#3D7848",
    grayText: "#5C6670",

    primaryButton: "#49BFDA",
    hoverButton: "#49BFDA",
    inactiveButton: "#EFF0F0",
    whiteButton: "#FFFFFF",
    meetingButton: "#3E7297",
    errorButton: "#FF2D55",
    grayButton: "#5C6670",
    meetingButtonActive: "#FFFFFF",
    meetingButtonInactive: "#606062",
    warningButton: "#BF4040",

    primaryBackground: "#FFFFFF",
    primaryMeetingBackground: "#2E2F34",
    secondaryBackground: "#FAFAFA",
    darkModeBackground: "#1B1C20",
    darkModeMeetingBackground: "#2E2F34",
    grayMeetingBackground: "#A5A5A5",
    grayControlsBackground: "#424242",
    grayControlsGroupBackground: "#606062",
    followerBackground: "#EFF0F0",
    followersCardBackground: "#D7D8FF",
    membersCardBackground: "#BDFFD7",

    primaryBorder: "#DCDEE0",
    secondaryBorder: "#DCDEE0",
    errorBorder: "#950404",
    warningBorder: "#BF4040",
    blackBorder: "#000",
    grayBorder: "#5C6670",

    followersDevider:"#DCDEE0"
};

 
export default colors;