import React from 'react';
import styled from 'styled-components';
import Sheet from 'react-modal-sheet';
import {VideoShared, VideoItemProps} from '../helpers/interfaces'

interface BottomSheetProps {
  currentTheme: 'light' | 'dark';
  showBottomSheet: boolean;
  closeBottomSheet: () => void;
  handleShareVideoClick: (item: VideoShared) => void;
  snapPoints: number[];
  videoList: VideoShared[];
  isIncognito?: boolean; 
}

const CustomSheet = styled(Sheet)<{ theme: 'light' | 'dark' }>`
  .react-modal-sheet-header {
    background-color: ${({ theme }) => theme === 'light' ? 'white' : '#333'};
  }
  .react-modal-sheet-backdrop {
    background-color: rgba(66, 66, 66, 0.7);  
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
  }
`;

const VideoItem: React.FC<VideoItemProps> = ({ item, onClick }) => (
    <div className="w-full flex flex-col items-center" onClick={onClick}>
      <img src={item.thumbnailUrl} className=""></img>
      <p className="mt-2">{item.videoName}</p>
    </div>
  );


const BottomSheet: React.FC<BottomSheetProps> = ({
  currentTheme,
  showBottomSheet,
  closeBottomSheet,
  handleShareVideoClick,
  snapPoints,
  videoList, // Now videoList is part of props
  isIncognito = false, 
}) => {
 
  return (
    <CustomSheet
      theme={currentTheme}
      isOpen={showBottomSheet}
      onClose={closeBottomSheet}
      snapPoints={snapPoints}
    >
      <Sheet.Container >
        <Sheet.Header />
        <Sheet.Content className="bg-dark-mode-background">
          <Sheet.Scroller draggableAt="both" style={{   overflowX: 'scroll', overflowY: 'scroll'  }}> 
            <div className={`pt-[0px] text-center ${currentTheme === "light" ? "bg-white text-black" : "bg-dark-mode-background text-white"}`}>
              <div className="flex w-full items-center justify-center">
                <div className="p-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {videoList.map((item, index) => (
                    <VideoItem key={index} item={item} onClick={() => handleShareVideoClick(item)} />
                  ))}
                </div>
              </div>
            </div>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={closeBottomSheet} />
    </CustomSheet>
  );
};

export default BottomSheet;
