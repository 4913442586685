import React from 'react';
import MeetingRoster from '../MeetingRoster';
import Navigation from '.';
import { useNavigation } from '../../providers/NavigationProvider';
import Chat from '../Chat';
import { Flex } from 'amazon-chime-sdk-component-library-react';

interface NavigationProps {
  handleShareVideo: () => void;
}

const NavigationControl: React.FC<NavigationProps> = ({ handleShareVideo }) =>  {
  const { showNavbar, showRoster, showChat } = useNavigation();

  const view = () => {
    if (showRoster && showChat) {
      return (
        <Flex layout="stack" style={{ height: '100vh' }}>
          <MeetingRoster />
          <Chat />
        </Flex>
      );
    }
    if (showRoster) {
      return <MeetingRoster/>;
    }
    if (showChat) {
      return <Chat />;
    }
    return null;
  };

  return (
    <>
      {/* {showNavbar ? <Navigation handleShareVideo={handleShareVideo}  /> : null} */}
      {view()}
    </>
  );
};

export default NavigationControl;
