 
import React, { useContext, useState, ReactNode, useEffect } from 'react';
import { VideoPriorityBasedPolicy } from 'amazon-chime-sdk-js';
import { MeetingMode, Layout, VideoFiltersCpuUtilization } from '../types';
import { JoinMeetingInfo } from '../../../api/meetingAPI';
import { MeetingManager, useLogger} from 'amazon-chime-sdk-component-library-react';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { AttendanceRecord } from '../../../helpers/interfaces';
import { DefaultBrowserBehavior, MeetingSessionConfiguration, VideoCodecCapability, DefaultMeetingSession, DefaultDeviceController, ConsoleLogger, LogLevel, } from 'amazon-chime-sdk-js'
import { endMeeting } from '../../../api/meetingAPI';
import { ControlButton } from '../../../helpers/types';

type Props = {
  children: ReactNode;
};

interface MeetingStateValue {
  meetingId: string;
  localUserName: string;
  theme: string;
  region: string;
  isWebAudioEnabled: boolean;
  videoTransformCpuUtilization: string;
  imageBlob: Blob | undefined;
  isEchoReductionEnabled: boolean;
  meetingMode: MeetingMode;
  enableSimulcast: boolean;
  priorityBasedPolicy: VideoPriorityBasedPolicy | undefined;
  keepLastFrameWhenPaused: boolean;
  layout: Layout;
  joinInfo: JoinMeetingInfo | undefined;
  meetingData: AttendanceRecord | undefined;
  meetingStatus: string;
  meetingSession: DefaultMeetingSession | undefined,
  videoEnabled: boolean;
  microphoneEnabled: boolean;
  speakerEnabled: boolean;
  videoDevices: MediaDeviceInfo[];
  audioInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
  selectedVideoDeviceId: string;
  selectedAudioInputDeviceId: string;
  selectedAudioOutputDeviceId: string;
  observerDeviceList: any;
  closeWebView: () => void;
  setVideoDevices: (deviceIds: MediaDeviceInfo[]) => void;
  setAudioInputDevices: (deviceIds: MediaDeviceInfo[]) => void;
  setAudioOutputDevices: (deviceIds: MediaDeviceInfo[]) => void;
  setSelectedVideoDeviceId: (deviceId: string) => void;
  setSelectedAudioInputDeviceId: (deviceId: string) => void;
  setSelectedAudioOutputDeviceId: (deviceId: string) => void;
  setMeetingSession: (meetingSession: DefaultMeetingSession | undefined) => void;
  setMeetingData: (meetingData: AttendanceRecord | undefined) => void;
  toggleTheme: () => void;
  setTheme: React.Dispatch<React.SetStateAction<string>>;
  toggleWebAudio: () => void;
  toggleVideoEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  toggleMicrophoneEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  toggleMicrophone: (meetingManager: MeetingManager | undefined) => void;
  toggleSpeakerEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  toggleSimulcast: () => void;
  togglePriorityBasedPolicy: () => void;
  toggleKeepLastFrameWhenPaused: () => void;
  setCpuUtilization: (videoTransformCpuUtilization: string) => void;
  toggleEchoReduction: () => void;
  setMeetingMode: React.Dispatch<React.SetStateAction<MeetingMode>>;
  setJoinInfo: (joinInfo: JoinMeetingInfo | undefined) => void;
  setLayout: React.Dispatch<React.SetStateAction<Layout>>;
  setMeetingId: React.Dispatch<React.SetStateAction<string>>;
  setLocalUserName: React.Dispatch<React.SetStateAction<string>>;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  setBlob: (imageBlob: Blob) => void;
  skipDeviceSelection: boolean;
  toggleMeetingJoinDeviceSelection: () => void;
  setMeetingStatus: React.Dispatch<React.SetStateAction<string>>;
  changeVideoInput: (deviceId: string, meetingManager: MeetingManager | undefined) => Promise<void>;
  changeAudioInput: (deviceId: string, meetingManager: MeetingManager | undefined) => Promise<void>;
  changeAudioOutput: (deviceId: string, meetingManager: MeetingManager | undefined) => Promise<void>;
  switchCamera: (meetingManager: MeetingManager | undefined) => Promise<void>;
  leaveMeeting: (meetingManager: MeetingManager | undefined) => Promise<void>;
  endMeetingForAll: (meetingManager: MeetingManager | undefined) => Promise<void>;
  fetchAllDevices: (meetingManager: MeetingManager | undefined) => Promise<void>;
  fetchDevicesForType: (buttonType: ControlButton, meetingManager: MeetingManager | undefined) => Promise<void>;
}

const MeetingStateContext = React.createContext<MeetingStateValue | null>(null);

export function useMeetingState(): MeetingStateValue {
  const state = useContext(MeetingStateContext);

  if (!state) {
    throw new Error('useMeetingState must be used within MeetingStateProvider');
  }

  return state;
}

const query = new URLSearchParams(location.search);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function MeetingStateProvider({ children }: Props) {
  const logger = useLogger();
  const [meetingId, setMeetingId] = useState(query.get('meetingId') || '');
  const [region, setRegion] = useState(query.get('region') || 'us-east-2');
  const [meetingMode, setMeetingMode] = useState(MeetingMode.Attendee);
  const [meetingStatus, setMeetingStatus] = useState("disconnected");
  const [joinInfo, setJoinInfo] = useState<JoinMeetingInfo | undefined>(undefined);
  const [meetingSession, setMeetingSession] = useState<DefaultMeetingSession | undefined>(undefined);
  const [layout, setLayout] = useState(Layout.Gallery);
  const [localUserName, setLocalUserName] = useState('');
  const [isWebAudioEnabled, setIsWebAudioEnabled] = useState(true);
  const [priorityBasedPolicy, setPriorityBasedPolicy] = useState<VideoPriorityBasedPolicy | undefined>(undefined);
  const [enableSimulcast, setEnableSimulcast] = useState(false);
  const [keepLastFrameWhenPaused, setKeepLastFrameWhenPaused] = useState(false);
  const [isEchoReductionEnabled, setIsEchoReductionEnabled] = useState(false);
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem('theme');
    return storedTheme || 'dark';
  });
  const [videoTransformCpuUtilization, setCpuPercentage] = useState(VideoFiltersCpuUtilization.CPU40Percent);
  const [imageBlob, setImageBlob] = useState<Blob | undefined>(undefined);
  const [skipDeviceSelection, setSkipDeviceSelection] = useState(false);
  const [meetingData, setMeetingData] = useState<AttendanceRecord | undefined>(undefined);
  const [videoEnabled, toggleVideoEnabled] = useState(true);
  const [microphoneEnabled, toggleMicrophoneEnabled] = useState(false);
  const [speakerEnabled, toggleSpeakerEnabled] = useState(true);
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState('');
  const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedAudioInputDeviceId, setSelectedAudioInputDeviceId] = useState('');
  const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedAudioOutputDeviceId, setSelectedAudioOutputDeviceId] = useState('');

  useEffect(() => {
    /* Load a canvas that will be used as the replacement image for Background Replacement */
    async function loadImage() {
      const canvas = document.createElement('canvas');
      canvas.width = 500;
      canvas.height = 500;
      const ctx = canvas.getContext('2d');
      if (ctx !== null) {
        const grd = ctx.createLinearGradient(0, 0, 250, 0);
        grd.addColorStop(0, '#000428');
        grd.addColorStop(1, '#004e92');
        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, 500, 500);
        canvas.toBlob(function(blob) {
          if (blob !== null) {
            setImageBlob(blob);
          }
        });
      }
    }
    loadImage();
  }, []);
  
  const toggleTheme = (): void => {
    if (theme === 'light') {
      setTheme('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      setTheme('light');
      localStorage.setItem('theme', 'light');
    }
  };

  const observerDeviceList = {
 
    audioInputsChanged: (freshAudioInputDeviceList?: MediaDeviceInfo[]) => {
      if (!freshAudioInputDeviceList) return;
   
      const audioInputDeviceFound = freshAudioInputDeviceList.some(device => device.deviceId === selectedAudioInputDeviceId);
      if (!audioInputDeviceFound && freshAudioInputDeviceList.length > 0) {
        const meetingManager = useMeetingManager();
        // Set the audio input device to the first available device ID
        const firstAvailableAudioInputDeviceId = freshAudioInputDeviceList[0].deviceId;
        setAudioInputDevices(freshAudioInputDeviceList);
        setSelectedAudioInputDeviceId(firstAvailableAudioInputDeviceId)
        changeAudioInput(firstAvailableAudioInputDeviceId, meetingManager)
      }
  
      freshAudioInputDeviceList.forEach(mediaDeviceInfo => {
        console.log(`DEVICE SETUP Audio Input Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`);
      });
    },
  
    audioOutputsChanged: (freshAudioOutputDeviceList?: MediaDeviceInfo[]) => {
      if (!freshAudioOutputDeviceList) return;
  
      
      const audioOutputDeviceFound = freshAudioOutputDeviceList.some(device => device.deviceId === selectedAudioOutputDeviceId);
  
      if (!audioOutputDeviceFound && freshAudioOutputDeviceList.length > 0) {
        const meetingManager = useMeetingManager();
        // Set the audio output device to the first available device ID
        const firstAvailableAudioOutputDeviceId = freshAudioOutputDeviceList[0].deviceId;
        setAudioOutputDevices(freshAudioOutputDeviceList);
        setSelectedAudioOutputDeviceId(firstAvailableAudioOutputDeviceId)
        changeAudioOutput(firstAvailableAudioOutputDeviceId, meetingManager)
      }
  
      freshAudioOutputDeviceList.forEach(mediaDeviceInfo => {
        console.log(`DEVICE SETUP Audio Output Device ID: ${mediaDeviceInfo.deviceId} Speaker: ${mediaDeviceInfo.label}`);
      });
    },
  
    videoInputsChanged: (freshVideoInputDeviceList?: MediaDeviceInfo[]) => {
      if (!freshVideoInputDeviceList) return;
  
      const videoInputDeviceFound = freshVideoInputDeviceList.some(device => device.deviceId === selectedVideoDeviceId);
  
      if (!videoInputDeviceFound && freshVideoInputDeviceList.length > 0) {
        const meetingManager = useMeetingManager();
        // Set the video input device to the first available device ID
        const firstAvailableVideoInputDeviceId = freshVideoInputDeviceList[0].deviceId;
        setVideoDevices(freshVideoInputDeviceList);
        setSelectedVideoDeviceId(firstAvailableVideoInputDeviceId);
        changeVideoInput(firstAvailableVideoInputDeviceId, meetingManager)
      }
  
      freshVideoInputDeviceList.forEach(mediaDeviceInfo => {
        console.log(`DEVICE SETUP Video Input Device ID: ${mediaDeviceInfo.deviceId} Camera: ${mediaDeviceInfo.label}`);
      });
    },
  };
  
  const toggleMeetingJoinDeviceSelection = (): void => {
    setSkipDeviceSelection((current) => !current);
  };

  const toggleWebAudio = (): void => {
    setIsWebAudioEnabled((current) => !current);
  };

  const toggleSimulcast = (): void => {
    setEnableSimulcast((current) => !current);
  };

  const togglePriorityBasedPolicy = (): void => {
    if (priorityBasedPolicy) {
      setPriorityBasedPolicy(undefined);
    } else {
      setPriorityBasedPolicy(new VideoPriorityBasedPolicy(logger));
    }
  };

  const toggleKeepLastFrameWhenPaused = (): void => {
    setKeepLastFrameWhenPaused((current) => !current);
  };

  const setCpuUtilization = (filterValue: string): void => {
    setCpuPercentage(filterValue);
  };

  const setBlob = (imageBlob: Blob): void => {
    setImageBlob(imageBlob);
  };

  const toggleEchoReduction = (): void => {
    setIsEchoReductionEnabled((current) => !current);
  };

  const fetchAllDevices = async (meetingManager: MeetingManager | undefined): Promise<void> => {
      if (!meetingManager) return
      try {
        let videoInputs = await meetingManager.audioVideo?.listVideoInputDevices() || [];
        videoInputs = videoInputs.filter(device => device.deviceId !== 'default'); // Filter out 'default' devices
        setVideoDevices(videoInputs);
        //console.log("fetchDevices video", videoInputs)
    
        let audioInputs = await meetingManager.audioVideo?.listAudioInputDevices() || [];
        audioInputs = audioInputs.filter(device => device.deviceId !== 'default'); // Filter out 'default' devices
        setAudioInputDevices(audioInputs);
        //console.log("fetchDevices audioInputs", audioInputs)
    
        let audioOutputs = await meetingManager.audioVideo?.listAudioOutputDevices() || [];
        audioOutputs = audioOutputs.filter(device => device.deviceId !== 'default'); // Filter out 'default' devices
        setAudioOutputDevices(audioOutputs);
  
        //console.log("fetchDevices audioOutputs", audioOutputs)
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
  };

  const fetchDevicesForType = async (buttonType: ControlButton, meetingManager: MeetingManager | undefined): Promise<void> => {
    console.log("fetchDevicesForType ");
    if (!meetingManager) return;  
  
    try {
      if (buttonType === 'video') {
        let videoInputs = await meetingManager.audioVideo?.listVideoInputDevices(true) || [];
        videoInputs = videoInputs.filter(device => device.deviceId !== 'default'); // Filter out 'default' devices
        setVideoDevices(videoInputs);
        console.log("fetchDevicesForType video", videoInputs);
      } else if (buttonType === 'microphone') {
        let audioInputs = await meetingManager.audioVideo?.listAudioInputDevices(true) || [];
        audioInputs = audioInputs.filter(device => device.deviceId !== 'default'); // Filter out 'default' devices
        setAudioInputDevices(audioInputs);
        console.log("fetchDevicesForType audioInputs", audioInputs);
      } else if (buttonType === 'speaker') {
        let audioOutputs = await meetingManager.audioVideo?.listAudioOutputDevices(true) || [];
        audioOutputs = audioOutputs.filter(device => device.deviceId !== 'default'); // Filter out 'default' devices
        setAudioOutputDevices(audioOutputs);
        console.log("fetchDevicesForType audioOutputs", audioOutputs);
      }
    } catch (error) {
      console.error(`Error fetching device for ${buttonType}`, error);
    }
  };

  const changeVideoInput = async (deviceId: string, meetingManager: MeetingManager | undefined): Promise<void> =>  {
    console.log('changeVideoInput for deviceId', deviceId);
    
    if (!meetingManager?.meetingSession?.audioVideo) {
      console.error('Meeting session or audioVideo not available.');
      return;
    }

    try {
      // Fetch and validate video input devices
      const videoInputDevices = await meetingManager.meetingSession.audioVideo.listVideoInputDevices();
      if (videoInputDevices.length === 0) {
        console.error('No video input devices found.');
        return;
      }

      console.log('changeVideoInput videoInputDevices', videoInputDevices);
      // Ensure the device ID is valid
      const validDevice = videoInputDevices.find(device => device.deviceId === deviceId);
      if (!validDevice) {
        console.error('Selected video device ID is not valid.');
        return;
      }
      console.log('changeVideoInput validDevice', validDevice);

      await meetingManager.meetingSession.audioVideo.startVideoInput(deviceId);

      toggleVideoEnabled(true)

      setSelectedVideoDeviceId(deviceId);
    } catch (error) {
      console.error('Error changing video input device:', error);
    }
  };
  const changeAudioInput = async (deviceId: string, meetingManager: MeetingManager | undefined): Promise<void> =>  {
    await meetingManager?.meetingSession?.audioVideo.startAudioInput(deviceId)
    toggleMicrophoneEnabled(true)
    setSelectedAudioInputDeviceId(deviceId)
    console.log('changeAudioInput deviceId', deviceId);
  };

  const changeAudioOutput = async (deviceId: string, meetingManager: MeetingManager | undefined): Promise<void> =>  {
    await meetingManager?.audioVideo?.chooseAudioOutput(deviceId);
    toggleSpeakerEnabled(true)
    setSelectedAudioOutputDeviceId(deviceId)
    console.log('changeAudioOutput deviceId', deviceId);
  };

  const switchCamera = async (meetingManager: MeetingManager | undefined): Promise<void> => {
    // Filter out only the front and back cameras
    const frontCamera = videoDevices.find(device => device.label.toLowerCase().includes('front'));
    const backCamera = videoDevices.find(device => device.label.toLowerCase().includes('back'));
    let nextDeviceId = "";

    if (frontCamera && backCamera) {
        // Determine which camera is currently selected
        const isFrontCameraSelected = selectedVideoDeviceId === frontCamera.deviceId;
        // Select the next camera based on the currently selected one
        nextDeviceId = isFrontCameraSelected ? backCamera.deviceId : frontCamera.deviceId;
    } else {
        // If no distinct front or back camera, find the first alternative camera
        const alternativeCamera = videoDevices.find(device => device.deviceId !== selectedVideoDeviceId);
        if (!alternativeCamera) {
            console.error("No alternative cameras available.");
            return;  // Return here to exit the function, ensures no further processing if no camera available
        }
        nextDeviceId = alternativeCamera.deviceId;
    }

    // Enable video and set the next device id as the selected video device
    toggleVideoEnabled(true);
    setSelectedVideoDeviceId(nextDeviceId);

    // Now, reselect the video input device using the Chime SDK or your video service
    if (meetingManager?.audioVideo) {
        try {
            await meetingManager.audioVideo.startVideoInput(nextDeviceId);
        } catch (error) {
            console.error("Error starting video input:", error);
        }
    }
};


//   const switchCamera = (meetingManager: MeetingManager | undefined) => {
//     // Filter out only the front and back cameras
//     const frontCamera = videoDevices.find(device => device.label.toLowerCase().includes('front'));
//     const backCamera = videoDevices.find(device => device.label.toLowerCase().includes('back'));
  
//     if (!frontCamera || !backCamera) {
//       console.error("Front or back camera not found.");
//       return;
//     }
  
//     // Determine which camera is currently selected
//     const isFrontCameraSelected = selectedVideoDeviceId === frontCamera.deviceId;
  
//     // Select the next camera based on the currently selected one
//     const nextDeviceId = isFrontCameraSelected ? backCamera.deviceId : frontCamera.deviceId;
  
//     // Enable video and set the next device id as the selected video device
//     toggleVideoEnabled(true);
//     setSelectedVideoDeviceId(nextDeviceId);
  
//     // Now, reselect the video input device using the Chime SDK or your video service
//     meetingManager?.audioVideo?.startVideoInput(nextDeviceId);
// };

   // We cannot change message
   useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message = "Are you sure you want to leave?";
      event.returnValue = message; // Standard for most browsers
      return message; // For some browsers
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const closeWebView = () => {
    //Check if ReactNativeWebView is available to avoid errors in a standard web environment
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('endCall');
    }
  }

  const leaveMeeting = async (meetingManager: MeetingManager | undefined): Promise<void> => {
    try {
      // Stop the local audio and video
      meetingManager?.audioVideo?.stopLocalVideoTile();
      meetingManager?.audioVideo?.stopAudioInput();
      
      closeWebView()

       // Leave the meeting
      await meetingManager?.leave();
      setMeetingStatus("disconnected")
      // Leave the meeting
    } catch (error) {
      console.error('Failed to leave the meeting', error);
    }
  };

  const endMeetingForAll = async (meetingManager: MeetingManager | undefined): Promise<void> => {
    try {
      console.log("endMeetingForAll meetingId", meetingId)
      if (meetingId) {
        await endMeeting(meetingId);
        await leaveMeeting(meetingManager)
      }
    } catch (e) {
      logger.error(`Could not end meeting: ${e}`);
    }
  };

  const toggleMicrophone = (meetingManager: MeetingManager | undefined) => {
    if (!meetingManager?.audioVideo) {
      console.error("AudioVideo not initialized for  meetingManager", meetingManager);
      return;
    }
  
    const isMuted = meetingManager.audioVideo.realtimeIsLocalAudioMuted();
    if (isMuted) {
      meetingManager.audioVideo.realtimeUnmuteLocalAudio();
      
    } else {
      meetingManager.audioVideo.realtimeMuteLocalAudio();

    }
    toggleMicrophoneEnabled(!meetingManager.audioVideo.realtimeIsLocalAudioMuted())
    console.log("toggleMicrophone Muted", meetingManager.audioVideo.realtimeIsLocalAudioMuted())
  };

  const providerValue = {
    meetingId,
    localUserName,
    theme,
    isWebAudioEnabled,
    videoTransformCpuUtilization,
    imageBlob,
    isEchoReductionEnabled,
    region,
    meetingMode,
    layout,
    joinInfo,
    enableSimulcast,
    priorityBasedPolicy,
    keepLastFrameWhenPaused,
    meetingData,
    meetingStatus,
    meetingSession,
    videoEnabled,
    microphoneEnabled,
    speakerEnabled,
    videoDevices,
    audioInputDevices,
    audioOutputDevices,
    selectedVideoDeviceId,
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    observerDeviceList,
    closeWebView,
    toggleMicrophone,
    setVideoDevices,
    setAudioInputDevices,
    setAudioOutputDevices,
    setSelectedVideoDeviceId,
    setSelectedAudioInputDeviceId,
    setSelectedAudioOutputDeviceId,
    setMeetingSession,
    setMeetingData,
    setMeetingStatus,
    toggleTheme,
    toggleWebAudio,
    toggleSpeakerEnabled, 
    toggleVideoEnabled,
    toggleMicrophoneEnabled,
    togglePriorityBasedPolicy,
    toggleKeepLastFrameWhenPaused,
    toggleSimulcast,
    setCpuUtilization,
    toggleEchoReduction,
    setMeetingMode,
    setLayout,
    setJoinInfo,
    setMeetingId,
    setLocalUserName,
    setRegion,
    setBlob,
    setTheme,
    skipDeviceSelection,
    toggleMeetingJoinDeviceSelection,
    switchCamera,
    changeAudioInput,
    changeVideoInput,
    changeAudioOutput,
    leaveMeeting,
    endMeetingForAll,
    fetchDevicesForType,
    fetchAllDevices,
  };

  return <MeetingStateContext.Provider value={providerValue}>{children}</MeetingStateContext.Provider>;
}
