import React, { ReactElement, useState, useEffect, useContext } from 'react'
import InvitePopup from '../../components/InvitePopup';
import maleAvatar from '../../assets/images/maleAvatar.png';
import femaleAvatar from '../../assets/images/femaleAvatar.png';
import crossIcon from '../../assets/images/cross-white.png';
import linkIcon from '../../assets/images/link-icon.png';
import { RotatingLines as Spinner } from 'react-loader-spinner'
import Api from '../../helpers/customAPIs';
import { Follower, NewFollowerData} from '../../helpers/interfaces';
import { InvitationStatus, PopupType} from '../../helpers/types';
import Colors from "../../constants/colors"
import {UserContext} from '../../contexts/userContext'
import PopUp from '../../components/Popup'
import { useNavigate } from 'react-router-dom';
import routes from '../../constants/routes';
import NavBar from '../../components/NavBar'
import { inviteFollower, deleteInvite } from '../../api/deepLinkAPI';
import Prompt from '../../components/Prompt';
import { useOrientationChange } from '../../hooks/useOrientationChange';
import { isMobile, isTablet} from 'react-device-detect';
import Avatar from '../../components/Avatar';

function Followers(): ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useContext(UserContext);
  const [showPopup, setShowPopup] = useState(false)
  const [popuData, setPopupData] = useState({type: 'error' as PopupType, title: 'Somthing went wrong', message: 'Error', target: null as any})
  const navigate = useNavigate();
  const [followers, setFollowers] = useState<Follower[]>([]);
  const [followersPending, setFollowersPending] = useState<Follower[]>([]);
  const [isInvitePopupVisible, setInvitePopupVisible] = useState(false);
  const [retryFollower, setRetryFollower] = useState<Follower | null>(null);
  const [promptMessage, setPromptMessage] = useState('');
  const [isPromptVisible, setIsPromptVisible] = useState(false);
  const isPortrait = useOrientationChange();
 
  const isPhone= () => {
    return isMobile && !isTablet
  }

  useEffect(() => {
    setIsLoading(true)
  }, []);

 
  useEffect(() => {
    fetchFollowersList();
  }, []);

  const fetchFollowersList = async () => {
    if (user) {
      setIsLoading(true)
      try {
        const followersList = await Api.getFollowersForInviter(user.contactID);
        groupFollowers(followersList)
        setIsLoading(false)
      } catch (error) {
        console.log('fetchFollowersList error: ', error)
        setIsLoading(false)
      }
    }
  };

  const groupFollowers = (followersList: Follower[]) => {
    const groupedFollowers: { following: Follower[], pending: Follower[] } = {
      following: [],
      pending: []
    };

    // Separate failed followers first and sort by updatedAt
    const failedFollowers = followersList.filter(follower => follower.status.toLowerCase() === 'failed');
    failedFollowers.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    groupedFollowers.pending = failedFollowers;

    // Group remaining followers based on status
    const otherFollowers = followersList.filter(follower => follower.status.toLowerCase() !== 'failed');
    otherFollowers.forEach((follower) => {
      if (follower.status.toLowerCase() === 'following') {
        groupedFollowers.following.push(follower);
      } else {
        groupedFollowers.pending.push(follower);
      }
    });

    // Sort followers based on updatedAt timestamp
    groupedFollowers.following.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    //groupedFollowers.pending.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

    setFollowers(groupedFollowers.following);
    setFollowersPending(groupedFollowers.pending);
  };

 
  const onInviteClick = () => {
    setInvitePopupVisible(true);
  }

  const createInvite = async (followerData: NewFollowerData) => {
    console.log('createInvite followerData: ', followerData)
    setInvitePopupVisible(false);
    if (user) {
      setIsLoading(true)
      try {
        const response = await inviteFollower(user.contactID, user.ProfileImageUrl ?? "", user.FirstName + ' ' + user.LastName, "instructor", followerData.name, followerData.endpoint, followerData.channel, "",false);
        console.log('createInvite response: ', response)
        if (response.success) {
          setPromptMessage(`Invite sent to ${followerData.endpoint}`)
          setIsPromptVisible(true)
          fetchFollowersList();
        } else {
          setPopupData({type: 'error', title: 'Cannot sent invite', message: response.message, target: followerData})
          setShowPopup(true)
        }
        setIsLoading(false) 
        setInvitePopupVisible(false)
      } catch (error) {
        console.log('createInvite error: ', error)
        setIsLoading(false)
        setPopupData({type: 'error', title: 'Error', message: `Somthing went wrong. Cannot sent invite to ${followerData.name} to the following ${followerData.channel}: ${followerData.endpoint}`, target: followerData})
        setShowPopup(true)
      }
    }

  }

  const revokeInvite = async (follower: Follower) => {
    setShowPopup(false)
    console.log('deleteInvite follower: ', follower)
    if (follower) {
      setIsLoading(true)
      try {
        const response = await deleteInvite(follower.inviterID, follower.id, follower.link);
        console.log('deleteInvite response: ', response)
        if (response.success) {
          setPromptMessage(`Invite deleted to ${follower.followerName}`)
          setIsPromptVisible(true)
          fetchFollowersList();
        } else {
          setPopupData({type: 'error', title: 'Cannot revoke invite', message: response.message, target: follower})
          setShowPopup(true)
        }
        setIsLoading(false) 
        setInvitePopupVisible(false)
      } catch (error) {
        console.log('createInvite error: ', error)
        setIsLoading(false)
        setPopupData({type: 'error', title: 'Error', message: `Somthing went wrong. Cannot delete invite to ${follower.followerName} to the following ${follower.channel}: ${follower.endpoint}`, target: follower})
        setShowPopup(true)
      }
    }

  }

  const onRevokeClick = (follower: Follower) => {
    setPopupData({type: 'revokeInvite', title: 'Revoke Invite', message: 'Are you sure you want to revoke this invitation?', target: follower})
    setShowPopup(true)
  }

  const onRetryClick = (follower: Follower) => {
    setRetryFollower(follower)
    setInvitePopupVisible(true)
  }

  const popupAction= (type: PopupType, target: any)=>{
    switch (type) {
      case 'error':
        
        break;
      case 'revokeInvite':
        const follower = target as Follower;
        revokeInvite(follower)
      default:
        break;
    }
  }

  const handleCopyLink = (link: string) => {
    console.log('handleCopyLink link: ', link);
    navigator.clipboard.writeText(link).then(() => {
      console.log('Link copied to clipboard');
      setPromptMessage(`The link has been copied to the clipboard`)
      setIsPromptVisible(true)
    }).catch(err => {
      console.error('Failed to copy link: ', err);
    });
  }

  const getStatus = (follower: Follower): { statusText: string; textColorClass: string } => {
    switch (follower.status.toLowerCase()) {
      case 'pending':
        const updatedAtDate = new Date(follower.updatedAt);
        const formattedDate = updatedAtDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        const formattedTime = updatedAtDate.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        }).toLowerCase();
        return { statusText: `Invited on ${formattedDate} ${formattedTime}`, textColorClass: 'text-green-text' }; // Adjust color as needed
      case 'following':
        return { statusText: '', textColorClass: '' }; // Adjust color as needed
      case 'unfollow':
        return { statusText: '', textColorClass: '' };
      case 'failed':
        return { statusText: 'Failed to Deliver', textColorClass: 'text-warning-text' }; // Adjust color as needed
      default:
        return { statusText: '', textColorClass: '' };
    }
  };

 
  return (
    <div className={`${isPhone() ? 'pt-[74px] px-[16px]' : 'pt-[24px] px-[48px]'} w-full h-screen`}>
      <NavBar />
      <div>
        <div className="flex flex-row self-center items-center justify-between pb-[20px]">
          <div className={`${isPhone() ? 'text-[30px]' : 'text-[48px]'} self-center`}>Followers</div>
          <button
            onClick={onInviteClick}
            className="min-w-[100px] h-[46px] bg-primary-button hover:bg-hover-button text-primary-text text-[20px] font-bold py-2 px-6 rounded-full focus:outline-none focus:shadow-outline"
          >
            Invite
          </button>
        </div>
        
        {/* Followers Pending Container */}
        <div className="bg-follower-background rounded-[10px] p-[15px]">
          {followersPending.length === 0 ? (
            <div className="text-primary-text text-[16px] font-bold">You do not have sent invitations</div>
          ) : (
            followersPending.map((follower, index) => (
              <div className={`${index === 0 ? '' : 'mt-[12px]'}`} key={follower.id}>
                <div className="flex flex-row items-center justify-between">
                  <div className={`${isPhone() ? 'flex-col' : 'flex-row'} flex`}>
                    <div className="flex flex-row">
                      <img src={maleAvatar} alt="avatar" className="h-[32px] w-[32px] mr-[16px] rounded-full" />
                      <span className="w-[200px] text-primary-text text-[16px] font-bold self-center mr-[10px]">
                        {`${follower.followerName}`}
                      </span>
                    </div>
                    <span className={`${isPhone() ? 'my-[10px]' : ''} w-[250px] text-primary-text text-[16px] font-bold self-center mr-[30px]`}>
                      {`${follower.endpoint}`}
                    </span>
                    <span className={`w-[250px] font-italic self-center ${getStatus(follower).textColorClass} mr-[30px]`}>
                      {getStatus(follower).statusText}
                    </span>
                  </div>
                  <div>
                    {follower.status === "failed" ? (
                      <div>
                        <button
                          onClick={() => onRetryClick(follower)}
                          className="min-w-[70px] h-[33px] border-[2px] border-warning-border text-warning-text text-[14px] font-bold rounded-full"
                        >
                          Retry
                        </button>
                        <button
                          onClick={() => onRevokeClick(follower)}
                          className="min-w-[33px] h-[33px] bg-warning-button hover:bg-hover-button px-[12px] text-white text-[20px] font-bold rounded-full ml-[10px]"
                        >
                          <img src={crossIcon} alt="close" className="h-[8px] w-[8px] items-center" />
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => onRevokeClick(follower)}
                        className="min-w-[33px] h-[33px] bg-warning-button hover:bg-hover-button px-[12px] text-white text-[20px] font-bold rounded-full"
                      >
                        <img src={crossIcon} alt="close" className="h-[8px] w-[8px] items-center" />
                      </button>
                    )}
                  </div>
                </div>
                {index < followersPending.length - 1 && <div className="bg-followers-devider h-[1px] mt-[12px]" />}
              </div>
            ))
          )}
        </div>
  
        {/* Followers Container */}
        <div className="border-solid border-[1px] border-followers-devider rounded-[10px] p-[15px] mt-[35px]">
          {followers.length === 0 ? (
            <div className="text-primary-text text-[16px] font-bold">You do not have followers yet</div>
          ) : (
            followers.map((follower, index) => (
              <div className={`${index === 0 ? '' : 'mt-[12px]'}`} key={follower.id}>
                <div className="flex flex-row items-center justify-between">
                  <div className={`${isPhone() ? 'flex-col' : 'flex-row'} flex`}>
                    <div className="flex flex-row">
                      <Avatar follower={follower} />
                      <span className="w-[200px] text-primary-text text-[16px] font-bold self-center pr-[10px]">
                        {`${follower.followerName}`}
                      </span>
                    </div>
                    <span className={`${isPhone() ? 'my-[10px]' : ''} min-w-[250px] text-primary-text text-[16px] font-bold self-center pr-[30px]`}>
                      {`${follower.endpoint}`}
                    </span>
                  </div>
                </div>
                {index < followers.length - 1 && <div className="bg-followers-devider h-[1px] mt-[12px]" />}
              </div>
            ))
          )}
        </div>
      </div>
  
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <Spinner visible={true} width="40px" strokeColor={Colors.primaryButton} />
        </div>
      )}
      {showPopup && (
        <PopUp
          type={popuData.type}
          isOpen={showPopup}
          onClose={() => {
            fetchFollowersList();
            setShowPopup(false);
          }}
          titleString={popuData.title}
          messageString={popuData.message}
          onActionOne={() => {
            popupAction(popuData.type, popuData.target);
          }}
        />
      )}
      {isInvitePopupVisible && (
        <InvitePopup
          closePopup={() => {
            setInvitePopupVisible(false);
            setRetryFollower(null);
          }}
          follower={retryFollower}
          createInvite={createInvite}
        />
      )}
      <Prompt message={promptMessage} isVisible={isPromptVisible} onClose={() => setIsPromptVisible(false)} isPortrait={isPortrait} isIncognito={false} useTimer={true} />
    </div>
  );
}
  

export default Followers


