import { ReactElement, useState, useEffect, useContext } from 'react'
import ClassCard from '../../components/ClassCard';
import Api from '../../helpers/customAPIs';
import { AttendanceRecord } from '../../helpers/interfaces';
import {UserContext} from '../../contexts/userContext'
import PwaInstallPopup from "../../components/PwaInstallPopup"
import PopUp from '../../components/Popup'
import { useNavigate } from 'react-router-dom';
import routes from '../../constants/routes';
import { RotatingLines as Spinner } from 'react-loader-spinner'
import Colors from "../../constants/colors"
import { isMobile, isTablet} from 'react-device-detect';

function Start(): ReactElement {
  const [classData, setClassData] = useState<AttendanceRecord[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [usersCountryCode, setUsersCountryCode] = useState("ca")
  const { user } = useContext(UserContext);
  const [showPopup, setShowPopup] = useState(false)
  const [error, setError] = useState({title: "Error", message: "Somthing went wrong"})
  const navigate = useNavigate();

  const isPhone= () => {
    return isMobile && !isTablet
  }

  const handleJoinNowClick = (classItem: AttendanceRecord) => {
     console.log('handleJoinNowClick classItem', classItem)
     navigate(routes.DEVICE, { state: { classItem } });
  };

  // Resets app theme if it was set to dark mode 
  useEffect(() => {
    localStorage.setItem('theme', 'light');
    setIsLoading(true)
  }, []);

  useEffect(() => {
    if (user) {
      if (user.IsInstructor) {
        fetchAttendanceListForInstructor();
      } else {
        fetchAttendanceList();
      }
    }
  }, [user]);

  const fetchAttendanceList = async () => {
    if (user) {
      setIsLoading(true)
      try {
        const attendanceList = await Api.getAttendanceList(user, usersCountryCode);
        // console.log('fetchAttendanceList attendanceList FULL', attendanceList)
        // console.log('fetchAttendanceList attendanceList sliced', attendanceList.slice(0, 6))
        setClassData(attendanceList.slice(0, 6))
        setIsLoading(false)
      } catch (error) {
        console.log('getAttendanceList error: ', error)
        setIsLoading(false)
      }
    }
  };

  const fetchAttendanceListForInstructor = async () => {
    console.log('fetchAttendanceListForInstructor')
    if (user) {
      setIsLoading(true)
      try {
        const attendanceListForInstructor = await Api.getAttendanceListForInstructor(user, usersCountryCode);
        setIsLoading(false)
        const data = [...classData || [], ...attendanceListForInstructor.slice(0, 6)];
        data.sort((a, b) => new Date(a.EventStartTime).getTime() - new Date(b.EventStartTime).getTime());
        // console.log('fetchAttendanceListForInstructor FULL', attendanceListForInstructor)
        // console.log('fetchAttendanceListForInstructor sliced', attendanceListForInstructor.slice(0, 6))
        setClassData(data.slice(0, 6))
      } catch (error) {
        console.log('fetchAttendanceListForInstructor error', error)
        setIsLoading(false)
      }
    }
  };

  const getTitle=() => {
    if (isLoading) {
      return (classData ?? []).length > 0 ? "" : ""
    } else {
      return (classData ?? []).length > 0 ? "" : "No Upcoming Classes"
    }
  }

  return (
    <div className={`${isPhone() ? 'pt-[80px]' : 'pt-[24px] px-[48px]'} flex flex-col w-full h-screen`}>
      <PwaInstallPopup/>
      <div className={`${isPhone() ? 'self-center pb-[0px]' : 'pb-[20px]'} h-[72px]`}>
          <div className={`${isPhone() ? 'text-[30px]' : 'text-[48px]'} self-center`}>Upcoming Classes</div>
       </div>
     <div className="flex justify-center items-center text-[32px] font-semibold  mt-[0] mb-[24px]">
       {getTitle()}
    </div>  
    <div className="container px-4 md:px-12 mx-auto ">
      <div className="flex flex-wrap">
       {classData?.map((classItem, index) => (
          <div key={index} className={`w-full sm:w-1/2 md:w-1/2 lg:w-1/3`}>
            <ClassCard
              data={classItem}
              onButtonClick={() => handleJoinNowClick(classItem)}
              isInstructor={user?.IsInstructor ??  false}
            />
          </div>
        ))} 
      </div>
    </div>

    {(isLoading) && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <Spinner 
            visible={true}
            width='40px'
            strokeColor= {Colors.primaryButton}
          />
        </div>
      )}
      {showPopup && (
        <PopUp 
          type={'error'} 
          isOpen={showPopup} 
          onClose={() => {setShowPopup(false)}}
          titleString= {error.title}
          messageString={error.message}
        />
      )}
    </div>
  )
}

export default Start


