import React, { useState} from 'react'
import { PopupProps } from '../helpers/interfaces'
import { isMobile, isTablet} from 'react-device-detect';
import { RotatingLines as Spinner } from 'react-loader-spinner'
import Colors from "../constants/colors"

const Popup: React.FC<PopupProps> = ({
  type,
  isOpen,
  isIncognito,
  messageString,
  titleString = "Error",
  onClose,
  onActionOne,
  onActionTwo,
  onActionThree  // Optional third action
}) => {
  if (!isOpen) return null;
  const [isLoading, setIsLoading] = useState(false)
  const isPhone= () => {
    return (isMobile && !isTablet) || isIncognito
  }
  
  const config = {
    endMeeting: {
      title: 'End Meeting',
      message: 'Are you sure about leaving the class?',
      buttons: [
        { label: 'Leave Meeting', isDestructive: true, action: onActionOne },
        { label: 'Cancel', isDestructive: false, action: onClose }
      ]
    },
    endMeetingForAll: {
      title: 'End Meeting for All',
      message: 'Are you sure about closing the class meeting?',
      buttons: [
        { label: 'End Meeting for All', isDestructive: true, action: onActionOne },
        { label: 'Cancel', isDestructive: false, action: onClose }
      ]
    },
    cannotJoinMeeting: {
      title: 'Unable to join meeting',
      message: 'There was an issue finding that meeting. The meeting may have already ended, or your authorization may have expired.',
      buttons: [
        { label: 'Try again', isDestructive: true, action: onActionOne },
        { label: 'Cancel', isDestructive: false, action: onClose }
      ]
    },
    error: {
      title: titleString,
      message: messageString,
      buttons: [
        { label: 'Ok', isDestructive: false, action: onClose }
      ]
    },
    revokeInvite: {
      title: titleString,
      message: messageString,
      buttons: [
        { label: 'Revoke', isDestructive: true, action: onActionOne },
        { label: 'Cancel', isDestructive: false, action: onClose }
      ]
    },
  };

  const { title, message, buttons } = config[type];

  const getDisabledButtonBackground= (button: any) => {
    let background = button.isDestructive ? 'bg-error-button hover:bg-error-button' : 'bg-primary-button hover:bg-primary-button'
    if (isLoading && button.isDestructive) {
      background += " bg-opacity-50"
    }  
    return background
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center">
      <div className={` w-full ${isPhone() ? 'max-w-[350px]' : 'max-w-[500px]'} bg-white  p-8 rounded-lg shadow-lg  text-center`}>
        <h2 className="text-xl text-black font-bold">{title}</h2>
        <p className="my-4 text-black">{message}</p>
        {(isLoading) && (
          <div className=" inset-0 z-50 flex items-center justify-center">
            <Spinner
              visible={true}
              width='40px'
              strokeColor={Colors.primaryButton}
            />
          </div>
        )}
        <div className="flex justify-center space-x-4">
          {buttons.map((button) => (
            <button
              key={button.label}
              onClick={() => {
                if (button.action) {  
                  button.action();
                  setIsLoading(true)
                }
              }}
              disabled={isLoading && button.isDestructive }
              className={`px-4 py-2 h-[50px] min-w-[100px] font-bold rounded-full text-white transition duration-150 ${getDisabledButtonBackground(button)}`}
            >
              {button.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Popup;