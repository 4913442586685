import React, { useState } from 'react';
import maleAvatar from '../assets/images/maleAvatar.png';
import femaleAvatar from '../assets/images/femaleAvatar.png';

interface Follower {
  followerImage?: string;
}

interface AvatarProps {
  follower: Follower;
}

const Avatar: React.FC<AvatarProps> = ({ follower }) => {
  const [imgSrc, setImgSrc] = useState(follower.followerImage || maleAvatar);

  const handleImageError = () => {
    setImgSrc(maleAvatar);
  };

  return (
    <img
      src={imgSrc}
      alt="avatar"
      className="h-[32px] w-[32px] mr-[16px] rounded-full"
      onError={handleImageError}
    />
  );
};

export default Avatar;
