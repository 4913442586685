import React, { useEffect, useState } from 'react';

const PwaInstallPopup: React.FC = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const isIOS = (): boolean => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  };

  useEffect(() => {
    if (isIOS() || isAppInstalled()) {
      return; // Don't show install prompt if it's iOS or app is installed
    }

    window.addEventListener('appinstalled', () => {
      console.log('App was installed.');
      localStorage.setItem('appInstalled', 'true'); // Set flag when app is installed
    });

    const installFlag = localStorage.getItem('showInstallApp');
    if (installFlag === 'false') {
      return; // Early return if user chose 'Do Not Ask Again' or app is installed
    }

    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault(); // Prevent the mini-infobar from appearing on mobile
      console.log(`'beforeinstallprompt' event was fired.`);
      setDeferredPrompt(e);
      setShowPopup(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    localStorage.setItem('showInstallApp', 'false'); // Set a flag in local storage
    setShowPopup(false);
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
          localStorage.setItem('appInstalled', 'true');
          localStorage.setItem('showInstallApp', 'false');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  const handleNoThanksClick = () => {
    localStorage.setItem('showInstallApp', 'false'); // Set a flag in local storage
    setShowPopup(false);
  };

  const handleDoNotAskAgainClick = () => {
    localStorage.setItem('showInstallApp', 'false'); // Set a flag in local storage
    setShowPopup(false);
  };

  const isAppInstalled = () => {
    return localStorage.getItem('appInstalled') === 'true';
  };

  if (!showPopup) return null;

  return (
    <div className="fixed flex flex-col items-center justify-center left-1/2 text-center transform -translate-x-1/2 bg-secondary-background p-5 shadow-lg z-50 w-[380px] h-[200px] text-[18px] font-bold rounded-md">
      <p>Would you like to install the app and get easier access to it?</p>
      <div className="flex justify-center space-x-4 mt-8">
        <button onClick={handleInstallClick} className="min-w-[80px] h-[36px] bg-primary-button hover:bg-hover-button text-primary-text text-[15px] font-bold py-2 px-5 rounded-full focus:outline-none focus:shadow-outline">
          Yes
        </button>
        <button onClick={handleNoThanksClick} className="min-w-[80px] h-[36px] bg-primary-button hover:bg-hover-button text-primary-text text-[15px] font-bold py-2 px-5 rounded-full focus:outline-none focus:shadow-outline">
          No
        </button>
        {/* <button onClick={handleDoNotAskAgainClick} className="min-w-[138px] h-[36px] bg-primary-button hover:bg-hover-button text-primary-text text-[13px] font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline">
          Do Not Ask Again
        </button> */}
      </div>
    </div>
  );
};

export default PwaInstallPopup;
