
import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  ControlBar,
  useUserActivityState,
  MeetingManager,
  useLocalVideo,
  useLocalAudioOutput
} from 'amazon-chime-sdk-component-library-react';
import { useNavigation } from '../../providers/NavigationProvider';
import { StyledControls } from './Styled';
import { useMeetingState } from '../../providers/MeetingStateProvider';
import { isMobile, isTablet } from 'react-device-detect';
import MeetingControlButton from '../../../MeetingControlButton';
import { UserContext } from '../../../../contexts/userContext'
import { useDataMessages } from '../../providers/DataMessagesProvider';
import {ControlButton}  from '../../../../helpers/types'

interface MeetingControlsProps {
  isInstructor: boolean;
  handleShareVideo: () => void;
  handleOpenDeviceSelection: (buttonType: ControlButton) => void;
  isIncognito: boolean;
  meetingManager: MeetingManager | undefined;
}

const MeetingControls: React.FC<MeetingControlsProps> = ({ isInstructor, handleShareVideo, handleOpenDeviceSelection, isIncognito, meetingManager }) => {
  const { user } = useContext(UserContext);
  const { toggleChat, toggleRoster, closeChat, closeRoster, showChat, showRoster} = useNavigation();
  const { isUserActive } = useUserActivityState();
  const [hasNewMessage, setHasNewMessage] = useState(false)
  const { messages } = useDataMessages();

  const isPhone = () => {
    return (isMobile && !isTablet) || isIncognito
  }

  const {
    videoEnabled,
    microphoneEnabled,
    speakerEnabled,
    toggleVideoEnabled,
    toggleMicrophoneEnabled,
    toggleSpeakerEnabled,
    toggleMicrophone,
    videoDevices,
    audioInputDevices,
    audioOutputDevices,
    selectedVideoDeviceId,
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    changeAudioInput,
    changeVideoInput,
    changeAudioOutput,
    joinInfo,
  } = useMeetingState();

  const { toggleAudio } = useLocalAudioOutput()
  const { toggleVideo } = useLocalVideo()
  const chatRef = useRef<HTMLDivElement>(null);
  const rosterRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (chatRef.current && !chatRef.current.contains(event.target as Node)) {
        closeChat();  // Assuming closeChat is a function to close the chat
      }
      if (rosterRef.current && !rosterRef.current.contains(event.target as Node)) {
        closeRoster();  // Assuming closeRoster is a function to close the roster
      }
    };
  
    // Attach the listener to the document
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [chatRef, rosterRef]); 

  useEffect(() => {
    // Check if the last message was sent by the current user
    try {
      const senderAttendeeId = messages[messages.length - 1]?.senderAttendeeId
      let userAttendeeId = ""
      if (typeof joinInfo?.Attendee === 'object') {
        userAttendeeId = joinInfo.Attendee["Attendee"]["AttendeeId"];
      } 
      if (senderAttendeeId === userAttendeeId) return 
    } catch (error) {
      console.error('Error parsing Attendee info:', error);
    }
    
   // Check if the chat is already openrd and has messages
    if (!showChat && messages.length > 0) {
      console.log("messages", messages)
      setHasNewMessage(true)
    }
  }, [messages]);


  return (
    <StyledControls className="controls" active={!!isUserActive} isIncognito={isIncognito}>
      <ControlBar
        className="controls-menu"
        layout="undocked-horizontal"
        showLabels
      >
        <div className={`flex justify-around items-center p-2 `}>
          <MeetingControlButton
            buttonType="video"
            isActive={videoEnabled}
            onClick={() => {
              toggleVideoEnabled(!videoEnabled)
              toggleVideo()
            }
            }
            onSelect={changeVideoInput}
            deviceList={videoDevices}
            selectedDeviceId={selectedVideoDeviceId}
            isMobile={isPhone()}
            margin='mr-[3px] ml-[0px]'
            showDeviceSelectionFor={["video"]}
            onArrowClick={ isPhone() ?  handleOpenDeviceSelection : undefined}
          />
          <MeetingControlButton
            buttonType="microphone"
            isActive={microphoneEnabled}
            onClick={() => {
              toggleMicrophoneEnabled(!microphoneEnabled)
              toggleMicrophone(meetingManager)
            }}
            onSelect={changeAudioInput}
            deviceList={audioInputDevices}
            selectedDeviceId={selectedAudioInputDeviceId}
            isMobile={isPhone()}
            showDeviceSelectionFor={["microphone"]}
            onArrowClick={ isPhone() ?  handleOpenDeviceSelection : undefined}
          />
          {!isPhone() &&
            <MeetingControlButton
              buttonType="speaker"
              isActive={speakerEnabled}
              onClick={() => {
                toggleAudio()
                toggleSpeakerEnabled(!speakerEnabled)
              }}
              onSelect={changeAudioOutput}
              deviceList={audioOutputDevices}
              selectedDeviceId={selectedAudioOutputDeviceId}
              isMobile={isPhone()}
              onArrowClick={ isPhone() ?  handleOpenDeviceSelection : undefined}
            />
          }
          <MeetingControlButton
            buttonType="messages"
            isActive={showChat}
            onClick={() => {
              toggleChat()
              setHasNewMessage(false);
            }}
            deviceList={[]}
            selectedDeviceId={''}
            isMobile={isPhone()}
            hasBadge={hasNewMessage}
          />
          {isInstructor &&
            <div className="flex justify-center items-center">
              <MeetingControlButton
                buttonType="attendees"
                isActive={showRoster}
                onClick={() => { toggleRoster() }}
                deviceList={[]}
                selectedDeviceId={''}
                isMobile={isPhone()}
              />
              <MeetingControlButton
                buttonType="share"
                isActive={true}
                onClick={() => {
                  handleShareVideo()
                  closeChat()
                  closeRoster()
                }}
                deviceList={[]}
                selectedDeviceId={''}
                isMobile={isPhone()}
                margin='mr-[3px] ml-[4px]'
              />
            </div>
          }
        </div>
      </ControlBar>
    </StyledControls>
  );
};

export default MeetingControls;
