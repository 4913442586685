import React, { useState, useEffect, useRef, useContext } from 'react';
import { MeetingManager, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { useMeetingState } from '../../providers/MeetingStateProvider';
import { isMobile, isTablet } from 'react-device-detect';
import { RotatingLines as Spinner } from 'react-loader-spinner'
import Colors from "../../../../constants/colors"
import MeetingControlButton from '../../../MeetingControlButton';
import cameraOffPlaceholder from '../../../../assets/images/camera_off_placeholder.png'
import { ControlButton } from '../../../../helpers/types';
import { useOrientationChange } from '../../../../hooks/useOrientationChange';
 
interface DeviceSelectionProps {
  isIncognito?: boolean;
  isPortrait: boolean;
  isPhone: boolean;
  handleOpenDeviceSelection: (buttonType: ControlButton) => void;
}
 
const DeviceSelection: React.FC<DeviceSelectionProps> = ({ isIncognito = false, isPortrait, isPhone,  handleOpenDeviceSelection }) => {
  const meetingManager = useMeetingManager();
  const {
    videoEnabled,
    microphoneEnabled,
    speakerEnabled,
    toggleVideoEnabled,
    toggleMicrophoneEnabled,
    toggleSpeakerEnabled,
    videoDevices,
    audioInputDevices,
    audioOutputDevices,
    selectedVideoDeviceId,
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    setSelectedVideoDeviceId,
    setSelectedAudioInputDeviceId,
    setSelectedAudioOutputDeviceId,
    changeAudioInput,
    changeVideoInput,
    changeAudioOutput,
    fetchAllDevices,
  } = useMeetingState();
  const [isVideoReady, setVideoReady] = useState(false);
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const [isChangingOrientation, setIsChangingOrientation] = useState(true);

  useEffect(() => {
    // Define an async function inside the effect
    const setupDevicesAndPreview = async () => {
      if (meetingManager && meetingManager.audioVideo) {
        await fetchAllDevices(meetingManager);
        setInitDevices();
      }
    };
 
    // Call the async function
    setupDevicesAndPreview();
 
    // Cleanup function
    return () => {
      stopVideoPreview(); // This should stop the video preview, assumed to be synchronous
    };
  }, [meetingManager.audioVideo]);
 
  useEffect(() => {
    setInitDevices();
  }, [videoDevices]);
 
 
  useEffect(() => {
    console.log("selectedVideoDeviceId", selectedVideoDeviceId)
    if (videoEnabled) {
      startVideoPreview();
    } else {
      stopVideoPreview()
    }
  }, [selectedVideoDeviceId, videoEnabled]);
 

 
  useEffect(() => {
    const video = videoElementRef.current;
    if (video) {
      video.addEventListener('loadeddata', onLoadedData);
    }
    return () => {
      if (video) {
        video.removeEventListener('loadeddata', onLoadedData);
      }
    }
  }, [videoElementRef]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
 
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    }
  }, []);
 
  const setInitDevices = () => {
    console.log("videoDevices", videoDevices)
    console.log("audioInputDevices", audioInputDevices)
    console.log("audioOutputDevices", audioOutputDevices)
    if (videoDevices.length > 0) {
      console.log("videoDevices[0].deviceId", videoDevices[0].deviceId)
      setSelectedVideoDeviceId(videoDevices[0].deviceId)
    }
 
    if (audioInputDevices.length > 0) {
      console.log("audioInputDevices[0].deviceId", audioInputDevices[0].deviceId)
      setSelectedAudioInputDeviceId(audioInputDevices[0].deviceId)
    }
 
    if (audioOutputDevices.length > 0) {
      console.log("audioOutputDevices[0].deviceId", audioOutputDevices[0].deviceId)
      setSelectedAudioOutputDeviceId(audioOutputDevices[0].deviceId)
    }
 
  }
 
  const onLoadedData = () => {
    console.log("onLoadedData ", true)
    setVideoReady(true);
    setIsChangingOrientation(false)
  };

  const startVideoPreview = async () => {
    console.log("startVideoPreview", selectedVideoDeviceId);
    if (selectedVideoDeviceId && videoElementRef.current) {
      setVideoReady(true);
      await meetingManager?.meetingSession?.audioVideo.startVideoInput(selectedVideoDeviceId);
      meetingManager.audioVideo?.startVideoPreviewForVideoInput(videoElementRef.current);
 
    } else if (videoElementRef.current) {
      setVideoReady(true);
      await meetingManager?.meetingSession?.audioVideo.startVideoInput(selectedVideoDeviceId);
      meetingManager.audioVideo?.startVideoPreviewForVideoInput(videoElementRef.current);
  
    }
  };
 
  const stopVideoPreview = async () => {
    if (videoElementRef.current) {
      meetingManager.audioVideo?.stopVideoPreviewForVideoInput(videoElementRef.current);
    }
  };

  const handleResize = () => {
    setIsChangingOrientation(true);
    setTimeout(() => {
      setIsChangingOrientation(false);
      console.log("handleResize isChangingOrientation", isChangingOrientation )
    }, 500);
  };
  
 console.log("videoEnabled", videoEnabled)
 
  return (
    <div className={` relative w-full h-full max-w-4xl self-center`}>
       <div className="absolute inset-0 top-20 z-100 flex justify-center items-center">
        {isChangingOrientation && (
          <div className=" ">
            <Spinner
              visible={true}
              width="30px"
              strokeColor={Colors.primaryButton}
            />
          </div>
        )}
      </div>
      <div className={`${isChangingOrientation ? 'invisible' : ''} `}>
        <div className={` ${isPortrait ? (isTablet ?  'aspect-w-10 aspect-h-9' : 'aspect-w-8 aspect-h-10') : ' aspect-w-16 aspect-h-9'} flex justify-center items-center bg-gray-meeting-background rounded-[10px]`}>
          {videoEnabled ? (
            <div >
               <div className="absolute inset-0 top-20 z-100 flex justify-center items-center">
                {isChangingOrientation && (
                  <div className=" ">
                    <Spinner
                      visible={true}
                      width="30px"
                      strokeColor={Colors.primaryButton}
                    />
                  </div>
                )}
              </div>
              <video
                ref={videoElementRef}
                id="video-preview"
                playsInline
                className={` ${isChangingOrientation ? 'invisible' : ''} h-full w-full object-cover rounded-[10px] `}
              />
            </div>
          ) : (
            <div className={`flex flex-col justify-center items-center w-full h-full pb-5}`}>
              <img
                className={`pt-4 ${isPhone ? 'w-8' : 'w-8'} self-center `}
                src={cameraOffPlaceholder}
                alt="No video placeholder"
              />
              <div className={`self-center ${isPhone ? 'text-[12px] pt-0' : 'text-lg pt-0'}`}>
                Your camera is turned off
              </div>
            </div>
          )}
 
        </div>
        <div className={`absolute left-1/2 transform -translate-x-1/2 ${isPhone ? 'bottom-2' : 'bottom-2'}  h-[74px] rounded-full flex justify-center items-center  bg-gray-controls-background bg-opacity-70 backdrop-blur `}>
          <MeetingControlButton
            buttonType="video"
            isActive={videoEnabled}
            onClick={() => {
              setVideoReady(true);
              toggleVideoEnabled(!videoEnabled)
            }
            }
            onSelect={changeVideoInput}
            deviceList={videoDevices}
            selectedDeviceId={selectedVideoDeviceId}
            isMobile={isPhone}
            showDeviceSelectionFor={["video"]}
            onArrowClick={isPhone ? handleOpenDeviceSelection : undefined}
            margin='mr-[3px] ml-[10px]'
          />
          <MeetingControlButton
            buttonType="microphone"
            isActive={microphoneEnabled}
            onClick={() => toggleMicrophoneEnabled(!microphoneEnabled)}
            onSelect={changeAudioInput}
            deviceList={audioInputDevices}
            selectedDeviceId={selectedAudioInputDeviceId}
            isMobile={isPhone}
            showDeviceSelectionFor={["microphone"]}
            onArrowClick={isPhone ? handleOpenDeviceSelection : undefined}
            margin='mr-[4px] ml-[4px]'
          />
          {!isPhone &&
            <MeetingControlButton
              buttonType="speaker"
              isActive={speakerEnabled}
              onClick={() => toggleSpeakerEnabled(!speakerEnabled)}
              onSelect={changeAudioOutput}
              deviceList={audioOutputDevices}
              selectedDeviceId={selectedAudioOutputDeviceId}
              isMobile={isPhone}
              onArrowClick={isPhone ? handleOpenDeviceSelection : undefined}
              margin='mr-[10px] ml-[4px]'
            />
            
          }
        </div>
      </div>
    </div>
  );
};
 
 
export default DeviceSelection;