import React, { ReactNode } from 'react';
import Navigation from './Navigation';

interface NavigationWrapperProps {
  children: ReactNode;
}

const NavigationWrapper: React.FC<NavigationWrapperProps> = ({ children }) => {
  return (
    <div className="relative flex w-full">
      <Navigation />
      {children}
    </div>
  );
};

export default NavigationWrapper;
