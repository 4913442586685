import { useState, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
 
export const useOrientationChange = (): boolean => {
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const isPhone = () => {
    return isMobile && !isTablet
  }

  useEffect(() => {
    const handleOrientationChanged = () => {
      const portrait =isPhone() ? (window.innerHeight > window.innerWidth) : (window.innerHeight > window.innerWidth);
      console.log("useOrientationChange: portrait ", portrait)
      setIsPortrait(portrait);
    };

    // Initial check and setup the event listener
 
    window.addEventListener('orientationchange', handleOrientationChanged);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChanged);
    };
  }, []); // Empty dependency array means this effect runs only once after the initial render

  return isPortrait;
};
