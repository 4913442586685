import { ApiGraphql } from './ApiGraphql';
import { listAttendances, listEventOccurrences, getContact, listConversationsInstructor, listConversationsStudent, getVideosByContact, listFollowers} from './GraphqlApi/queryApi';
import { convertDateTimeFormatInLocalTimeZone, convertToIST } from '../utils/formatters';
import moment from "moment-timezone";
import {getCurrentUser} from "@aws-amplify/auth"
import {AppConstant} from '../constants';
import { Contact, AttendanceRecord, Follower } from './interfaces';
import { handleAttendance} from "../graphql/mutations";
import { VideoShared } from '../helpers/interfaces'
import {updateFollower} from '../api/deepLinkAPI'

const getS3VideoList = async (userId: string): Promise<VideoShared[]> => {
  let requestData = {
    filter: {
      contactID: userId
    },
  };

return new Promise<VideoShared[]>((resolve, reject) => {
  ApiGraphql.apiQueryCall(
    getVideosByContact,
    requestData,
    (apiResponse, responseStatus) => {
      if (responseStatus === AppConstant.success) {
        const listData = (apiResponse as any).data?.getVideosByContact;
        console.log(" listData", listData)
        if (listData) {
          const videos = listData
            .filter((video: any) => 
              !video.contactIds || // Check if contactIds is empty
              video.contactIds.includes(userId) // or contains the userId
            )
            .map((video: any) => ({
              id: video?.id,
              videoName: video?.Name,
              videoUrl: video?.VideoUrl,
              thumbnailUrl: video?.ThumbnailUrl,
              createdAt: video?.createdAt,
              updatedAt: video?.updatedAt,
              contactIds: video?.ContactIds,
            }));
          console.log("Filtered videos", videos)
          resolve(videos);
        } else {
          resolve([]);
        }
      } else {
        reject('Failed to fetch video list');
      }
    }
  );
});
}


const getAttendanceList = async (userData: Contact, usersCountryCode: string): Promise<AttendanceRecord[]>  =>  {
  const currentDate = new Date();
  const userId = userData?.id
  const startTime = new Date().getTime();
  let requestData = {
    filter: {
      contactAttendancesContactID: {
        eq: userId,
      },
      // Status: {
      //   eq: "Booked",
      // },
    },
    limit: 20000,
  };

  return new Promise<AttendanceRecord[]>((resolve, reject) => {
  ApiGraphql.apiQueryCall(
    listAttendances,
    requestData,
    async (apiResponse, responseStatus) => {
      if (responseStatus == AppConstant.success) {

        let listData = (apiResponse as any).data?.listAttendances?.items;

        //console.log('getAttendanceList listData', listData);
        const filteredArray = listData.filter((event: { Event: { SubCategory: { BookingType: number; }; }; Status: string; }) => {
          // Check if BookingType is 2 in SubCategory
          const isBookingType2 = event.Event && event.Event.SubCategory.BookingType === 2;
          if (isBookingType2 === null || isBookingType2 === undefined ) {
            return false
          }
          // Check the status conditions
          return (isBookingType2 && (event.Status === "Booked" || event.Status === "Attended")) ||
            (!isBookingType2 && event.Status === "Booked");
        });

      var currentDateTime = new Date();
      let arr = [];
      for (let i in filteredArray) {
        if ((filteredArray[i].Event.SubCategory.BookingType === 3) || (filteredArray[i].Event.SubCategory.BookingType === 2)) {
          //console.log('getAttendanceList filteredArray [i]', filteredArray[i]);
          let EventOccurrences = filteredArray[i].Event.EventOccurrences.items
          //console.log('getAttendanceList EventOccurrences', EventOccurrences);
          if (EventOccurrences.length > 0) {
            for (let j in EventOccurrences) {
              let obj = {
                id: filteredArray[i].id,
                Contact: filteredArray[i].Contact,
                updatedAt: filteredArray[i].updatedAt,
                createdAt: filteredArray[i].createdAt,
                ModifiedDate: filteredArray[i].ModifiedDate,
                contactAttendancesContactID: filteredArray[i].contactAttendancesContactID,
                EventStartDate: filteredArray[i].EventStartDate,
                Status: filteredArray[i].Status,
                eventAttendancesId: filteredArray[i].eventAttendancesId,
                EventStartTime: EventOccurrences[j].StartTime,
                EventEndTime: EventOccurrences[j].EndTime,
                Event: {
                  id: filteredArray[i].Event.id,
                  Name: EventOccurrences[j].Name,
                  Booked: filteredArray[i].Event.Booked,
                  Capacity: filteredArray[i].Event.Capacity,
                  Description: filteredArray[i].Event.Description,
                  ParentId: filteredArray[i].Event.ParentId,
                  Price: filteredArray[i].Event.Price,
                  Duration: filteredArray[i].Event.Duration,
                  RawEventDate: filteredArray[i].Event.RawEventDate,
                  Recurrence: filteredArray[i].Event.Recurrence,
                  Remaining: filteredArray[i].Event.Remaining,
                  PlannerEventExtra: filteredArray[i].Event.PlannerEventExtra,
                  EventStartTime: EventOccurrences[j].StartTime,
                  EventEndTime: EventOccurrences[j].EndTime,
                  SubCategory: filteredArray[i].Event.SubCategory,
                  InstructorDetails: filteredArray[i].Event.InstructorDetails,
                  programEventsId: filteredArray[i].Event.programEventsId,
                  subCategoryEventsId: filteredArray[i].Event.subCategoryEventsId,
                  Instructor: filteredArray[i].Event.Instructor,
                  AssistantInstructorsInfo: filteredArray[i].Event.AssistantInstructorsInfo,
                  AssistantInstructorsId: filteredArray[i].Event.AssistantInstructorsId,
                  AssistantInstructors: filteredArray[i].Event.AssistantInstructors,
                }
              }

              const EndDate = new Date(convertToIST(obj?.EventEndTime, usersCountryCode));
              if (EndDate > currentDateTime) {
                arr.push(obj);
              }
            }
          } else {
            const EndDate = new Date(convertToIST(filteredArray[i]?.EventEndTime, usersCountryCode));
            if (EndDate > currentDateTime) {
              let obj= {
                id: filteredArray[i].id,
                Contact: filteredArray[i].Contact,
                updatedAt: filteredArray[i].updatedAt,
                createdAt: filteredArray[i].createdAt,
                ModifiedDate: filteredArray[i].ModifiedDate,
                contactAttendancesContactID: filteredArray[i].contactAttendancesContactID,
                EventStartDate: filteredArray[i].EventStartDate,
                Status: filteredArray[i].Status,
                eventAttendancesId: filteredArray[i].eventAttendancesId,
                EventStartTime: filteredArray[i].EventStartTime,
                EventEndTime:filteredArray[i].EventEndTime,
                Event: {
                  // Replace event id with parent id to use the same meeting ID 
                  id: filteredArray[i].Event.ParentId,
                  Name: filteredArray[i].Event.Name,
                  Booked: filteredArray[i].Event.Booked,
                  Capacity: filteredArray[i].Event.Capacity,
                  Description: filteredArray[i].Event.Description,
                  ParentId: filteredArray[i].Event.ParentId,
                  Price: filteredArray[i].Event.Price,
                  Duration: filteredArray[i].Event.Duration,
                  RawEventDate: filteredArray[i].Event.RawEventDate,
                  Recurrence: filteredArray[i].Event.Recurrence,
                  Remaining: filteredArray[i].Event.Remaining,
                  PlannerEventExtra: filteredArray[i].Event.PlannerEventExtra,
                  EventStartTime: filteredArray[i].Event.StartDate,
                  EventEndTime: filteredArray[i].Event.EndDate,
                  SubCategory: filteredArray[i].Event.SubCategory,
                  InstructorDetails: filteredArray[i].Event.InstructorDetails,
                  programEventsId: filteredArray[i].Event.programEventsId,
                  subCategoryEventsId: filteredArray[i].Event.subCategoryEventsId,
                  Instructor: filteredArray[i].Event.Instructor,
                  AssistantInstructorsInfo: filteredArray[i].Event.AssistantInstructorsInfo,
                  AssistantInstructorsId: filteredArray[i].Event.AssistantInstructorsId,
                  AssistantInstructors: filteredArray[i].Event.AssistantInstructors,
                }
              }
              arr.push(obj);
            }
          }
        } else {
          var EndDate = new Date(convertToIST(filteredArray[i]?.EventEndTime, usersCountryCode));
          if (EndDate > currentDateTime) {
            let obj =  filteredArray[i]
              console.log("obj.ParentId", obj["ParentId"])
              console.log("OBJ BEFORE", obj)
              if (obj.ParentId) {
                obj["id"] = obj.ParentId
              }
              console.log("OBJ AFTER", obj)
              arr.push(obj);
          }
        }
      }

      const date30DaysFromNow = new Date();
      date30DaysFromNow.setDate(currentDate.getDate() + 30);

      // Filter the array
      const filteredEvents = arr.filter(event => {
        const eventDate =  new Date(convertDateTimeFormatInLocalTimeZone(event?.EventEndTime).format('YYYY-MM-DD HH:mm:ss'));
        const currentLocalDate =  new Date(moment(currentDate).format('YYYY-MM-DD HH:mm:ss'));
        const localDate30DaysFromNow =  new Date(moment(date30DaysFromNow).format('YYYY-MM-DD HH:mm:ss'));
        return eventDate >= currentLocalDate && eventDate <= localDate30DaysFromNow;
      });

      //Sort events by date
      filteredEvents.sort((a, b) => {
        const dateA = new Date(convertToIST(a?.EventStartTime, usersCountryCode)).getTime();
        const dateB = new Date(convertToIST(b?.EventStartTime, usersCountryCode)).getTime();
        return dateA - dateB;
      });
      resolve(filteredEvents);
    } else {
        reject('Failed to fetch attendance list');
    }
  }
  )
}     

  );
};

const testFunction = (userData: Contact, usersCountryCode: string) => {  
  // console.log("NEW TEST getAttendanceListForInstructor")
  // const userId = userData?.id
  // const startTime = new Date().getTime();
  // let requestData = {
  //   filter: {
  //     contactAttendancesContactID: {
  //       eq: userId,
  //     },
  //     // Status: {
  //     //   eq: "Booked",
  //     // },
  //   },
  //   limit: 20000,
  // };

  // ApiGraphql.apiQueryCall(
  //   listAttendances,
  //   requestData,
  //   async (apiResponse, responseStatus) => {
  //     if (responseStatus == AppConstant.success) {

  //       let listData = (apiResponse as any).data?.listAttendances?.items;

  //     console.log("NEW TEST getAttendanceListForInstructor", listData)
  //     console.log("NEW TEST getAttendanceListForInstructor COUNT", listData.length)
  //     console.log('NEW TEST Execution time:',  (new Date().getTime() - startTime) , 'ms');
  //     }
  // });

}
 
const getAttendanceListForInstructor = async (userData: Contact, usersCountryCode: string): Promise<any> => {
  var currentDate = new Date();
  const date30DaysFromNow = new Date();
  date30DaysFromNow.setDate(currentDate.getDate() + 30);
  const userId = userData?.id
  let requestData = {
    filter: {
      ParentId: {
        eq: "" // Filter events where the id is also not a ParentId (broken events filtering)
      },
    },
    limit: 20000,
  };
  const startTime = new Date().getTime();
 
  return new Promise(async (resolve, reject) => {
  ApiGraphql.apiQueryCall(
    listEventOccurrences,
    requestData,
    async (apiResponse, responseStatus) => {
      if (responseStatus == AppConstant.success) {
      let arr = []
      let data = (apiResponse as any).data?.listEvents?.items;
      console.log("TEST getAttendanceListForInstructor", data)
      console.log("TEST getAttendanceListForInstructor COUNT", data.length)
      console.log('TEST Execution time:',  (new Date().getTime() - startTime) , 'ms');

      for (let i in data) {
        let instructorId = data[i].InstructorDetails ? data[i].InstructorDetails.id : null;
        const assistantIdFound = data[i].AssistantInstructorsInfo.some((info: { id: string; }) => info.id === userId)

        if ((instructorId && instructorId === userId) || assistantIdFound) {
          if ((data[i].SubCategory.BookingType === 3) || (data[i].SubCategory.BookingType === 2)){
            let EventOccurrences = data[i].EventOccurrences.items
            //console.log('EventOccurrences', EventOccurrences);
            if (EventOccurrences.length > 0) {
              for (let j in EventOccurrences) {
                let obj = {
                  id: data[i].id,
                  EventStartDate: data[i].EventStartDate,
                  EventEndDate: data[i].EventEndDate,
                  EventStartTime: EventOccurrences[j].EventStartDate,
                  EventEndTime: EventOccurrences[j].EventEndDate,
                  Event: {
                    id: data[i].id,
                    Name: EventOccurrences[j].Name,
                    Booked: data[i].Booked,
                    Capacity: data[i].Capacity,
                    Description: data[i].Description,
                    ParentId: data[i].ParentId,
                    Price: data[i].Price,
                    Duration: data[i].Duration,
                    Recurrence: data[i].Recurrence,
                    Remaining: data[i].Remaining,
                    PlannerEventExtra: data[i].PlannerEventExtra,
                    EventStartTime: EventOccurrences[j].StartTime,
                    EventEndTime: EventOccurrences[j].EndTime,
                    SubCategory: data[i].SubCategory,
                    InstructorDetails: data[i].InstructorDetails,
                    Instructor: data[i].Instructor,
                    AssistantInstructorsInfo: data[i].AssistantInstructorsInfo,
                    AssistantInstructors: data[i].AssistantInstructors,
                  }
                }

                const EndDate = new Date(convertToIST(obj?.EventEndTime, usersCountryCode));
                if (EndDate > currentDate) {
                  arr.push(obj);
                }
              }
            } else {
              const EndDate = new Date(convertToIST(data[i]?.EventEndTime, usersCountryCode));
              if (EndDate > currentDate) {
                let obj = {
                  id: data[i].id,
                  EventStartDate: data[i].EventStartDate,
                  EventEndDate: data[i].EventEndDate,
                  EventStartTime: data[i].EventStartDate,
                  EventEndTime: data[i].EventEndDate,
                  Event: {
                    id: data[i].id,
                    Name: data[i].Name,
                    Booked: data[i].Booked,
                    Capacity: data[i].Capacity,
                    Description: data[i].Description,
                    ParentId: data[i].ParentId,
                    Price: data[i].Price,
                    Duration: data[i].Duration,
                    Recurrence: data[i].Recurrence,
                    Remaining: data[i].Remaining,
                    PlannerEventExtra: data[i].EventEndDate,
                    EventStartTime: data[i].EventStartDate,
                    EventEndTime: data[i].EndTime,
                    SubCategory: data[i].SubCategory,
                    InstructorDetails: data[i].InstructorDetails,
                    Instructor: data[i].Instructor,
                    AssistantInstructorsInfo: data[i].AssistantInstructorsInfo,
                    AssistantInstructors: data[i].AssistantInstructors,
                  }
    
                }
                arr.push(obj);
              }
            }
          } else {
            let obj = {
              id: data[i].id,
              EventStartDate: data[i].EventStartDate,
              EventEndDate: data[i].EventEndDate,
              EventStartTime: data[i].EventStartDate,
              EventEndTime: data[i].EventEndDate,
              Event: {
                id: data[i].id,
                Name: data[i].Name,
                Booked: data[i].Booked,
                Capacity: data[i].Capacity,
                Description: data[i].Description,
                ParentId: data[i].ParentId,
                Price: data[i].Price,
                Duration: data[i].Duration,
                Recurrence: data[i].Recurrence,
                Remaining: data[i].Remaining,
                PlannerEventExtra: data[i].EventEndDate,
                EventStartTime: data[i].EventStartDate,
                EventEndTime: data[i].EndTime,
                SubCategory: data[i].SubCategory,
                InstructorDetails: data[i].InstructorDetails,
                Instructor: data[i].Instructor,
                AssistantInstructorsInfo: data[i].AssistantInstructorsInfo,
                AssistantInstructors: data[i].AssistantInstructors,
              }

            }

            const EndDate = new Date(convertToIST(obj?.EventEndTime, usersCountryCode));
            if (EndDate > currentDate) {
              arr.push(obj);
            }
          }
        }
      }

      // Filter the array
      const filteredEvents = arr.filter(event => {
        const eventDate =  new Date(convertDateTimeFormatInLocalTimeZone(event?.EventEndTime).format('YYYY-MM-DD HH:mm:ss'));
        const currentLocalDate =  new Date(moment(currentDate).format('YYYY-MM-DD HH:mm:ss'));
        const localDate30DaysFromNow =  new Date(moment(date30DaysFromNow).format('YYYY-MM-DD HH:mm:ss'));
        return eventDate >= currentLocalDate && eventDate <= localDate30DaysFromNow;
      });
 
      //Sort events by date
      filteredEvents.sort((a, b) => {
        const dateA = new Date(convertToIST(a?.EventStartTime, usersCountryCode)).getTime();
        const dateB = new Date(convertToIST(b?.EventStartTime, usersCountryCode)).getTime();
        return dateA - dateB;
      });

      resolve(filteredEvents);
    } else {
      reject('Failed to fetch attendance list');
  }
}
  ) 
    }
  );
};

const getUserData = async (): Promise<Contact | undefined> => {
  try {
    const user = await getCurrentUser();
    let requestCategoryData = {
      contactID: user.userId,
    };
    console.log('getUserData contactID',user.userId);
    const userInfo = await new Promise<Contact | undefined>((resolve, reject) => {
      ApiGraphql.apiQueryCall(
        getContact,
        requestCategoryData,
        (apiResponse, responseStatus) => {
          console.log('getUserData apiResponse',apiResponse);
          console.log('getUserData responseStatus',responseStatus);
          if (responseStatus == AppConstant.success) {
            let userInfo = (apiResponse as any).data?.getContact;
            console.log("getUserData userInfo", userInfo);
            resolve(userInfo); // Resolve the Promise with userInfo
          } else {
            reject("Failed to fetch user info"); // Reject the Promise on failure
          }
        }
      );
    });

    return userInfo; // Return the fetched userInfo
  } catch (error) {
    console.error(error);
    return undefined; // Return undefined in case of error
  }
};

const updateAttendeeData = async (userId: String | undefined, meetingId: String | undefined, eventStartTime: String | undefined, eventEndTime: String | undefined) => {
  const input = {
    contactId: userId,
    startTime: eventStartTime,
    endTime: eventEndTime,
    eventId: meetingId,
    operation: 'meetingAttended',
    status: 'Attended'
  };
  const variables = { input };

  console.log("updateAttendeeData input", input);
    const result = await new Promise<Object | undefined>((resolve, reject) => {
      ApiGraphql.apiQueryCall(
        handleAttendance,
        variables,
        (apiResponse, responseStatus) => {
          if (responseStatus == AppConstant.success) {
            resolve(variables); // Resolve the Promise with userInfo
          } else {
            reject("Failed to update Attendee Data"); // Reject the Promise on failure
          }

          console.log("updateAttendeeData apiResponse", apiResponse)
        }
      );
    });
}

const getConversationListForStudent = async (userData: Contact, usersCountryCode: string): Promise<AttendanceRecord[]>  =>  {
   
  const userId = userData?.id
 
 
 // Calculate the date 7 days before the current date
 var sevenDaysBefore = moment().subtract(7, 'days').format("YYYY-MM-DD");

 // Calculate the date 7 days after the current date
 var sevenDaysAfter = moment().add(7, 'days').format("YYYY-MM-DD");
    
   let requestData = {
     filter: {
       contactAttendancesContactID: {
         eq: userId,
       },
       EventStartDate: {
         between: [sevenDaysBefore, sevenDaysAfter],
       },
     },
     limit: 20000, 
   };

 
  return new Promise<AttendanceRecord[]>((resolve, reject) => {
  ApiGraphql.apiQueryCall(
    listConversationsStudent,
    requestData,
    async (apiResponse, responseStatus) => {
      if (responseStatus == AppConstant.success) {

        let listData = (apiResponse as any).data?.listAttendances?.items;
        console.log('getConversationListForStudent ', listData);

          resolve(listData);
        } else {
            reject('Failed to fetch attendance list');
        }
      }
      )
    }     

  );
};

const getConversationListForInstructors = async (userData: Contact, usersCountryCode: string): Promise<AttendanceRecord[]>  =>  {
   
  const userId = userData?.id

  // Calculate the date 7 days before the current date
 var sevenDaysBefore = moment().subtract(7, 'days').format("YYYY-MM-DD");

 // Calculate the date 7 days after the current date
 var sevenDaysAfter = moment().add(7, 'days').format("YYYY-MM-DD");
    
   let requestData = {
     filter: {

      //  contactAttendancesContactID: {
      //    eq: userId,
      //  },
       EventStartDate: {
         between: [sevenDaysBefore, sevenDaysAfter],
       },
     },
     limit: 20000, 
   };

 
  return new Promise<AttendanceRecord[]>((resolve, reject) => {
  ApiGraphql.apiQueryCall(
    listConversationsInstructor,
    requestData,
    async (apiResponse, responseStatus) => {
      if (responseStatus == AppConstant.success) {

        let listData = (apiResponse as any).data?.listEvents?.items;;
        console.log('getConversationListForInstructor ', listData);
          resolve(listData);
        } else {
            reject('Failed to fetch attendance list');
        }
      }
      )
    }     

  );
};

const getFollowersForInviter = async (inviterID: String): Promise<Follower[]>  =>  {
   
   let requestData = {
     filter: {
      inviterID: {
         eq: inviterID,
       },
     },
     limit: 20000, 
   };

 
  return new Promise<Follower[]>((resolve, reject) => {
  ApiGraphql.apiQueryCall(
    listFollowers,
    requestData,
    async (apiResponse, responseStatus) => {
      if (responseStatus == AppConstant.success) {
        console.log('getFollowersForInviter apiResponse', apiResponse);
        let listData = (apiResponse as any).data?.listFollowers?.items;
        console.log('getFollowersForInviter listData', listData);
          resolve(listData);
        } else {
          console.log('getFollowersForInviter apiResponse', apiResponse);
          console.log('getFollowersForInviter responseStatus', responseStatus);
            reject('Failed to fetch followers list');
        }
      }
      )
    }     

  );
};

const getFollower = async (inviterID: String, endpoint: String): Promise<Follower[]>  =>  {
   
  let requestData = {
    filter: {
     inviterID: {
        eq: inviterID,
      },
      endpoint: {
        eq: endpoint,
      },
    },
    limit: 20000, 
  };


 return new Promise<Follower[]>((resolve, reject) => {
 ApiGraphql.apiQueryCall(
   listFollowers,
   requestData,
   async (apiResponse, responseStatus) => {
     if (responseStatus == AppConstant.success) {
       console.log('getFollowersForCoach apiResponse', apiResponse);
       let listData = (apiResponse as any).data?.listFollowers?.items;;
       console.log('getFollowersForCoach listData', listData);
         resolve(listData);
       } else {
           reject('Failed to fetch followers list');
       }
     }
     )
   }     

 );
};



const updateFollowerData = async (
  inviterID: string,
  followerID: string,
  followerName: string,
  followerImage: string,
  status: string,
  endpoint: string,
) => {

  const result = updateFollower(inviterID, followerID, followerImage, followerName, endpoint)
  console.log("updateFollowerData result", result)
};



export default {
  getAttendanceList,
  getAttendanceListForInstructor,
  getUserData,
  updateAttendeeData,
  getConversationListForStudent,
  getConversationListForInstructors,
  getS3VideoList,
  getFollower,
  getFollowersForInviter,
  updateFollowerData
};
