import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define an interface for the context value
interface PopupContextValue {
  activePopup: string | null;
  setActivePopup: (id: string | null) => void;
}

// Default values for the context when it's initialized
const defaultContextValue: PopupContextValue = {
  activePopup: null,
  setActivePopup: () => {},  // No-op function placeholder
};

// Create the context with the default value
const PopupContext = createContext<PopupContextValue>(defaultContextValue);

// Custom hook to use the context
export const usePopup = () => useContext(PopupContext);

// Define interface for children prop
interface PopupProviderProps {
  children: ReactNode;
}

// PopupProvider component
export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [activePopup, setActivePopup] = useState<string | null>(null);

  return (
    <PopupContext.Provider value={{ activePopup, setActivePopup }}>
      {children}
    </PopupContext.Provider>
  );
};
