import styled from 'styled-components';

interface Props {
  isPortrait: boolean;
}

export const StyledLayout = styled.main<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%; 

  ${({ isPortrait }) => {
    if (isPortrait) {
      return `
      flex-direction: column;

      .class-info-top {
        display: block;  
      } 

      .class-info-side {
        display: none;  
      } 

      `;
    }
    return `
      flex-direction: row;

      .class-info-top {
        display: none;  
      } 

      .class-info-side {
        display: block;  
      } 
  `;
  }}
`;
