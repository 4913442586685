import React, { useState, KeyboardEvent } from 'react';
import logo from '../assets/images/loginLogo.png'
import errorIcon from "../assets/images/error_icon.png"
import { RotatingLines as Spinner } from 'react-loader-spinner'
import Colors from "../constants/colors"

interface CodeFormProps {
  setCredentials: React.Dispatch<React.SetStateAction<{
    phone: string;
    email: string;
    inputTypeNumber: boolean;
    countryCode: string;
    otp: string;
  }>>;
  onCodeSubmit: () => void;
  onCodeCancel: () => void;
  onResendCode: () => void;
  credentials: { 
    phone: string;
    email: string;
    inputTypeNumber: boolean;
    countryCode: string;
    otp: string;
  };
  error: {
    isError: boolean;
    message: string;
  };
  setError: React.Dispatch<React.SetStateAction<{
    isError: boolean;
    message: string;
  }>>;
  isLoading: boolean;
}

const CodeForm: React.FC<CodeFormProps> = ({ credentials, error, isLoading, setError, setCredentials, onCodeSubmit, onCodeCancel, onResendCode }) => {
  const [code, setCode] = useState('');
  const [isValid, setIsValid] = useState(false);
 
  const handleCodeSubmit = (e: React.FormEvent) => {
    e.preventDefault();  
    onCodeSubmit(); 
  };

  const handleCancel = (e: React.FormEvent) => {
    e.preventDefault();  
    onCodeCancel()
  };

  const handleResend = (e: React.FormEvent) => {
    e.preventDefault();  
    onResendCode()
    setCode("")
    setIsValid(false)
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const code = e.target.value
      setCode(code);
      setCredentials(prevState => ({...prevState, otp: code}));
      setIsValid(code.length === 6)
      setError({isError: false, message:""}) 
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();  
      onCodeSubmit(); 
    }
  };

  return (
      <div className="w-full flex flex-col items-center mx-[60px] my-[32px]">
        <img src={logo} alt="logo" className="w-[185px] mb-[52px]" />
        <form className="w-full">
          <div className="mb-4 flex flex-col">
            <label className="block text-field-label-text text-center text-sm font" htmlFor="email">
              {credentials.inputTypeNumber ? `Enter the code we sent to +${credentials.phone}` : `Enter the code we sent to ${credentials.email}`}
            </label>
      
            <input
              className={`border border-black-border w-full mx-auto my-[8px] px-[24px] h-[40px] appearance-none ${error.isError ? 'border-error-border' : ''} rounded-full text-black-text leading-tight text-[16px]`}
              id="code"
              type="tel"
              placeholder="Your code"
              value={code}
              onChange={(e) => {
                handleCodeChange(e)
              }}
              onKeyDown={handleKeyDown}
              autoFocus
              />
               
               { isLoading ? (
                <div className="flex items-center justify-center">
                  <Spinner 
                    visible={isLoading}
                    width='30px'
                    strokeColor= {Colors.primaryButton}
                    
                
                  />
                </div>
              ) : (
                <div> 
                {error.isError && 
                  <div className="flex flex-row items-center justify-center"> 
                      <img src={errorIcon} alt="error" className="w-[16px] mr-[4px] my-[8px]" />
                      <p className="text-red-text text-[14px] ">{error.message}</p>
                  </div>
                  }
                </div>
              )}
            <div className="flex items-center justify-center">
              
              <div
                className="inline-block align-baseline font-bold text-[14px] text-blue-text hover:blue-text"
                onClick={handleResend}
              >
                {'Send Again'}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <button
              className={`w-full rounded-full bg-white-button border border-gray-border text-primary-text font-bold text-[20px] py-[8px] px-[24px] mt-[52px] mr-[9px] h-[46px] rounded`}
              type="submit"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className={`w-full rounded-full ${isValid ? 'bg-primary-button' : 'bg-inactive-button'}  text-primary-text font-bold text-[20px] py-[8px] px-[24px] mt-[52px] h-[46px] rounded focus:outline-none focus:shadow-outline`}
              type="submit"
              disabled={!isValid}
              onClick={handleCodeSubmit}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    
  );
};

export default CodeForm;



