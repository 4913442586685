import React, { useState, useEffect, useRef } from 'react';
import { usePopup } from '../contexts/popupContext'; // Ensure the path is correct
import { MeetingManager, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { useMeetingState } from './chime/providers/MeetingStateProvider';

// Import icons
import videoOnIcon from '../assets/images/camera_on.png';
import videoOffIcon from '../assets/images/camera_off.png';
import micOnIcon from '../assets/images/mic_on.png';
import micOffIcon from '../assets/images/mic_off.png';
import soundOnIcon from '../assets/images/sound_on.png';
import soundOffIcon from '../assets/images/sound_off.png';
import arrowDownIcon from '../assets/images/arrow_down.png';
import arrowUpIcon from '../assets/images/arrow_up.png';
import attandeesIconOn from '../assets/images/person_on.png';
import attandeesIconOff from '../assets/images/person_off.png';
import shareIcon from '../assets/images/share.png';
import messagesIconOn from '../assets/images/message_on.png';
import messagesIconOff from '../assets/images/message_off.png';
import messagesIconBadge from '../assets/images/message_badge.png';
import { MeetingMode } from './chime/types';
import { useUserActivityMonitor } from '../hooks/useUserActivityMonitor';
import {ControlButton}from '../helpers/types'
 

interface ControlButtonProps {
  buttonType: ControlButton;
  isActive: boolean;
  onClick: () => void;
  onSelect?: (deviceId: string, meetingManager: MeetingManager | undefined) => void;
  onArrowClick?: (buttonType: ControlButton) => void;
  deviceList: MediaDeviceInfo[];
  selectedDeviceId: string;
  isMobile: boolean;
  hasBadge?: boolean;
  margin?: string;
  showDeviceSelectionFor?: ControlButton[];
}

const iconMap: { [key in ControlButton]: { on: string, off: string, badge: string } } = {
  video: { on: videoOnIcon, off: videoOffIcon, badge: "" },
  microphone: { on: micOnIcon, off: micOffIcon, badge: "" },
  speaker: { on: soundOnIcon, off: soundOffIcon, badge: "" },
  attendees: { on: attandeesIconOn, off: attandeesIconOff, badge: "" },
  share: { on: shareIcon, off: shareIcon, badge: "" },
  messages: { on: messagesIconOn, off: messagesIconOff, badge: messagesIconBadge },
};

const MeetingControlButton: React.FC<ControlButtonProps> = ({
  buttonType,
  isActive,
  onClick,
  onSelect,
  onArrowClick,
  deviceList,
  selectedDeviceId,
  isMobile,
  hasBadge = false,
  margin,
  showDeviceSelectionFor = [], 
}) => {
  const { activePopup, setActivePopup } = usePopup();
  const popupRef = useRef<HTMLDivElement>(null);
  const meetingManager = useMeetingManager();
  const { meetingMode } = useMeetingState();
  const buttonsWithArrows: ControlButton[] = ['video', 'microphone', 'speaker'];

  // Toggle popup function using context
  const togglePopup = () => {
    setActivePopup(activePopup === buttonType ? null : buttonType);
  };

  const {
    selectedVideoDeviceId,
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    setVideoDevices,
    setAudioInputDevices,
    setAudioOutputDevices,
    setSelectedVideoDeviceId,
    setSelectedAudioInputDeviceId,
    setSelectedAudioOutputDeviceId,
    fetchDevicesForType,
  } = useMeetingState();

  useEffect(() => {
    // Close the popup when clicking outside
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setActivePopup(null);  // Close popup via context
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Fetch devices when component mounts
  useEffect(() => {
   
  }, []);

  const showArrow = () => {
    return buttonsWithArrows.includes(buttonType);
  }

  const getIcon = () => {
    if (hasBadge)  {
      return  iconMap[buttonType].badge
    } else  {
      return isActive ? iconMap[buttonType].on : iconMap[buttonType].off
    }
  }

  const getMargin = () => {
    let marginNew = 'mr-[3px] ml-[3px]'
    
    if (margin) {
      marginNew= margin
    }
    return  marginNew
  }


  const getTransformForPopup= () => {
    let position = 'left-1/2 transform -translate-x-1/2'
    if (buttonType === "video") {
      console.log("meetingMode === MeetingMode.Spectator", meetingMode === MeetingMode.Spectator) 
      position = 'left-1/2 transform ' +  (meetingMode === MeetingMode.Spectator ? '-translate-x-[50px]' : '-translate-x-[120px]')
    }

    return position
  }

  return (
    <div className={`flex justify-center items-center relative bg-gray-controls-group-background bg-opacity-60 backdrop-blur rounded-full  ${getMargin()}`}>
      <button
        onClick={() => {
          onClick();
        }}
        className={`w-[50px] h-[50px] text-white rounded-full flex justify-center items-center`}>
        <img
          className={``}
          src={getIcon()}
          alt={`${buttonType} control`}
        />
      </button>
      {((!isMobile && showArrow()) || showDeviceSelectionFor.includes(buttonType)) &&  deviceList.length > 0 && (
        <button
          onMouseDown={(event) => {
            event.stopPropagation();
            //Update the device list
            fetchDevicesForType(buttonType, meetingManager)
            if (onArrowClick) {
              onArrowClick(buttonType)            
            } else {
              togglePopup(); // Toggle popup visibility on arrow click
            }
          }}
          className="text-white pr-[6px] pl-[6px] rounded-full flex justify-center items-center">
          <img
            className={`w-[20px]`}
            src={activePopup === buttonType ? arrowUpIcon : arrowDownIcon}
            alt="Toggle Popup"
          />
        </button>
      )}
      {activePopup === buttonType && (
        <div ref={popupRef} className={`absolute p-[10px] ${getTransformForPopup()} bottom-16 bg-gray-controls-background bg-opacity-100 rounded-[10px] flex flex-col justify-start items-center text-white`}>
          {deviceList.map(device => (
            <button
              className={`${isMobile ? 'w-[350px]' : 'w-[500px]'} h-[50px] text-start px-[20px] rounded-[10px] ${selectedDeviceId === device.deviceId ? 'bg-meeting-button' : 'bg-transparent'}`}
              key={device.deviceId}
              onClick={() => {
                if (onSelect) {
                  onSelect(device.deviceId, meetingManager);
                }
                setActivePopup(null); // Close popup when a device is selected
              }}>
              {device.label || "Unnamed Device"}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default MeetingControlButton;
