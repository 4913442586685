// hooks/useJoinMeeting.ts
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AudioVideoContext,
  DeviceLabels,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import { createGetAttendeeCallback, createMeetingAndAttendee } from '../api/meetingAPI';
import { DefaultBrowserBehavior, MeetingSessionConfiguration, VideoCodecCapability, DefaultMeetingSession, DefaultDeviceController, ConsoleLogger, LogLevel, } from 'amazon-chime-sdk-js';
import { getErrorContext } from '../components/chime/providers/ErrorProvider';
import routes from '../constants/routes';
import Card from '../components/chime/components/Card';
import Spinner from '../components/chime/components/icons/Spinner';
import DevicePermissionPrompt from '../components/chime/containers/DevicePermissionPrompt';
import RegionSelection from '../components/chime/containers/MeetingForm/RegionSelection';
import { useMeetingState } from '../components/chime/providers/MeetingStateProvider';
import { MeetingMode, VideoFiltersCpuUtilization } from '../components/chime/types';
import { MeetingManagerJoinOptions } from 'amazon-chime-sdk-component-library-react/lib/providers/MeetingProvider/types';
import meetingConfig from '../utils/meeting/meetingConfig';
import Api from '../helpers/customAPIs';
import { AttendanceRecord } from '../helpers/interfaces';
 

export const useJoinMeeting = () => {
  const meetingManager = useMeetingManager();
  const {
    region,
    meetingId,
    localUserName,
    meetingMode,
    enableSimulcast,
    priorityBasedPolicy,
    keepLastFrameWhenPaused,
    isWebAudioEnabled,
    videoTransformCpuUtilization: videoTransformCpuUtilization,
    setJoinInfo,
    isEchoReductionEnabled,
    meetingSession,
    setMeetingSession,
    toggleEchoReduction,
    toggleWebAudio,
    toggleSimulcast,
    togglePriorityBasedPolicy,
    toggleKeepLastFrameWhenPaused,
    setMeetingMode,
    setMeetingId,
    setLocalUserName,
    setRegion,
    setCpuUtilization,
    skipDeviceSelection,
    toggleMeetingJoinDeviceSelection,
  } = useMeetingState();
  const [meetingErr, setMeetingErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [isMeetingLoading, setIsMeetingLoading] = useState(false);
  const { errorMessage, updateErrorMessage } = useContext(getErrorContext());
  const navigate = useNavigate();
  const [meetingID, setMeetingID] = useState('');


  const joinMeeting = async (userId: string, meetingId: string, attendeeId: string, attendeeName: string, meetingMode: MeetingMode, classItem: AttendanceRecord) => {
    setIsMeetingLoading(true);
    setMeetingMode(meetingMode)
    setMeetingId(meetingId)
    setLocalUserName(attendeeName)
    
    meetingManager.getAttendee = createGetAttendeeCallback(meetingId);

    if (!meetingId || !attendeeName) {
      if (!attendeeName) {
        setNameErr(true);
      }

      if (!meetingId) {
        setMeetingErr(true);
      }

      return;
    }

    try {
      const { JoinInfo } = await createMeetingAndAttendee(meetingId, attendeeId, region, isEchoReductionEnabled);
      
      setJoinInfo(JoinInfo);

      const meetingSessionConfiguration = new MeetingSessionConfiguration(JoinInfo?.Meeting, JoinInfo?.Attendee);

      // You would typically create a MeetingSession like this
      const logger = new ConsoleLogger('MyLogger', LogLevel.ERROR); // LogLevel can be adjusted based on your needs
      // Create the DefaultDeviceController with the logger
      const deviceController = new DefaultDeviceController(logger);
      // Now create the MeetingSession with the logger and device controller
      const meetingSession = new DefaultMeetingSession(
        meetingSessionConfiguration,
        logger,
        deviceController
      );

      // Set video codec preferences on the MeetingSession's audioVideo controller
      const codecPreferences = [VideoCodecCapability.vp9()];
      const session = meetingSession?.audioVideo
      
      if (session) {
        session.chooseVideoInputQuality(1280, 720, 30);
        session.setVideoMaxBandwidthKbps(1400);
        session.setVideoCodecSendPreferences?.(codecPreferences);
        session.setContentShareVideoCodecPreferences?.(codecPreferences);
      } else {
        console.error('audioVideo is undefined');
      }

      setMeetingSession(meetingSession)

      if (
        meetingConfig.postLogger &&
        meetingSessionConfiguration.meetingId &&
        meetingSessionConfiguration.credentials &&
        meetingSessionConfiguration.credentials.attendeeId

      ) {
        const existingMetadata = meetingConfig.postLogger.metadata;
        meetingConfig.postLogger.metadata = {
          ...existingMetadata,
          meetingId: meetingSessionConfiguration.meetingId,
          attendeeId: meetingSessionConfiguration.credentials.attendeeId,
          meetingMode: meetingMode.toString(),
          attendeeName: attendeeName,
        };
      }

      setRegion(JoinInfo.Meeting.MediaRegion);
      meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = enableSimulcast;
      if (priorityBasedPolicy) {
        meetingSessionConfiguration.videoDownlinkBandwidthPolicy = priorityBasedPolicy;
      }
      meetingSessionConfiguration.keepLastFrameWhenPaused = keepLastFrameWhenPaused;
      const options: MeetingManagerJoinOptions = {
        deviceLabels: meetingMode === MeetingMode.Spectator ? DeviceLabels.AudioAndVideo : DeviceLabels.AudioAndVideo,
        enableWebAudio: isWebAudioEnabled,
        skipDeviceSelection,
      };

      if (typeof window !== 'undefined' && typeof window.global === 'undefined') {
        window.global = window;
      }
      
      await meetingManager.join(meetingSessionConfiguration, options);

      const av = meetingManager.audioVideo
      const vp9CodecCapability = VideoCodecCapability.vp9();
 
      if (av) {
        av.chooseVideoInputQuality(1280, 720, 30);
        av.setVideoMaxBandwidthKbps(1400);
        av.setVideoCodecSendPreferences?.([vp9CodecCapability]);
        av.setContentShareVideoCodecPreferences?.([vp9CodecCapability]);
      }

      setIsMeetingLoading(false);

    } catch (error) {
      console.log(" error", error)
      console.log(" error", error)
      updateErrorMessage((error as Error).message);
      setIsMeetingLoading(false);
    }

    // Updates attendance table
    await Api.updateAttendeeData(userId, meetingId,  classItem?.EventStartTime,  classItem?.EventEndTime)

  };

  return { joinMeeting, isMeetingLoading, errorMessage, meetingID, updateErrorMessage };
};
