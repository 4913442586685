
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext';

const NotFound = () => {
  return (
    <div >
      <div >
        <h2>Sorry... nothing here.</h2>
       
        <div >
          <button
          >
            Go home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
