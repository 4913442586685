const AppConstant = {
  getStarted: "Get Started",
  onBoarding1Content: "Find your class and start your journey",
  onBoarding1Desc:
    "Find your class and start your journey now Find your class and start your journey",
  onBoarding2Content: "Learn new skills with your family",
  onBoarding2Desc:
    "Find your class and start your journey now Find your class and start your journey",
  onBoarding3Content: "Action is the key to all success",
  onBoarding3Desc:
    "Find your class and start your journey now Find your class and start your journey",
  mobileNumber: "Mobile Number",
  emailLinkText: "or use email instead",
  mobileLinkText: "or use mobile number instead",
  continue: "Continue",
  emailAddress: "Email Address",
  signIn: "Sign in",
  otpFieldLabel: "Enter the code we sent over SMS to",
  enterCodeSend: "Enter the code we sent to",
  didntReceiveTheSms: "Didn’t receive the SMS? ",
  didntReceiveTheEmail: "Didn’t receive the Email? ",
  sendAgain: "Send Again",
  whatCallYou: "What do we call you?",
  firstName: "First Name",
  lastName: "Last Name",
  tellUsAbout: "Tell us about yourself!",
  howOldAreYou: "How old are you ?",
  whatsYourGoal: "What’s your goal?",
  selectAllThatApply: "*Select all that apply",
  gainWeight: "Gain Weight",
  loseWeight: "Lose Weight",
  getFitter: "Get Fitter",
  gainMoreFlexibity: "Gain More Flexibility",
  learnNewSkills: "Learn New Skills",
  whatTypeOfProgramInterest: "What type of programs interests you the most?",
  dance: "Dance",
  yoga: "Yoga",
  fitness: "Fitness",
  martialArts: "Martial Arts",
  other: "Other",
  typeYourInterest: "Type your interest",
  whatPhysicalActivityLevel: "What is your physical activity level?",
  rookie: "Rookie",
  beginner: "Beginner",
  intermediate: "Intermediate",
  advanced: "Advanced",
  howOftenWouldYouLikeToWorkoutWachWeek:
    "How often would you like to workout each week?",
  oneDay: "1 day",
  twoToThreeDay: "2 to 3 days",
  fourToFiveDay: "4 to 5 days",
  sixToSevenDay: "6 to 7 days",
  preferedWorkout: "When do you prefer to do your workouts?",
  morning: "Morning",
  afternoon: "Afternoon",
  evening: "Evening",
  next: "Next",
  done: "Done",
  hello: `Hello `,
  upcomingClass: "Upcoming Classes",
  topPicksForYou: "Top Picks for you",
  whiteBeltClass: "White belt class",
  editProfile: "Edit Profile",
  achievement: "Achievements",
  inbox: "Inbox",
  interestAndGoal: "Interests and Goals",
  membership: "Membership",
  signOut: "Sign Out",
  age: "Age",
  phoneNumber: "Phone Number",
  welcomeTo: "Welcome to",
  step: "STEP",
  confirm: "Confirm",
  confirmNewValueToUpdate: "Confirm the new value to update",
  takePhoto: "Take Photo",
  chooseFromLibrary: "Choose from library",
  cancel: "Cancel",
  save: "Save",
  firstNameError: "First name required",
  lastNameError: "Last name required",
  genderError: "Please select gender",
  atLeastOneFieldRequired: "Atleast one field required",
  required: "Required",
  pleaseEnterEmailAddress: "Please enter emaill address",
  invalidEmailAddress: "Please enter valid email address",
  pleaseEnterPhoneNumber: "Please enter phone number",
  invalidPhoneNumber: "Phone number should be 10 digits",
  invalidOtpError: "Entered otp is incorrect",
  expireOtpError: "Otp is expired, please send again",
  incorrectOTPError: "Incorrect OTP",
  phonenumberFormatError: "Phone number format is incorrect.",
  somethingWentWrong: "Something went wrong.",
  more: "More",
  readMore: "Read More",
  confirmBooking: "Confirm Booking",
  bookingShedule: "Booking Schedule",
  success: "Success",
  error: "Error",
  yourShedule: "Your Schedule",
  nameKey: "name",
  genderKey: "gender",
  ageKey: "age",
  interestsKey: "interests",
  goalKey: "goal",
  levelkey: "level",
  trainPeriodKey: "train period",
  trainTimeKey: "train time",
  searchForCountry: "Search for country",
  search: "Search",
  invalidPhoneNumberFormat: "Invalid phone number format.",
  UserNotFoundException: "UserNotFoundException",
  UsernameExistsException: "UsernameExistsException",
  NotAuthorizedException: "NotAuthorizedException",
  UserDoesNotExistException: "User does not exist",
  instructors: "Instructors",
  goodMorning: "Good morning.",
  goodAfternoon: "Good afternoon.",
  goodEve: "Good evening.",
  joinNow: "Join Now",
  subscribe: "Subscribe",
  premiumMemberDetailsTxt: `In order to complete this booking you are required to pay the following fees:`,
  feeName: "Fee Name",
  amountToPay: "Amount to Pay Now",
  dueNow: "Due Now",
  monthly: "Monthly",
  payMonthly: "Pay monthly, cancel any time",
  payYearly: "Pay yearly, cancel any time",
  yearly: "Yearly",
  payFull: "Pay for a full year and Save 10%",
  payNow: "Pay Now",
  viewAll: "View All",
  sendMessage: "Send a Message",
  congrates: "Congratulations",
  confirmUpdate: "Confirm Update",
  yourInstructors: "Your Instructors",
  markAsUnread: "Mark as Unread",
  archive: "Archive",
  takePhotoVideo: "Take photo or video",
  chooseExisting: "Choose existing",
  active: "Active",
  expired: "Expired",
  cancelMembership: "Cancel Membership",
  changeMembership: "Change Membership",
  eventIsFullNow: "This Event is Full. Please Try Another",
  noNetworkConnection: "No network connection",
  confirmCancellation: "Confirm Cancellation",
  notNow: "Not Now",
  noInternetConnection: "No Internet Connection",
  buyNow: "Buy Now",
  bookNow: "Book Now",
  viewSchedule: "View Schedule",
  successfullyBooked: "Successfully Booked",
  teach: "Teach",
  video: "Video",
  privacyPolicy: "Privacy Policy",
  termsofService: "Terms of Service",
  pleaseEnterVerifedEmail:
    "Email is incorrect, please enter verified email address.",
  emailUpdatedSuccessfully: "Email address updated successfully.",
  phoneUpdatedSuccessfully: "Phone number updated successfully.",
  retry: 'Retry',
  checkYourInternet: 'No internet connection.',
  videoOn: 'Video is on',
  videoOff: 'Video is off',
  micOn: 'Mic is on',
  micOff: 'Mic is off',
  endSession: 'End Session'
};

const ApIConstant = {
  UPDATE_USER_INFO_DATA: "UPDATE_USER_INFO_DATA",
  UPDATE_USER_INFO_DATA_ERROR: "UPDATE_USER_INFO_DATA_ERROR",
  CLEAR_USER_INFO_DATA: "CLEAR_USER_INFO_DATA",
  UPDATE_INPUT_OTP: "UPDATE_INPUT_OTP",
  CLEAR_OTP_DATA: "CLEAR_OTP_DATA",
  SAVE_PROMOTIONAL_DATA_DYNAMO_DB: "SAVE_PROMOTIONAL_DATA_DYNAMO_DB",
  SAVE_WORKOUT_DATA: "SAVE_WORKOUT_DATA",
  SAVE_USER_ATTRIBUTES_DATA: "SAVE_USER_ATTRIBUTES_DATA",
  SAVE_SURVEY_LIST_DATA: "SAVE_SURVEY_LIST_DATA",
  SURVEY_SCREEN_LIST: "SURVEY_SCREEN_LIST",
  UPDATE_USER_DATA_SURVEY: "UPDATE_USER_DATA_SURVEY",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  UPDATE_COMMON_DATA: "UPDATE_COMMON_DATA",
  SAVE_ACHIEVEMENT_DATA: "SAVE_ACHIEVEMENT_DATA",
  UPDATE_PROGRAM_TAB_DATA: "UPDATE_PROGRAM_TAB_DATA",
  UPDATE_LIST_ATTENDANCE_DATA: "UPDATE_LIST_ATTENDANCE_DATA",
  CLEAR_ALL_REDUX_STATE: "CLEAR_ALL_REDUX_STATE",
  RESET_USER_DATA: "RESET_USER_DATA",
  UPDATE_SCHEDULE_LIST_DATA: "UPDATE_SCHEDULE_LIST_DATA",
  USERS_APP_STATE_MODE: "USERS_APP_STATE_MODE",
  USERS_COUNTRY_LOCATION_CODE: "USERS_COUNTRY_LOCATION_CODE",
  SAVE_DEVICE_TOKEN: 'SAVE_DEVICE_TOKEN',
};

export { AppConstant, ApIConstant };
