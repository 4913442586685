 
import React, { useEffect, useState } from 'react';
import MeetingJoinDetailsInc from '../../components/chime/containers/MeetingJoinDetailsInc';
import { StyledLayout } from './Styled';
import DeviceSelection from '../../components/chime/components/DeviceSelection';
import { useMeetingState } from '../../components/chime/providers/MeetingStateProvider';
import EndMeetingPopup from '../../components/chime/containers/EndMeetingPopup';
import { isMobile, isTablet} from 'react-device-detect';
import { ControlButton } from '../../helpers/types';
import { MeetingManager, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import BottomSheetDeviseSelection from '../../components/BottomSheetDeviseSelection';
import routes from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { formatTime } from '../../utils/formatters';
import { useOrientationChange } from '../../hooks/useOrientationChange';
import { RotatingLines as Spinner } from 'react-loader-spinner'
import Colors from "../../constants/colors"

const DeviceSetupInc: React.FC = ()  => {
  const { 
    meetingStatus, 
    meetingData, 
    leaveMeeting, 
    changeVideoInput, 
    changeAudioInput,
    changeAudioOutput,
    setTheme,
  } = useMeetingState();
  const { closeWebView } = useMeetingState();
  const meetingManager = useMeetingManager();
  const navigate = useNavigate();
  const [showDeviceSelection, setShowDeviceSelection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { meetingId, localUserName } = useMeetingState();
  const [deviceSelectionButton, setDeviceSelectionButton] = useState<ControlButton>('video')

  const isPhone= () => {
    return isMobile && !isTablet
  }
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

  useEffect(() => {
    setTheme('dark');
    localStorage.setItem('theme', 'dark');
    if (!isPhone())  return 
    // Disable scrolling
    document.body.style.overflow = 'hidden';
    return () => {
      // Re-enable scrolling
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
 
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    }
  }, []);


  const handleResize = () => {;
    const portrait =isPhone() ? (window.innerHeight > window.innerWidth) : (window.innerHeight > window.innerWidth);
    setIsPortrait(portrait);
  };


  const endMeetingHandler= () => {
    leaveMeeting(meetingManager)
    navigate(routes.MEETINGINCENDED);
    closeWebView()
  }

  const closeDeviceSelection = (): void => {
    setShowDeviceSelection(false)
  }

  const  handleOpenDeviceSelection= (buttonType: ControlButton) => {
    setDeviceSelectionButton(buttonType)
    setShowDeviceSelection(true)
   }

  const  handleDeviceSelection= (deviceId: string, meetingManager: MeetingManager | undefined, buttonType: ControlButton) => {
    if (buttonType === "video") {
      changeVideoInput(deviceId, meetingManager)
    } else if (buttonType === 'microphone') {
      changeAudioInput(deviceId, meetingManager)
    } else  if (buttonType === "speaker") {
      changeAudioOutput(deviceId, meetingManager)
    }
   }

   const getMeetingTime= () => {
    if (meetingData?.Event.EventStartTime && meetingData?.Event.EventEndTime) {
      const startTime = formatTime(meetingData?.Event.EventStartTime);
      const endTime = formatTime(meetingData?.Event.EventEndTime);
      const meetingTimeDisplay = `${startTime} - ${endTime}`;
      return meetingTimeDisplay
    } else 
        return ''
   }

   const handleJoinMeeting = async () => {
    setIsLoading(true);
       
    try {
      await meetingManager.start();
      setIsLoading(false);
       //Stop video input to be ableto use the camera in the meeting
       await meetingManager?.meetingSession?.audioVideo.stopVideoInput()
      navigate(`${routes.MEETINGINC}/${meetingId}`);
    } catch (error) {
      setIsLoading(false);
      setError((error as Error).message);
    }
  };


  return (<>
        <div className={`flex flex-col ${isPhone() ? 'justify-start' : 'justify-center'} w-full h-full self-center bg-primary-meeting-background text-white`}> 
        <StyledLayout isPortrait={isPortrait}>
            <div className='class-info-top'> 
              <div className={`${isPhone() ? 'text-[24px]' : 'text-[24px]'} text-center font-bold `}>{meetingData?.Event.Name}</div>
              <div className={`${isPhone() ? 'text-[20px] py-[15px]' : 'text-[20px] py-[23px]'}  text-center`} >{getMeetingTime()}</div>
            </div> 
            <DeviceSelection 
            isPortrait={isPortrait} 
            isPhone={isPhone()}
            isIncognito={true} 
            handleOpenDeviceSelection= {handleOpenDeviceSelection}
            />
            <div className="flex flex-col min-w-[300px] mt-[20px]"> 
              <div className='class-info-side'> 
                <div className={`${isPhone() ? 'text-[24px]' : 'text-[24px]'} text-center font-bold `}>{meetingData?.Event.Name}</div>
                <div className={`${isPhone() ? 'text-[20px] py-[15px]' : 'text-[20px] py-[23px]'}  text-center`} >{meetingData?.Event.EventStartTime}</div>
              </div>
              <button
                onClick={handleJoinMeeting}
                className="self-center mb-[20px] w-[180px] h-[46px] bg-primary-button hover:bg-hover-button text-white  text-[20px] font-bold py-2 px-6 rounded-full focus:outline-none focus:shadow-outline"
              >
                {isLoading ? 'Loading...' : 'Join class'}
                </button>
              <button 
                onClick={() => {
                  endMeetingHandler()
                }}
                className={`text-pwhite font-bold bg-transparent text-[20px]`}>
                Cancel
              </button>
            </div>
          <BottomSheetDeviseSelection
            currentTheme={'dark'}
            showBottomSheet={showDeviceSelection}
            closeBottomSheet={closeDeviceSelection}
            handleDeviceSelection={handleDeviceSelection}
            buttonType={deviceSelectionButton}
            isIncognito={true}
            isPortrait={isPortrait}
          />
        </StyledLayout>
      {/* <EndMeetingPopup isInstructor={true} showModal={showPopup} setShowModal={setShowPopup}/> */}
      </div>
  </>
  );
}

export default DeviceSetupInc;