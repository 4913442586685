import React from 'react';
import changeNavLayoutIcon from '../assets/images/change-navigation-layout.png';
import startIcon from '../assets/images/start-icon.png';
import profileIcon from '../assets/images/profile-icon.png';
import { isMobile, isTablet} from 'react-device-detect';

interface FloatingTabBarProps {
  selectedMenu: 'start' | 'profile';
  setSelectedMenu: (menu: 'start' | 'profile') => void;
  toggleView: () => void;
}

const FloatingTabBar: React.FC<FloatingTabBarProps> = ({ selectedMenu, setSelectedMenu, toggleView }) => {
  const isPhone= () => {
    return isMobile && !isTablet
  }

  return (
    <div className={`${isPhone() ? 'min-w-[250px]' : 'min-w-[350px]'} fixed top-6 left-1/2 bg-white transform -translate-x-1/2 h-12 z-50 rounded-full flex items-center justify-around shadow-[0_0px_15px_1px_rgba(0,0,0,0.15)]`}>
      { (!isPhone())  && 
      <button onClick={toggleView} className="ml-[15px]">
      <img src={changeNavLayoutIcon} alt="ChangeNavLayout" className="h-[25px] w-[25px] mr-[18px]" />  
      </button>
      }
      <button
        onClick={() => setSelectedMenu('start')}
        className={`flex h-[40px] items-center space-x-2 rounded-full px-4 py-1 ${selectedMenu === 'start' ? 'bg-primary-button' : ''}`}
      >
         <img src={startIcon} alt="Start" className="h-[18px] w-[18px] mr-[12px]" />  
        <span className="text-black font-bold font-[16px]">Start</span>
      </button>
      <button
        onClick={() => setSelectedMenu('profile')}
        className={`flex h-[40px]  mr-[5px] items-center text-black space-x-2 rounded-full px-4 py-1 ${selectedMenu === 'profile' ? 'bg-primary-button' : ''}`}
      >
         <img src={profileIcon} alt="Profile" className="h-[18px] w-[18px] mr-[12px]" />  
         <span className="text-black font-bold text-[16px]">Profile</span>
      </button>
    </div>
  );
};

export default FloatingTabBar;
