
import './App.css'
import React, { FC ,useEffect} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useMeetingState } from './components/chime/providers/MeetingStateProvider';
import MeetingProviderWrapper from './components/chime/containers/MeetingProviderWrapper';
import { Route, Routes } from 'react-router-dom';
import routes from './constants/routes';
import NoMeetingRedirect from './components/chime/containers/NoMeetingRedirect';
import { Meeting, DeviceSetup, Login, Start, JoinMeeting, MeetingInc, DeviceSetupInc ,MeetingIncEnded, Followers, Profile, Members} from './views';
import MeetingEventObserver from './components/chime/containers/MeetingEventObserver';
import {ScrollToTop} from './helpers/NavigationHelper'
import { GlobalErrorBoundary, LocalErrorBoundary } from './components/ErrorsBoundary';
import {UserProvider} from './contexts/userContext'
import PrivateRoute from './views/PrivateRoute/PrivateRoute';
import NotFound from './views/NotFound/NotFound';
import { PopupProvider } from '../src/contexts/popupContext';
import NavigationWrapper from './components/NavigationWrapper';
import BranchService from './utils/branchioService';

const App: FC = () => {
  useEffect(() => {
    BranchService.init();
    console.log('ENVIRONMENT:', import.meta.env.VITE_ENV)
    console.log('CHIME_SERVER_URL:', import.meta.env.VITE_CHIME_SERVER_URL)
    console.log('CHIME_SERVER_URL:', import.meta.env.VITE_CHIME_SERVER_URL)
  }, []);

  
  return (
  <Router>
    <GlobalErrorBoundary>
      <UserProvider>
        <ScrollToTop/>
          <LocalErrorBoundary>
           <PopupProvider>
            <Routes>
              <Route 
                path={routes.LOGIN} 
                element={<Login />} 
              />
              <Route 
                path={routes.JOINMEETING} 
                element={
                  <MeetingProviderWrapper>
                    <JoinMeeting />
                  </MeetingProviderWrapper>} 
              />
              <Route
                  path={routes.DEVICEINC}
                  element={
                    <MeetingProviderWrapper>
                      <NoMeetingRedirect>
                        <DeviceSetupInc />
                      </NoMeetingRedirect>
                    </MeetingProviderWrapper>
                  }
                ></Route>
                <Route
                  path={`${routes.MEETINGINC}/:meetingId`}
                  element={
                    <MeetingProviderWrapper>
                      <NoMeetingRedirect>
                        <MeetingModeSelectorInc />
                      </NoMeetingRedirect>
                    </MeetingProviderWrapper>
                  }
                ></Route>
                 <Route
                  path={`${routes.MEETINGINCENDED}`}
                  element={
                    <MeetingProviderWrapper>
                      <NoMeetingRedirect>
                        <MeetingIncEnded />
                      </NoMeetingRedirect>
                    </MeetingProviderWrapper>
                  }
                ></Route>
              <Route element={<PrivateRoute />}> 
                <Route 
                  path={routes.START} 
                  element={
                    <NavigationWrapper>
                      <Start />
                    </NavigationWrapper>
                  } 
                />
                 <Route 
                  path={routes.FOLLOWERS} 
                  element={
                  <NavigationWrapper>
                      <Followers />
                  </NavigationWrapper>
                  } 
                />
                 <Route 
                  path={routes.PROFILE} 
                  element={
                  <NavigationWrapper>
                      <Profile />
                  </NavigationWrapper>
                  } 
                />
                <Route 
                  path={routes.MEMBERS} 
                  element={
                  <NavigationWrapper>
                      <Members />
                  </NavigationWrapper>
                  } 
                />
                <Route
                  path={routes.DEVICE}
                  element={
                    <MeetingProviderWrapper>
                      <NoMeetingRedirect>
                        <DeviceSetup />
                      </NoMeetingRedirect>
                    </MeetingProviderWrapper>
                  }
                ></Route>
                <Route
                  path={`${routes.MEETING}/:meetingId`}
                  element={
                    <MeetingProviderWrapper>
                      <NoMeetingRedirect>
                        <MeetingModeSelector />
                      </NoMeetingRedirect>
                    </MeetingProviderWrapper>
                  }
                ></Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
            </PopupProvider>
          </LocalErrorBoundary>
        <MeetingEventObserver />
      </UserProvider>
    </GlobalErrorBoundary>
    
  </Router>
);
}

const MeetingModeSelector: React.FC = () => {
  const { meetingMode } = useMeetingState();
  return <Meeting mode={meetingMode} />;
};

const MeetingModeSelectorInc: React.FC = () => {
  const { meetingMode } = useMeetingState();
  return <MeetingInc mode={meetingMode} />;
};

export default App;