/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const sellMembership = /* GraphQL */ `mutation SellMembership($input: SellMembershipInput!) {
  sellMembership(input: $input) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SellMembershipMutationVariables,
  APITypes.SellMembershipMutation
>;
export const terminateMembership = /* GraphQL */ `mutation TerminateMembership($input: TerminateMembershipInput!) {
  terminateMembership(input: $input) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.TerminateMembershipMutationVariables,
  APITypes.TerminateMembershipMutation
>;
export const createBooking = /* GraphQL */ `mutation CreateBooking($input: CreateBookingInput!) {
  createBooking(input: $input) {
    contactId
    eventId
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookingMutationVariables,
  APITypes.CreateBookingMutation
>;
export const cancelBooking = /* GraphQL */ `mutation CancelBooking($input: CancelBookingInput!) {
  cancelBooking(input: $input) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelBookingMutationVariables,
  APITypes.CancelBookingMutation
>;
export const sendOTP = /* GraphQL */ `mutation SendOTP($input: SendOTPInput!) {
  sendOTP(input: $input) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendOTPMutationVariables,
  APITypes.SendOTPMutation
>;
export const validateOTP = /* GraphQL */ `mutation ValidateOTP($input: ValidateOTPInput!) {
  validateOTP(input: $input) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ValidateOTPMutationVariables,
  APITypes.ValidateOTPMutation
>;
export const createLog = /* GraphQL */ `mutation CreateLog($input: LogInfoInput!) {
  createLog(input: $input) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLogMutationVariables,
  APITypes.CreateLogMutation
>;
export const handleAttendance = /* GraphQL */ `mutation HandleAttendance($input: handleAttendanceInput!) {
  handleAttendance(input: $input) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.HandleAttendanceMutationVariables,
  APITypes.HandleAttendanceMutation
>;
export const handleDeleteUser = /* GraphQL */ `mutation HandleDeleteUser($input: handleDeleteUserInput!) {
  handleDeleteUser(input: $input) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.HandleDeleteUserMutationVariables,
  APITypes.HandleDeleteUserMutation
>;
export const startConversation = /* GraphQL */ `mutation StartConversation($input: startConversationInput!) {
  startConversation(input: $input) {
    status
    message
    conversationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartConversationMutationVariables,
  APITypes.StartConversationMutation
>;
export const sendMessage = /* GraphQL */ `mutation SendMessage($input: SendMessageInput!) {
  sendMessage(input: $input) {
    status
    message
    messageId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendMessageMutationVariables,
  APITypes.SendMessageMutation
>;
export const updateMessageReadStatus = /* GraphQL */ `mutation UpdateMessageReadStatus($input: updateMessageReadStatusInput!) {
  updateMessageReadStatus(input: $input) {
    status
    message
    conversationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageReadStatusMutationVariables,
  APITypes.UpdateMessageReadStatusMutation
>;
export const deleteConversationForUser = /* GraphQL */ `mutation DeleteConversationForUser($input: deleteConversationInput!) {
  deleteConversationForUser(input: $input) {
    status
    message
    conversationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConversationForUserMutationVariables,
  APITypes.DeleteConversationForUserMutation
>;
export const createContent = /* GraphQL */ `mutation CreateContent(
  $input: CreateContentInput!
  $condition: ModelContentConditionInput
) {
  createContent(input: $input, condition: $condition) {
    id
    Key
    Value
    Type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContentMutationVariables,
  APITypes.CreateContentMutation
>;
export const updateContent = /* GraphQL */ `mutation UpdateContent(
  $input: UpdateContentInput!
  $condition: ModelContentConditionInput
) {
  updateContent(input: $input, condition: $condition) {
    id
    Key
    Value
    Type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContentMutationVariables,
  APITypes.UpdateContentMutation
>;
export const deleteContent = /* GraphQL */ `mutation DeleteContent(
  $input: DeleteContentInput!
  $condition: ModelContentConditionInput
) {
  deleteContent(input: $input, condition: $condition) {
    id
    Key
    Value
    Type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContentMutationVariables,
  APITypes.DeleteContentMutation
>;
export const createSurvey = /* GraphQL */ `mutation CreateSurvey(
  $input: CreateSurveyInput!
  $condition: ModelSurveyConditionInput
) {
  createSurvey(input: $input, condition: $condition) {
    id
    Name
    Description
    Questions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSurveyMutationVariables,
  APITypes.CreateSurveyMutation
>;
export const updateSurvey = /* GraphQL */ `mutation UpdateSurvey(
  $input: UpdateSurveyInput!
  $condition: ModelSurveyConditionInput
) {
  updateSurvey(input: $input, condition: $condition) {
    id
    Name
    Description
    Questions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSurveyMutationVariables,
  APITypes.UpdateSurveyMutation
>;
export const deleteSurvey = /* GraphQL */ `mutation DeleteSurvey(
  $input: DeleteSurveyInput!
  $condition: ModelSurveyConditionInput
) {
  deleteSurvey(input: $input, condition: $condition) {
    id
    Name
    Description
    Questions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSurveyMutationVariables,
  APITypes.DeleteSurveyMutation
>;
export const createWorkout = /* GraphQL */ `mutation CreateWorkout(
  $input: CreateWorkoutInput!
  $condition: ModelWorkoutConditionInput
) {
  createWorkout(input: $input, condition: $condition) {
    id
    Name
    VideoIDs
    CreatedBy {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    contactWorkoutsContactID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWorkoutMutationVariables,
  APITypes.CreateWorkoutMutation
>;
export const updateWorkout = /* GraphQL */ `mutation UpdateWorkout(
  $input: UpdateWorkoutInput!
  $condition: ModelWorkoutConditionInput
) {
  updateWorkout(input: $input, condition: $condition) {
    id
    Name
    VideoIDs
    CreatedBy {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    contactWorkoutsContactID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkoutMutationVariables,
  APITypes.UpdateWorkoutMutation
>;
export const deleteWorkout = /* GraphQL */ `mutation DeleteWorkout(
  $input: DeleteWorkoutInput!
  $condition: ModelWorkoutConditionInput
) {
  deleteWorkout(input: $input, condition: $condition) {
    id
    Name
    VideoIDs
    CreatedBy {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    contactWorkoutsContactID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWorkoutMutationVariables,
  APITypes.DeleteWorkoutMutation
>;
export const createVideo = /* GraphQL */ `mutation CreateVideo(
  $input: CreateVideoInput!
  $condition: ModelVideoConditionInput
) {
  createVideo(input: $input, condition: $condition) {
    id
    Name
    VideoUrl
    ThumbnailUrl
    Contacts {
      nextToken
      __typename
    }
    ContactIds
    SubCategoryID {
      id
      Name
      Category
      Description
      PreviewImage
      PreviewVideo
      BookingType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    contactVideosContactID
    subCategoryVideosId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVideoMutationVariables,
  APITypes.CreateVideoMutation
>;
export const updateVideo = /* GraphQL */ `mutation UpdateVideo(
  $input: UpdateVideoInput!
  $condition: ModelVideoConditionInput
) {
  updateVideo(input: $input, condition: $condition) {
    id
    Name
    VideoUrl
    ThumbnailUrl
    Contacts {
      nextToken
      __typename
    }
    ContactIds
    SubCategoryID {
      id
      Name
      Category
      Description
      PreviewImage
      PreviewVideo
      BookingType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    contactVideosContactID
    subCategoryVideosId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVideoMutationVariables,
  APITypes.UpdateVideoMutation
>;
export const deleteVideo = /* GraphQL */ `mutation DeleteVideo(
  $input: DeleteVideoInput!
  $condition: ModelVideoConditionInput
) {
  deleteVideo(input: $input, condition: $condition) {
    id
    Name
    VideoUrl
    ThumbnailUrl
    Contacts {
      nextToken
      __typename
    }
    ContactIds
    SubCategoryID {
      id
      Name
      Category
      Description
      PreviewImage
      PreviewVideo
      BookingType
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    contactVideosContactID
    subCategoryVideosId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVideoMutationVariables,
  APITypes.DeleteVideoMutation
>;
export const createAttendance = /* GraphQL */ `mutation CreateAttendance(
  $input: CreateAttendanceInput!
  $condition: ModelAttendanceConditionInput
) {
  createAttendance(input: $input, condition: $condition) {
    id
    Status
    Contact {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    contactAttendancesContactID
    Event {
      id
      Name
      StartDate
      EndDate
      EventStartDate
      EventEndDate
      IsCourse
      Recurrence
      Description
      ParentId
      Capacity
      Booked
      LevelFrom
      LevelTo
      AgeFrom
      AgeTo
      Gender
      Instructor
      AssistantInstructors
      InstructorId
      AssistantInstructorsId
      Fees
      Extras
      Duration
      Remaining
      PriceScheme
      Price
      subCategoryEventsId
      programEventsId
      PlannerEventExtra
      RawEventDate
      localStartDate
      localEndDate
      AllDayEvent
      GuideCapacity
      isStreaming
      source
      createdAt
      updatedAt
      contactEventsContactID
      __typename
    }
    eventAttendancesId
    EventStartDate
    EventEndTime
    EventStartTime
    operationSource
    PerfectMindAttendanceID
    MasterEventId
    Instructors
    InstructorId
    ModifiedDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAttendanceMutationVariables,
  APITypes.CreateAttendanceMutation
>;
export const updateAttendance = /* GraphQL */ `mutation UpdateAttendance(
  $input: UpdateAttendanceInput!
  $condition: ModelAttendanceConditionInput
) {
  updateAttendance(input: $input, condition: $condition) {
    id
    Status
    Contact {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    contactAttendancesContactID
    Event {
      id
      Name
      StartDate
      EndDate
      EventStartDate
      EventEndDate
      IsCourse
      Recurrence
      Description
      ParentId
      Capacity
      Booked
      LevelFrom
      LevelTo
      AgeFrom
      AgeTo
      Gender
      Instructor
      AssistantInstructors
      InstructorId
      AssistantInstructorsId
      Fees
      Extras
      Duration
      Remaining
      PriceScheme
      Price
      subCategoryEventsId
      programEventsId
      PlannerEventExtra
      RawEventDate
      localStartDate
      localEndDate
      AllDayEvent
      GuideCapacity
      isStreaming
      source
      createdAt
      updatedAt
      contactEventsContactID
      __typename
    }
    eventAttendancesId
    EventStartDate
    EventEndTime
    EventStartTime
    operationSource
    PerfectMindAttendanceID
    MasterEventId
    Instructors
    InstructorId
    ModifiedDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAttendanceMutationVariables,
  APITypes.UpdateAttendanceMutation
>;
export const deleteAttendance = /* GraphQL */ `mutation DeleteAttendance(
  $input: DeleteAttendanceInput!
  $condition: ModelAttendanceConditionInput
) {
  deleteAttendance(input: $input, condition: $condition) {
    id
    Status
    Contact {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    contactAttendancesContactID
    Event {
      id
      Name
      StartDate
      EndDate
      EventStartDate
      EventEndDate
      IsCourse
      Recurrence
      Description
      ParentId
      Capacity
      Booked
      LevelFrom
      LevelTo
      AgeFrom
      AgeTo
      Gender
      Instructor
      AssistantInstructors
      InstructorId
      AssistantInstructorsId
      Fees
      Extras
      Duration
      Remaining
      PriceScheme
      Price
      subCategoryEventsId
      programEventsId
      PlannerEventExtra
      RawEventDate
      localStartDate
      localEndDate
      AllDayEvent
      GuideCapacity
      isStreaming
      source
      createdAt
      updatedAt
      contactEventsContactID
      __typename
    }
    eventAttendancesId
    EventStartDate
    EventEndTime
    EventStartTime
    operationSource
    PerfectMindAttendanceID
    MasterEventId
    Instructors
    InstructorId
    ModifiedDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAttendanceMutationVariables,
  APITypes.DeleteAttendanceMutation
>;
export const createEvent = /* GraphQL */ `mutation CreateEvent(
  $input: CreateEventInput!
  $condition: ModelEventConditionInput
) {
  createEvent(input: $input, condition: $condition) {
    id
    Name
    StartDate
    EndDate
    EventStartDate
    EventEndDate
    IsCourse
    Recurrence
    Description
    ParentId
    Capacity
    Booked
    LevelFrom
    LevelTo
    AgeFrom
    AgeTo
    Gender
    Program {
      id
      Name
      Description
      Ranks
      Progress
      Gender
      MinimumAge
      MaximumAge
      createdAt
      updatedAt
      __typename
    }
    Instructor
    AssistantInstructors
    InstructorId
    AssistantInstructorsId
    InstructorInfo {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    InstructorDetails {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    AssistantInstructorsInfo {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    Fees
    Extras
    Duration
    Remaining
    PriceScheme
    Price
    SubCategory {
      id
      Name
      Category
      Description
      PreviewImage
      PreviewVideo
      BookingType
      createdAt
      updatedAt
      __typename
    }
    subCategoryEventsId
    programEventsId
    Attendances {
      nextToken
      __typename
    }
    EventOccurrences {
      nextToken
      __typename
    }
    PlannerEventExtra
    RawEventDate
    localStartDate
    localEndDate
    AllDayEvent
    GuideCapacity
    isStreaming
    source
    createdAt
    updatedAt
    contactEventsContactID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventMutationVariables,
  APITypes.CreateEventMutation
>;
export const updateEvent = /* GraphQL */ `mutation UpdateEvent(
  $input: UpdateEventInput!
  $condition: ModelEventConditionInput
) {
  updateEvent(input: $input, condition: $condition) {
    id
    Name
    StartDate
    EndDate
    EventStartDate
    EventEndDate
    IsCourse
    Recurrence
    Description
    ParentId
    Capacity
    Booked
    LevelFrom
    LevelTo
    AgeFrom
    AgeTo
    Gender
    Program {
      id
      Name
      Description
      Ranks
      Progress
      Gender
      MinimumAge
      MaximumAge
      createdAt
      updatedAt
      __typename
    }
    Instructor
    AssistantInstructors
    InstructorId
    AssistantInstructorsId
    InstructorInfo {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    InstructorDetails {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    AssistantInstructorsInfo {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    Fees
    Extras
    Duration
    Remaining
    PriceScheme
    Price
    SubCategory {
      id
      Name
      Category
      Description
      PreviewImage
      PreviewVideo
      BookingType
      createdAt
      updatedAt
      __typename
    }
    subCategoryEventsId
    programEventsId
    Attendances {
      nextToken
      __typename
    }
    EventOccurrences {
      nextToken
      __typename
    }
    PlannerEventExtra
    RawEventDate
    localStartDate
    localEndDate
    AllDayEvent
    GuideCapacity
    isStreaming
    source
    createdAt
    updatedAt
    contactEventsContactID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventMutationVariables,
  APITypes.UpdateEventMutation
>;
export const deleteEvent = /* GraphQL */ `mutation DeleteEvent(
  $input: DeleteEventInput!
  $condition: ModelEventConditionInput
) {
  deleteEvent(input: $input, condition: $condition) {
    id
    Name
    StartDate
    EndDate
    EventStartDate
    EventEndDate
    IsCourse
    Recurrence
    Description
    ParentId
    Capacity
    Booked
    LevelFrom
    LevelTo
    AgeFrom
    AgeTo
    Gender
    Program {
      id
      Name
      Description
      Ranks
      Progress
      Gender
      MinimumAge
      MaximumAge
      createdAt
      updatedAt
      __typename
    }
    Instructor
    AssistantInstructors
    InstructorId
    AssistantInstructorsId
    InstructorInfo {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    InstructorDetails {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    AssistantInstructorsInfo {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    Fees
    Extras
    Duration
    Remaining
    PriceScheme
    Price
    SubCategory {
      id
      Name
      Category
      Description
      PreviewImage
      PreviewVideo
      BookingType
      createdAt
      updatedAt
      __typename
    }
    subCategoryEventsId
    programEventsId
    Attendances {
      nextToken
      __typename
    }
    EventOccurrences {
      nextToken
      __typename
    }
    PlannerEventExtra
    RawEventDate
    localStartDate
    localEndDate
    AllDayEvent
    GuideCapacity
    isStreaming
    source
    createdAt
    updatedAt
    contactEventsContactID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventMutationVariables,
  APITypes.DeleteEventMutation
>;
export const createContact = /* GraphQL */ `mutation CreateContact(
  $input: CreateContactInput!
  $condition: ModelContactConditionInput
) {
  createContact(input: $input, condition: $condition) {
    id
    FirstName
    LastName
    Email
    Gender
    Phone
    CreatedDate
    Birthdate
    ProfileImageUrl
    Membership
    Status
    Achievements {
      id
      Title
      Description
      BadgeIMG
      ContentIMG
      createdAt
      updatedAt
      __typename
    }
    IsInstructor
    InstructorBio
    Survey
    Notifications
    Workouts {
      nextToken
      __typename
    }
    Attendances {
      nextToken
      __typename
    }
    Videos {
      nextToken
      __typename
    }
    Events {
      nextToken
      __typename
    }
    DeviceInfo
    DeviceTokens
    contactID
    Transactions {
      nextToken
      __typename
    }
    verificationOTP
    countryCode
    deleteReason
    instructor {
      ID
      RecordName
      LocationId
      CreatedDate
      CreatedByUserID
      ModifiedDate
      ModifiedByUserID
      RowVersion
      AboutThis
      FirstName
      LastName
      Photo
      FullName
      Position
      JobTitle
      Gender
      Birthdate
      Status
      EmployementDate
      TerminationDate
      MobilePhone
      MobilePhone_NoSpace
      HomePhone
      HomePhone_NoSpace
      WorkPhone
      WorkPhone_NoSpace
      OtherPhone
      OtherPhone_NoSpace
      Address
      Email
      StaffID
      UseServiceRestrictions
      BookingStartsInDays
      BookingStartsInHours
      BookingEndsInDays
      DisplayEndsInDays
      Age
      PhotoThumb
      PhotoCard
      __typename
    }
    instructorIds
    instructorList {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    messages {
      nextToken
      __typename
    }
    Conversations {
      nextToken
      __typename
    }
    operationSource
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContactMutationVariables,
  APITypes.CreateContactMutation
>;
export const updateContact = /* GraphQL */ `mutation UpdateContact(
  $input: UpdateContactInput!
  $condition: ModelContactConditionInput
) {
  updateContact(input: $input, condition: $condition) {
    id
    FirstName
    LastName
    Email
    Gender
    Phone
    CreatedDate
    Birthdate
    ProfileImageUrl
    Membership
    Status
    Achievements {
      id
      Title
      Description
      BadgeIMG
      ContentIMG
      createdAt
      updatedAt
      __typename
    }
    IsInstructor
    InstructorBio
    Survey
    Notifications
    Workouts {
      nextToken
      __typename
    }
    Attendances {
      nextToken
      __typename
    }
    Videos {
      nextToken
      __typename
    }
    Events {
      nextToken
      __typename
    }
    DeviceInfo
    DeviceTokens
    contactID
    Transactions {
      nextToken
      __typename
    }
    verificationOTP
    countryCode
    deleteReason
    instructor {
      ID
      RecordName
      LocationId
      CreatedDate
      CreatedByUserID
      ModifiedDate
      ModifiedByUserID
      RowVersion
      AboutThis
      FirstName
      LastName
      Photo
      FullName
      Position
      JobTitle
      Gender
      Birthdate
      Status
      EmployementDate
      TerminationDate
      MobilePhone
      MobilePhone_NoSpace
      HomePhone
      HomePhone_NoSpace
      WorkPhone
      WorkPhone_NoSpace
      OtherPhone
      OtherPhone_NoSpace
      Address
      Email
      StaffID
      UseServiceRestrictions
      BookingStartsInDays
      BookingStartsInHours
      BookingEndsInDays
      DisplayEndsInDays
      Age
      PhotoThumb
      PhotoCard
      __typename
    }
    instructorIds
    instructorList {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    messages {
      nextToken
      __typename
    }
    Conversations {
      nextToken
      __typename
    }
    operationSource
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContactMutationVariables,
  APITypes.UpdateContactMutation
>;
export const deleteContact = /* GraphQL */ `mutation DeleteContact(
  $input: DeleteContactInput!
  $condition: ModelContactConditionInput
) {
  deleteContact(input: $input, condition: $condition) {
    id
    FirstName
    LastName
    Email
    Gender
    Phone
    CreatedDate
    Birthdate
    ProfileImageUrl
    Membership
    Status
    Achievements {
      id
      Title
      Description
      BadgeIMG
      ContentIMG
      createdAt
      updatedAt
      __typename
    }
    IsInstructor
    InstructorBio
    Survey
    Notifications
    Workouts {
      nextToken
      __typename
    }
    Attendances {
      nextToken
      __typename
    }
    Videos {
      nextToken
      __typename
    }
    Events {
      nextToken
      __typename
    }
    DeviceInfo
    DeviceTokens
    contactID
    Transactions {
      nextToken
      __typename
    }
    verificationOTP
    countryCode
    deleteReason
    instructor {
      ID
      RecordName
      LocationId
      CreatedDate
      CreatedByUserID
      ModifiedDate
      ModifiedByUserID
      RowVersion
      AboutThis
      FirstName
      LastName
      Photo
      FullName
      Position
      JobTitle
      Gender
      Birthdate
      Status
      EmployementDate
      TerminationDate
      MobilePhone
      MobilePhone_NoSpace
      HomePhone
      HomePhone_NoSpace
      WorkPhone
      WorkPhone_NoSpace
      OtherPhone
      OtherPhone_NoSpace
      Address
      Email
      StaffID
      UseServiceRestrictions
      BookingStartsInDays
      BookingStartsInHours
      BookingEndsInDays
      DisplayEndsInDays
      Age
      PhotoThumb
      PhotoCard
      __typename
    }
    instructorIds
    instructorList {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    messages {
      nextToken
      __typename
    }
    Conversations {
      nextToken
      __typename
    }
    operationSource
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContactMutationVariables,
  APITypes.DeleteContactMutation
>;
export const createSubCategory = /* GraphQL */ `mutation CreateSubCategory(
  $input: CreateSubCategoryInput!
  $condition: ModelSubCategoryConditionInput
) {
  createSubCategory(input: $input, condition: $condition) {
    id
    Name
    Category
    Description
    PreviewImage
    PreviewVideo
    BookingType
    Events {
      nextToken
      __typename
    }
    Videos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSubCategoryMutationVariables,
  APITypes.CreateSubCategoryMutation
>;
export const updateSubCategory = /* GraphQL */ `mutation UpdateSubCategory(
  $input: UpdateSubCategoryInput!
  $condition: ModelSubCategoryConditionInput
) {
  updateSubCategory(input: $input, condition: $condition) {
    id
    Name
    Category
    Description
    PreviewImage
    PreviewVideo
    BookingType
    Events {
      nextToken
      __typename
    }
    Videos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubCategoryMutationVariables,
  APITypes.UpdateSubCategoryMutation
>;
export const deleteSubCategory = /* GraphQL */ `mutation DeleteSubCategory(
  $input: DeleteSubCategoryInput!
  $condition: ModelSubCategoryConditionInput
) {
  deleteSubCategory(input: $input, condition: $condition) {
    id
    Name
    Category
    Description
    PreviewImage
    PreviewVideo
    BookingType
    Events {
      nextToken
      __typename
    }
    Videos {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSubCategoryMutationVariables,
  APITypes.DeleteSubCategoryMutation
>;
export const createCategory = /* GraphQL */ `mutation CreateCategory(
  $input: CreateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  createCategory(input: $input, condition: $condition) {
    id
    Name
    Order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory(
  $input: UpdateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  updateCategory(input: $input, condition: $condition) {
    id
    Name
    Order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory(
  $input: DeleteCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  deleteCategory(input: $input, condition: $condition) {
    id
    Name
    Order
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const createProgram = /* GraphQL */ `mutation CreateProgram(
  $input: CreateProgramInput!
  $condition: ModelProgramConditionInput
) {
  createProgram(input: $input, condition: $condition) {
    id
    Name
    Description
    Ranks
    Progress
    Gender
    MinimumAge
    MaximumAge
    Events {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProgramMutationVariables,
  APITypes.CreateProgramMutation
>;
export const updateProgram = /* GraphQL */ `mutation UpdateProgram(
  $input: UpdateProgramInput!
  $condition: ModelProgramConditionInput
) {
  updateProgram(input: $input, condition: $condition) {
    id
    Name
    Description
    Ranks
    Progress
    Gender
    MinimumAge
    MaximumAge
    Events {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProgramMutationVariables,
  APITypes.UpdateProgramMutation
>;
export const deleteProgram = /* GraphQL */ `mutation DeleteProgram(
  $input: DeleteProgramInput!
  $condition: ModelProgramConditionInput
) {
  deleteProgram(input: $input, condition: $condition) {
    id
    Name
    Description
    Ranks
    Progress
    Gender
    MinimumAge
    MaximumAge
    Events {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProgramMutationVariables,
  APITypes.DeleteProgramMutation
>;
export const createAward = /* GraphQL */ `mutation CreateAward(
  $input: CreateAwardInput!
  $condition: ModelAwardConditionInput
) {
  createAward(input: $input, condition: $condition) {
    id
    Title
    Description
    BadgeIMG
    ContentIMG
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAwardMutationVariables,
  APITypes.CreateAwardMutation
>;
export const updateAward = /* GraphQL */ `mutation UpdateAward(
  $input: UpdateAwardInput!
  $condition: ModelAwardConditionInput
) {
  updateAward(input: $input, condition: $condition) {
    id
    Title
    Description
    BadgeIMG
    ContentIMG
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAwardMutationVariables,
  APITypes.UpdateAwardMutation
>;
export const deleteAward = /* GraphQL */ `mutation DeleteAward(
  $input: DeleteAwardInput!
  $condition: ModelAwardConditionInput
) {
  deleteAward(input: $input, condition: $condition) {
    id
    Title
    Description
    BadgeIMG
    ContentIMG
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAwardMutationVariables,
  APITypes.DeleteAwardMutation
>;
export const createMembership = /* GraphQL */ `mutation CreateMembership(
  $input: CreateMembershipInput!
  $condition: ModelMembershipConditionInput
) {
  createMembership(input: $input, condition: $condition) {
    id
    Description
    Image
    Moderation
    Name
    Ongoing
    DurationDays
    Price
    Taxable
    Featured
    MembershipType
    MinAdultsCount
    MaxAdultsCount
    MinAdultAge
    MaxChildrenCount
    AllowSubsidy
    PriceIncludeTax
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMembershipMutationVariables,
  APITypes.CreateMembershipMutation
>;
export const updateMembership = /* GraphQL */ `mutation UpdateMembership(
  $input: UpdateMembershipInput!
  $condition: ModelMembershipConditionInput
) {
  updateMembership(input: $input, condition: $condition) {
    id
    Description
    Image
    Moderation
    Name
    Ongoing
    DurationDays
    Price
    Taxable
    Featured
    MembershipType
    MinAdultsCount
    MaxAdultsCount
    MinAdultAge
    MaxChildrenCount
    AllowSubsidy
    PriceIncludeTax
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMembershipMutationVariables,
  APITypes.UpdateMembershipMutation
>;
export const deleteMembership = /* GraphQL */ `mutation DeleteMembership(
  $input: DeleteMembershipInput!
  $condition: ModelMembershipConditionInput
) {
  deleteMembership(input: $input, condition: $condition) {
    id
    Description
    Image
    Moderation
    Name
    Ongoing
    DurationDays
    Price
    Taxable
    Featured
    MembershipType
    MinAdultsCount
    MaxAdultsCount
    MinAdultAge
    MaxChildrenCount
    AllowSubsidy
    PriceIncludeTax
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMembershipMutationVariables,
  APITypes.DeleteMembershipMutation
>;
export const createSplashScreens = /* GraphQL */ `mutation CreateSplashScreens(
  $input: CreateSplashScreensInput!
  $condition: ModelSplashScreensConditionInput
) {
  createSplashScreens(input: $input, condition: $condition) {
    id
    Description
    Label
    Image
    Type
    Video
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSplashScreensMutationVariables,
  APITypes.CreateSplashScreensMutation
>;
export const updateSplashScreens = /* GraphQL */ `mutation UpdateSplashScreens(
  $input: UpdateSplashScreensInput!
  $condition: ModelSplashScreensConditionInput
) {
  updateSplashScreens(input: $input, condition: $condition) {
    id
    Description
    Label
    Image
    Type
    Video
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSplashScreensMutationVariables,
  APITypes.UpdateSplashScreensMutation
>;
export const deleteSplashScreens = /* GraphQL */ `mutation DeleteSplashScreens(
  $input: DeleteSplashScreensInput!
  $condition: ModelSplashScreensConditionInput
) {
  deleteSplashScreens(input: $input, condition: $condition) {
    id
    Description
    Label
    Image
    Type
    Video
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSplashScreensMutationVariables,
  APITypes.DeleteSplashScreensMutation
>;
export const createMeeting = /* GraphQL */ `mutation CreateMeeting(
  $input: CreateMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  createMeeting(input: $input, condition: $condition) {
    id
    meetingId
    eventId
    data
    TTL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingMutationVariables,
  APITypes.CreateMeetingMutation
>;
export const updateMeeting = /* GraphQL */ `mutation UpdateMeeting(
  $input: UpdateMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  updateMeeting(input: $input, condition: $condition) {
    id
    meetingId
    eventId
    data
    TTL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeetingMutationVariables,
  APITypes.UpdateMeetingMutation
>;
export const deleteMeeting = /* GraphQL */ `mutation DeleteMeeting(
  $input: DeleteMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  deleteMeeting(input: $input, condition: $condition) {
    id
    meetingId
    eventId
    data
    TTL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingMutationVariables,
  APITypes.DeleteMeetingMutation
>;
export const createAttendee = /* GraphQL */ `mutation CreateAttendee(
  $input: CreateAttendeeInput!
  $condition: ModelAttendeeConditionInput
) {
  createAttendee(input: $input, condition: $condition) {
    attendeeId
    userId
    rejoinToken
    eventId
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAttendeeMutationVariables,
  APITypes.CreateAttendeeMutation
>;
export const updateAttendee = /* GraphQL */ `mutation UpdateAttendee(
  $input: UpdateAttendeeInput!
  $condition: ModelAttendeeConditionInput
) {
  updateAttendee(input: $input, condition: $condition) {
    attendeeId
    userId
    rejoinToken
    eventId
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAttendeeMutationVariables,
  APITypes.UpdateAttendeeMutation
>;
export const deleteAttendee = /* GraphQL */ `mutation DeleteAttendee(
  $input: DeleteAttendeeInput!
  $condition: ModelAttendeeConditionInput
) {
  deleteAttendee(input: $input, condition: $condition) {
    attendeeId
    userId
    rejoinToken
    eventId
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAttendeeMutationVariables,
  APITypes.DeleteAttendeeMutation
>;
export const createEventOccurrence = /* GraphQL */ `mutation CreateEventOccurrence(
  $input: CreateEventOccurrenceInput!
  $condition: ModelEventOccurrenceConditionInput
) {
  createEventOccurrence(input: $input, condition: $condition) {
    id
    ParentEventId
    Event {
      id
      Name
      StartDate
      EndDate
      EventStartDate
      EventEndDate
      IsCourse
      Recurrence
      Description
      ParentId
      Capacity
      Booked
      LevelFrom
      LevelTo
      AgeFrom
      AgeTo
      Gender
      Instructor
      AssistantInstructors
      InstructorId
      AssistantInstructorsId
      Fees
      Extras
      Duration
      Remaining
      PriceScheme
      Price
      subCategoryEventsId
      programEventsId
      PlannerEventExtra
      RawEventDate
      localStartDate
      localEndDate
      AllDayEvent
      GuideCapacity
      isStreaming
      source
      createdAt
      updatedAt
      contactEventsContactID
      __typename
    }
    StartTime
    EndTime
    OccurrenceId
    Status
    RawEventDate
    EventStartDate
    EventEndDate
    eventSubCategoryId
    AgeFrom
    AgeTo
    Gender
    Name
    Capacity
    Booked
    Remaining
    AllDayEvent
    GuideCapacity
    isStreaming
    localStartDate
    localEndDate
    source
    createdAt
    updatedAt
    eventEventOccurrencesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventOccurrenceMutationVariables,
  APITypes.CreateEventOccurrenceMutation
>;
export const updateEventOccurrence = /* GraphQL */ `mutation UpdateEventOccurrence(
  $input: UpdateEventOccurrenceInput!
  $condition: ModelEventOccurrenceConditionInput
) {
  updateEventOccurrence(input: $input, condition: $condition) {
    id
    ParentEventId
    Event {
      id
      Name
      StartDate
      EndDate
      EventStartDate
      EventEndDate
      IsCourse
      Recurrence
      Description
      ParentId
      Capacity
      Booked
      LevelFrom
      LevelTo
      AgeFrom
      AgeTo
      Gender
      Instructor
      AssistantInstructors
      InstructorId
      AssistantInstructorsId
      Fees
      Extras
      Duration
      Remaining
      PriceScheme
      Price
      subCategoryEventsId
      programEventsId
      PlannerEventExtra
      RawEventDate
      localStartDate
      localEndDate
      AllDayEvent
      GuideCapacity
      isStreaming
      source
      createdAt
      updatedAt
      contactEventsContactID
      __typename
    }
    StartTime
    EndTime
    OccurrenceId
    Status
    RawEventDate
    EventStartDate
    EventEndDate
    eventSubCategoryId
    AgeFrom
    AgeTo
    Gender
    Name
    Capacity
    Booked
    Remaining
    AllDayEvent
    GuideCapacity
    isStreaming
    localStartDate
    localEndDate
    source
    createdAt
    updatedAt
    eventEventOccurrencesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventOccurrenceMutationVariables,
  APITypes.UpdateEventOccurrenceMutation
>;
export const deleteEventOccurrence = /* GraphQL */ `mutation DeleteEventOccurrence(
  $input: DeleteEventOccurrenceInput!
  $condition: ModelEventOccurrenceConditionInput
) {
  deleteEventOccurrence(input: $input, condition: $condition) {
    id
    ParentEventId
    Event {
      id
      Name
      StartDate
      EndDate
      EventStartDate
      EventEndDate
      IsCourse
      Recurrence
      Description
      ParentId
      Capacity
      Booked
      LevelFrom
      LevelTo
      AgeFrom
      AgeTo
      Gender
      Instructor
      AssistantInstructors
      InstructorId
      AssistantInstructorsId
      Fees
      Extras
      Duration
      Remaining
      PriceScheme
      Price
      subCategoryEventsId
      programEventsId
      PlannerEventExtra
      RawEventDate
      localStartDate
      localEndDate
      AllDayEvent
      GuideCapacity
      isStreaming
      source
      createdAt
      updatedAt
      contactEventsContactID
      __typename
    }
    StartTime
    EndTime
    OccurrenceId
    Status
    RawEventDate
    EventStartDate
    EventEndDate
    eventSubCategoryId
    AgeFrom
    AgeTo
    Gender
    Name
    Capacity
    Booked
    Remaining
    AllDayEvent
    GuideCapacity
    isStreaming
    localStartDate
    localEndDate
    source
    createdAt
    updatedAt
    eventEventOccurrencesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventOccurrenceMutationVariables,
  APITypes.DeleteEventOccurrenceMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    id
    Name
    Logo
    orgNumber
    PortalSubdomain
    InAppPurchase
    TopPicksQuery
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    id
    Name
    Logo
    orgNumber
    PortalSubdomain
    InAppPurchase
    TopPicksQuery
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization(
  $input: DeleteOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  deleteOrganization(input: $input, condition: $condition) {
    id
    Name
    Logo
    orgNumber
    PortalSubdomain
    InAppPurchase
    TopPicksQuery
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const createLiveContentURL = /* GraphQL */ `mutation CreateLiveContentURL(
  $input: CreateLiveContentURLInput!
  $condition: ModelLiveContentURLConditionInput
) {
  createLiveContentURL(input: $input, condition: $condition) {
    id
    meetingId
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLiveContentURLMutationVariables,
  APITypes.CreateLiveContentURLMutation
>;
export const updateLiveContentURL = /* GraphQL */ `mutation UpdateLiveContentURL(
  $input: UpdateLiveContentURLInput!
  $condition: ModelLiveContentURLConditionInput
) {
  updateLiveContentURL(input: $input, condition: $condition) {
    id
    meetingId
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLiveContentURLMutationVariables,
  APITypes.UpdateLiveContentURLMutation
>;
export const deleteLiveContentURL = /* GraphQL */ `mutation DeleteLiveContentURL(
  $input: DeleteLiveContentURLInput!
  $condition: ModelLiveContentURLConditionInput
) {
  deleteLiveContentURL(input: $input, condition: $condition) {
    id
    meetingId
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLiveContentURLMutationVariables,
  APITypes.DeleteLiveContentURLMutation
>;
export const createContentShareURL = /* GraphQL */ `mutation CreateContentShareURL(
  $input: CreateContentShareURLInput!
  $condition: ModelContentShareURLConditionInput
) {
  createContentShareURL(input: $input, condition: $condition) {
    id
    meetingId
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContentShareURLMutationVariables,
  APITypes.CreateContentShareURLMutation
>;
export const updateContentShareURL = /* GraphQL */ `mutation UpdateContentShareURL(
  $input: UpdateContentShareURLInput!
  $condition: ModelContentShareURLConditionInput
) {
  updateContentShareURL(input: $input, condition: $condition) {
    id
    meetingId
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContentShareURLMutationVariables,
  APITypes.UpdateContentShareURLMutation
>;
export const deleteContentShareURL = /* GraphQL */ `mutation DeleteContentShareURL(
  $input: DeleteContentShareURLInput!
  $condition: ModelContentShareURLConditionInput
) {
  deleteContentShareURL(input: $input, condition: $condition) {
    id
    meetingId
    url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContentShareURLMutationVariables,
  APITypes.DeleteContentShareURLMutation
>;
export const createTransaction = /* GraphQL */ `mutation CreateTransaction(
  $input: CreateTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  createTransaction(input: $input, condition: $condition) {
    id
    currency
    subtotal
    totalAmount
    contact {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    dateTime
    cartItems
    invoices
    gatewayProcessor
    paymentPattern
    status
    accessEnds
    type
    createdAt
    updatedAt
    contactTransactionsContactID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransactionMutationVariables,
  APITypes.CreateTransactionMutation
>;
export const updateTransaction = /* GraphQL */ `mutation UpdateTransaction(
  $input: UpdateTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  updateTransaction(input: $input, condition: $condition) {
    id
    currency
    subtotal
    totalAmount
    contact {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    dateTime
    cartItems
    invoices
    gatewayProcessor
    paymentPattern
    status
    accessEnds
    type
    createdAt
    updatedAt
    contactTransactionsContactID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTransactionMutationVariables,
  APITypes.UpdateTransactionMutation
>;
export const deleteTransaction = /* GraphQL */ `mutation DeleteTransaction(
  $input: DeleteTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  deleteTransaction(input: $input, condition: $condition) {
    id
    currency
    subtotal
    totalAmount
    contact {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    dateTime
    cartItems
    invoices
    gatewayProcessor
    paymentPattern
    status
    accessEnds
    type
    createdAt
    updatedAt
    contactTransactionsContactID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTransactionMutationVariables,
  APITypes.DeleteTransactionMutation
>;
export const createSyncMetadata = /* GraphQL */ `mutation CreateSyncMetadata(
  $input: CreateSyncMetadataInput!
  $condition: ModelSyncMetadataConditionInput
) {
  createSyncMetadata(input: $input, condition: $condition) {
    tableName
    lastSyncTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSyncMetadataMutationVariables,
  APITypes.CreateSyncMetadataMutation
>;
export const updateSyncMetadata = /* GraphQL */ `mutation UpdateSyncMetadata(
  $input: UpdateSyncMetadataInput!
  $condition: ModelSyncMetadataConditionInput
) {
  updateSyncMetadata(input: $input, condition: $condition) {
    tableName
    lastSyncTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSyncMetadataMutationVariables,
  APITypes.UpdateSyncMetadataMutation
>;
export const deleteSyncMetadata = /* GraphQL */ `mutation DeleteSyncMetadata(
  $input: DeleteSyncMetadataInput!
  $condition: ModelSyncMetadataConditionInput
) {
  deleteSyncMetadata(input: $input, condition: $condition) {
    tableName
    lastSyncTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSyncMetadataMutationVariables,
  APITypes.DeleteSyncMetadataMutation
>;
export const createMessage = /* GraphQL */ `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    conversationID
    conversation {
      id
      ContactIds
      lastMessage
      deletedFor
      archivedFor
      lastMessageTimestamp
      timestamp
      createdAt
      updatedAt
      contactConversationsContactID
      __typename
    }
    senderID
    sender {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    content
    attachmentURLs {
      id
      url
      type
      order
      __typename
    }
    timestamp
    createdAt
    updatedAt
    contactMessagesContactID
    conversationMessagesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const updateMessage = /* GraphQL */ `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    conversationID
    conversation {
      id
      ContactIds
      lastMessage
      deletedFor
      archivedFor
      lastMessageTimestamp
      timestamp
      createdAt
      updatedAt
      contactConversationsContactID
      __typename
    }
    senderID
    sender {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    content
    attachmentURLs {
      id
      url
      type
      order
      __typename
    }
    timestamp
    createdAt
    updatedAt
    contactMessagesContactID
    conversationMessagesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const deleteMessage = /* GraphQL */ `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    conversationID
    conversation {
      id
      ContactIds
      lastMessage
      deletedFor
      archivedFor
      lastMessageTimestamp
      timestamp
      createdAt
      updatedAt
      contactConversationsContactID
      __typename
    }
    senderID
    sender {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    content
    attachmentURLs {
      id
      url
      type
      order
      __typename
    }
    timestamp
    createdAt
    updatedAt
    contactMessagesContactID
    conversationMessagesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const createCompany = /* GraphQL */ `mutation CreateCompany(
  $input: CreateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  createCompany(input: $input, condition: $condition) {
    id
    googleClientId
    name
    refreshTokenYouTube
    scopeYouTube
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyMutationVariables,
  APITypes.CreateCompanyMutation
>;
export const updateCompany = /* GraphQL */ `mutation UpdateCompany(
  $input: UpdateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  updateCompany(input: $input, condition: $condition) {
    id
    googleClientId
    name
    refreshTokenYouTube
    scopeYouTube
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyMutationVariables,
  APITypes.UpdateCompanyMutation
>;
export const deleteCompany = /* GraphQL */ `mutation DeleteCompany(
  $input: DeleteCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  deleteCompany(input: $input, condition: $condition) {
    id
    googleClientId
    name
    refreshTokenYouTube
    scopeYouTube
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyMutationVariables,
  APITypes.DeleteCompanyMutation
>;
export const createConversation = /* GraphQL */ `mutation CreateConversation(
  $input: CreateConversationInput!
  $condition: ModelConversationConditionInput
) {
  createConversation(input: $input, condition: $condition) {
    id
    ContactIds
    readStatus {
      ContactId
      Read
      __typename
    }
    Contacts {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    messages {
      nextToken
      __typename
    }
    lastMessage
    deletedFor
    archivedFor
    lastMessageTimestamp
    timestamp
    createdAt
    updatedAt
    contactConversationsContactID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConversationMutationVariables,
  APITypes.CreateConversationMutation
>;
export const updateConversation = /* GraphQL */ `mutation UpdateConversation(
  $input: UpdateConversationInput!
  $condition: ModelConversationConditionInput
) {
  updateConversation(input: $input, condition: $condition) {
    id
    ContactIds
    readStatus {
      ContactId
      Read
      __typename
    }
    Contacts {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    messages {
      nextToken
      __typename
    }
    lastMessage
    deletedFor
    archivedFor
    lastMessageTimestamp
    timestamp
    createdAt
    updatedAt
    contactConversationsContactID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConversationMutationVariables,
  APITypes.UpdateConversationMutation
>;
export const deleteConversation = /* GraphQL */ `mutation DeleteConversation(
  $input: DeleteConversationInput!
  $condition: ModelConversationConditionInput
) {
  deleteConversation(input: $input, condition: $condition) {
    id
    ContactIds
    readStatus {
      ContactId
      Read
      __typename
    }
    Contacts {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    messages {
      nextToken
      __typename
    }
    lastMessage
    deletedFor
    archivedFor
    lastMessageTimestamp
    timestamp
    createdAt
    updatedAt
    contactConversationsContactID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConversationMutationVariables,
  APITypes.DeleteConversationMutation
>;
export const createInstructorMember = /* GraphQL */ `mutation CreateInstructorMember(
  $input: CreateInstructorMemberInput!
  $condition: ModelInstructorMemberConditionInput
) {
  createInstructorMember(input: $input, condition: $condition) {
    id
    InstructorID
    InstructorName
    InstructorProfileImageURL
    StudentID
    student {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    instructor {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    StudentName
    StudentProfileImageURL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInstructorMemberMutationVariables,
  APITypes.CreateInstructorMemberMutation
>;
export const updateInstructorMember = /* GraphQL */ `mutation UpdateInstructorMember(
  $input: UpdateInstructorMemberInput!
  $condition: ModelInstructorMemberConditionInput
) {
  updateInstructorMember(input: $input, condition: $condition) {
    id
    InstructorID
    InstructorName
    InstructorProfileImageURL
    StudentID
    student {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    instructor {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    StudentName
    StudentProfileImageURL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInstructorMemberMutationVariables,
  APITypes.UpdateInstructorMemberMutation
>;
export const deleteInstructorMember = /* GraphQL */ `mutation DeleteInstructorMember(
  $input: DeleteInstructorMemberInput!
  $condition: ModelInstructorMemberConditionInput
) {
  deleteInstructorMember(input: $input, condition: $condition) {
    id
    InstructorID
    InstructorName
    InstructorProfileImageURL
    StudentID
    student {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    instructor {
      id
      FirstName
      LastName
      Email
      Gender
      Phone
      CreatedDate
      Birthdate
      ProfileImageUrl
      Membership
      Status
      IsInstructor
      InstructorBio
      Survey
      Notifications
      DeviceInfo
      DeviceTokens
      contactID
      verificationOTP
      countryCode
      deleteReason
      instructorIds
      operationSource
      createdAt
      updatedAt
      __typename
    }
    StudentName
    StudentProfileImageURL
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInstructorMemberMutationVariables,
  APITypes.DeleteInstructorMemberMutation
>;
export const createFollower = /* GraphQL */ `mutation CreateFollower(
  $input: CreateFollowerInput!
  $condition: ModelFollowerConditionInput
) {
  createFollower(input: $input, condition: $condition) {
    id
    inviterID
    inviterName
    inviterImage
    inviterType
    followerID
    followerName
    followerImage
    channel
    endpoint
    link
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFollowerMutationVariables,
  APITypes.CreateFollowerMutation
>;
export const updateFollower = /* GraphQL */ `mutation UpdateFollower(
  $input: UpdateFollowerInput!
  $condition: ModelFollowerConditionInput
) {
  updateFollower(input: $input, condition: $condition) {
    id
    inviterID
    inviterName
    inviterImage
    inviterType
    followerID
    followerName
    followerImage
    channel
    endpoint
    link
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFollowerMutationVariables,
  APITypes.UpdateFollowerMutation
>;
export const deleteFollower = /* GraphQL */ `mutation DeleteFollower(
  $input: DeleteFollowerInput!
  $condition: ModelFollowerConditionInput
) {
  deleteFollower(input: $input, condition: $condition) {
    id
    inviterID
    inviterName
    inviterImage
    inviterType
    followerID
    followerName
    followerImage
    channel
    endpoint
    link
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFollowerMutationVariables,
  APITypes.DeleteFollowerMutation
>;
