
import { AppConstant } from "../constants";
import { GraphQLResult, generateClient } from 'aws-amplify/api';
import { listAttendances as listAttendancesQuery, listEvents } from '../graphql/queries';
import { ListAttendancesQuery } from '../API';
import { signIn, confirmSignIn, signOut, getCurrentUser } from 'aws-amplify/auth';
import amplify from 'aws-sdk/clients/amplify';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession  } from '@aws-amplify/auth';
const client = generateClient();  

export const ApiGraphql = {
  async apiQueryCall(requestQuery: any, requestData: any, callback: (arg0: unknown, arg1: string) => void) {
    try {
      const result = await client.graphql({
        query: requestQuery,
        variables: requestData,
      });
      callback(result, AppConstant.success);
    } catch (err) {
      callback(err, AppConstant.error);
    }
  },
};


